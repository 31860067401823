<!-- 广告管理 -->

<style scoped>
	.img{
		/* width:30px; */
		height:30px
	}
	.ellipsis-1{
		max-width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; 
		word-wrap: break-word;
		 word-break: break-all 
	}
	.w150{
		width: 150px;
	}
</style>
<template>
  <div class="pageCentent">
    <div >
	<a-button type="primary"  @click="dialogClick('add')">新增</a-button>
	<MyTable class="mt10" :isSelection="false" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
		<a-space slot='button' slot-scope="item,row,index">
			<a-button size="small" type="primary"  @click="dialogClick('edit',row)">编辑</a-button>
			<a-popconfirm
			title="确定删除?"
			ok-text="确定"
			cancel-text="取消"
			@confirm="delConfirm(row)" >
				<a-button size="small" type="danger" >
					删除
				</a-button>
			</a-popconfirm>
        </a-space>
		<a-space slot='thumb' slot-scope="item,row,index">
			<div class="imgUploadContent">
				<imgUpload v-if="row.thumb" :disabled="true" ref="logo"  :list="[row.thumb]" :maxLength="1" :type="'img'"  @result="uploadResult"></imgUpload>
			</div>
		</a-space>
		<a-space slot='status' slot-scope="item,row,index">
          <a-switch @click="switchClick(row,index)" :disabled="isDisabledSwitch" checked-children="正常" un-checked-children="锁定" :defaultChecked="row.status==1?true:false" />
        </a-space>
      </MyTable>
    </div>
	<a-modal :maskClosable="false" v-model="dialogVisible" :title="dialogType=='add'?'添加':'编辑'" :confirm-loading="submitLoading" @ok="onSubmit"  @cancel="handleCancel" >
		<a-form-model :rules="rules" ref="formModel" size="small" :model="form">
          <a-form-model-item label="品牌名" prop="brand_name">
            <a-input  placeholder="请输入品牌名" v-model="form.brand_name" />
          </a-form-model-item>
		  <a-form-model-item label="封面图" prop="thumb">
			<div class="fs12 color666">建议上传尺寸：80*80</div>
            <imgUpload :disabled="false" ref="logo" v-if="dialogVisible"  :list="form.thumb?[form.thumb]:[]" :maxLength="1" :type="'img'"  @result="uploadResult"></imgUpload>
          </a-form-model-item>
        </a-form-model>
	</a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import imgUpload from "@/components/myUpload.vue"
import {brand_index,brand_edit,brand_add,brand_del,brand_status} from '@/api/goods'
export default {
	components:{MyTable,imgUpload},
  data() {
    return {
		IMGHOST:window.IMGHOST,
		apiUrl:brand_index,
		ajaxparams:{},
		dialogImg:'',
		dialogType:'',
		dialogVisible:false,
		columns:[
			{ title: '封面', ellipsis: true,dataIndex: 'thumb',fixed: 'left', key: 'thumb',width: 120,scopedSlots: { customRender: 'thumb' }},
			{ title: '品牌名', ellipsis: true,dataIndex: 'brand_name',fixed: 'left', key: 'brand_name',width: 120,scopedSlots: { customRender: 'brand_name' }},
			{ title: '状态', ellipsis: true, width: 150, dataIndex: 'status', key: 'status' ,scopedSlots: { customRender: 'status' } },
			{ title: '创建时间', ellipsis: true,dataIndex: 'create_at', key: 'create_at',width: 200},
			{ title: '操作', key: 'operation',fixed: 'right', width: 150, scopedSlots: { customRender: 'button' } }
		],
		isDisabledSwitch:false,
		form:{},
		rules:{},
		submitLoading:false
		
	}
  },
  created() {
  },
  mounted() {
  },
  destroyed() {  
  },
  methods: {
	handleCancel(){
		this.submitLoading = false;
		this.dialogVisible=false
	},
	async onSubmit(){
		this.$refs.formModel.validate(async valid => {
			if (valid) {
				this.submitLoading = true
				if(this.dialogType=='add'){
					brand_add(this.form).then((result)=>{
						this.$message.success('操作成功');
						setTimeout(()=>{
							this.$refs.myTable.getList()
							this.submitLoading = false;
							this.dialogVisible=false
						},1500)
					}).catch(()=>{
						this.submitLoading = false;
					})

				}else{
					brand_edit(this.form).then((result)=>{
						this.$message.success('操作成功');
						setTimeout(()=>{
							this.$refs.myTable.getList()
							this.submitLoading = false;
							this.dialogVisible=false
						},1500)
					}).catch(()=>{
						this.submitLoading = false;
					})
					
				}
				
			}else{
				return false
			}
		});
	},
	// 上传图片回调
	uploadResult(row){//logo
		console.log(row)
		if(row.type=='img'){
			this.form.thumb = row.url
		}
	},
	async switchClick(val,index){//禁用/启用
      brand_status({id:val.id}).then(()=>{
        this.$message.success('操作成功');
        this.tableData[index].status= val.status==0?1:0
        setTimeout(()=>{
          this.isDisabledSwitch = false;
          // this.$refs.myTable.getList()
	  	  },1500)
      })
	},
	async delConfirm(val){//删除
		brand_del({id:val.id}).then((result)=>{
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.$refs.myTable.getList()
			},1500)
		})
	},
	dialogClick(type,row)  {
		this.dialogType=type;
		if(type=='edit'){
			this.form={
				id:row.id,
				brand_name:row.brand_name,
				thumb:row.thumb,
			}
		}else{
			this.form={
				brand_name:'',
				thumb:'',
			}
		}
		this.dialogVisible=true
    },
	
  },
}
</script>
	