import request from '@/utils/request'

// 获取提现列表
export function withdrawal_index(data) {
  return request({
    url: '/merchant/withdrawal/index',
    method: 'get',
    params: data
  })
}

// 提现处理
export function withdrawal_handle(data) {
  return request({
    url: '/merchant/withdrawal/handle',
    method: 'post',
    data: data
  })
}

// 修改提现订单备注
export function withdrawal_remark(data) {
  return request({
    url: '/merchant/withdrawal/remark',
    method: 'post',
    data: data
  })
}

// 检测提现金额
export function withdrawal_check(data) {
  return request({
    url: '/merchant/withdrawal/checkWithdrawalMoney',
    method: 'post',
    data: data
  })
}

// 检测提现金额
export function withdrawal_handle_check(data) {
  return request({
    url: '/merchant/withdrawal/changeCheckStatus',
    method: 'post',
    data: data
  })
}