<!-- 搞单列表 -->
<template>
  <div>
    <div style="background-color: #fff; padding: 16px; margin-bottom: 20px">
      <div>
        <!-- <a-button type="primary" @click="doReady()">开单</a-button> -->
        <!-- 搜索条件 -->
        <a-form-model
          size="small"
          layout="inline"
          :model="ajaxparams"
          @keyup.native.enter="$refs.myTable.search()"
        >
          <a-form-model-item label="业务员名称">
            <a-input
              placeholder="业务员名称"
              v-model="ajaxparams['manager_name_no']"
            />
          </a-form-model-item>
          <a-form-model-item label="服务类型">
            <a-select
              style="width: 200px"
              allowClear
              placeholder="请选择服务类型"
              v-model="ajaxparams['service_type']"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in typeData"
                :key="index"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="日期">
            <a-range-picker
              v-model="ajaxparams['service_date_between']"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              value="null"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              icon="search"
              @click="$refs.myTable.search()"
              type="primary"
              >搜索</a-button
            >
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="mt10">
        <a-button type="primary" @click="check">添加线下服务</a-button>
      </div>
      <MyTable
        class="mt10"
        :apiUrl="orders_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
        @selectRow="onSelectChange"
        :isSelection="false"
      >
        <a-space slot="button" slot-scope="item, row, index">
          <a-button size="small" type="primary" @click="check(row)"
            >编辑</a-button
          >
        </a-space>
        <a-space slot="manager_id" slot-scope="item, row, index">
          <div>{{ findManager(item) }}</div>
        </a-space>
        <a-space slot="service_type" slot-scope="item, row, index">
          <div>{{ typeData[row.service_type - 1].name }}</div>
        </a-space>
      </MyTable>

      <a-modal
        :maskClosable="false"
        v-model="dialogVisible"
        title="添加线下服务"
        :confirm-loading="submitLoading"
        @ok="onSubmit"
        @cancel="handleCancel"
      >
        <a-form-model :rules="rules" ref="formModel" size="small" :model="form">
          <a-form-model-item label="业务经理">
            <a-select
              style="width: 200px"
              allowClear
              v-model="form.manager_id"
              placeholder="请选择业务经理"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in user"
                :key="index"
                >{{ item.manager_name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="线下服务类型">
            <a-select
              style="width: 200px"
              allowClear
              v-model="form.service_type"
              placeholder="请选择线下服务类型"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in typeData"
                :key="index"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="日期">
            <a-date-picker
              v-model="form.service_date"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              value="null"
            />
          </a-form-model-item>
          <a-form-model-item label="目标数量">
            <a-input
              placeholder="请输入目标数量"
              v-model="form['target_num']"
            />
          </a-form-model-item>
          <a-form-model-item label="未完成数">
            <a-input placeholder="请输入未完成数" v-model="form['miss_num']" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue";
import imgUpload from "@/components/myUpload.vue";

import {
  managerService_index,
  managerService_add,
  managerService_edit,
  manager_user_index,
} from "@/api/manager.js";
export default {
  components: { MyTable, imgUpload },
  data() {
    return {
      orders_index: managerService_index,

      ajaxparams: {},

      /*
			列表header
		*/
      columns: [
        {
          title: "操作",
          key: "operation",
          width: 200,
          ellipsis: true,
          fixed: "left",
          scopedSlots: { customRender: "button" },
        },
        {
          title: "业务员",
          key: "manager_id",
          dataIndex: "manager_id",
          width: 130,
          scopedSlots: { customRender: "manager_id" },
        },
        {
          title: "服务类型",
          key: "service_type",
          dataIndex: "service_type",
          width: 130,
          scopedSlots: { customRender: "service_type" },
        },
        {
          title: "目标数量",
          key: "target_num",
          dataIndex: "target_num",
          width: 130,
        },
        {
          title: "未完成数",
          key: "miss_num",
          dataIndex: "miss_num",
          width: 130,
        },
        {
          title: "日期",
          ellipsis: true,
          dataIndex: "service_date",
          key: "service_date",
          width: 220,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "create_at",
          key: "create_at",
          width: 220,
        },
      ],
      dialogVisible: false,
      submitLoading: false,
      form: {},

      rules: {
        manager_id: [
          { required: true, message: "请选择业务经理", trigger: "blur" },
        ],
        report_type: [
          { required: true, message: "请选择日报类型", trigger: "blur" },
        ],
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
      },
      typeData: [
        {
          id: "1",
          name: "朋友圈",
        },
        {
          id: "2",
          name: "群管理",
        },
        {
          id: "3",
          name: "群消息",
        },
      ],
      user: [],
      type: "",
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    findManager(manager_id) {
      if (!manager_id) return "";
      const targetManager = this.user.filter((obj) => obj.id == manager_id);
      return targetManager[0].manager_name;
    },
    check(row) {
      if (row.id) {
        this.form = row;
        this.form.service_type = this.form.service_type + "";
        this.type = "edit";
      } else {
        this.form = {};
        this.type = "add";
      }
      this.dialogVisible = true;
    },
    async getUser() {
      let res = await manager_user_index();
      this.user = res;
      //   this.user.unshift({
      //     id: 0,
      //     manager_name: "全部",
      //   });
    },
    async onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          let action;
          if (this.type == "add") {
            action = managerService_add;
          } else {
            action = managerService_edit;
          }
          console.log(this.form, 111);
          this.submitLoading = true;
          action(this.form)
            .then((result) => {
              this.$message.success("操作成功");
              setTimeout(() => {
                this.$refs.myTable.getList();
                this.submitLoading = false;
                this.dialogVisible = false;
              }, 1500);
            })
            .catch(() => {
              this.submitLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.submitLoading = false;
      this.dialogVisible = false;
    },
  },
};
</script>
