<!-- 账号管理 -->
<style scoped>
.width150 {
  width: 150px;
}
</style>
<template>
  <div>
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
      <div>
       <a-button type="primary" @click="doRank(null)">添加手工数据</a-button>
      </div>
      <MyTable
        class="mt10"
        :apiUrl="fakeData_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
        @selectRow="onSelectChange"
        :isSelection="false"
        :isPagination="false"
      >
        <a-space slot="button" slot-scope="item,row,index" >
          <a-button size="small" type="primary" @click="doRank(row)">修改</a-button>
          <a-popconfirm
            title="确认删除?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="delRank(row.id)"
          >
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </a-space>
        <!-- 价格 需要除100 -->
        <a-space slot='amount' slot-scope="item,row,index">
          <span>{{item}}</span>
        </a-space>
        
      </MyTable>
    </div>
    <!-- 用户确认框 -->
    <a-modal title="人工排行榜" :visible="showModel" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="handleCancel">
      <a-form-model ref="rank" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" size="small"
        :model="formModel">
        <a-form-model-item label="用户名">
            <a-input v-model="formModel.name"  />
        </a-form-model-item>
        <a-form-model-item label="业务经理">
            <a-input v-model="formModel.manager_name"  />
        </a-form-model-item>
        <a-form-model-item label="手机号">
          <a-input  type="number" v-model="formModel.mobile"  maxlength="11" />
        </a-form-model-item>
        <a-form-model-item label="交易金额">
          <a-input type="number" v-model="formModel.amount" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import { fakeData_index,fakeData_add,fakeData_edit,fakeData_del } from "@/api/fakeData"
export default {
  components: { MyTable },
  data() {
    return {
      fakeData_index: fakeData_index,
      ajaxparams: {}, //请求参数
      /*
			列表header
		*/
      columns: [
      
        {
          title: "操作",
          key: "operation",
          width: 200,
          ellipsis: true,
          fixed:"left",
          scopedSlots: { customRender: "button" },
        },
        {
          title: "用户名",
          key: "prams.name",
          dataIndex: "params.name",
          width: 130,
        },
        {
          title: "手机号",
          width: 220,
          dataIndex: "params.mobile",
          key: "params.mobile",
        },
        {
          title: "订单金额",
          width: 220,
          dataIndex: "params.amount",
          key: "params.amount",
          scopedSlots: { customRender: 'params.amount' }
        },
         {
          title: "业务经理",
          width: 220,
          dataIndex: "params.manager_name",
          key: "params.manager_name",
          scopedSlots: { customRender: 'params.manager_name' }
        },
        { 
          title: '时间',
          dataIndex: 'create_at',
          key: 'create_at', 
          width: 150,
          scopedSlots: { customRender: 'create_at' }
        }
      ],
      formModel:{
        name:"",
        mobile:"",
        amount:"",
      },
      fakeId:0,
      showModel:false,
      confirmLoading:false,
    }
  },
  mounted(){
    
  },
  methods: {
    //添加 修改
    doRank(row){
      this.showModel = true
      if(row){
        this.fakeId = row.id
        this.formModel.name = row.params.name
        this.formModel.mobile = row.params.mobile
        this.formModel.amount = row.params.amount
      }else{
        this.fakeId = 0
        this.formModel.name = ""
        this.formModel.mobile = ""
        this.formModel.amount = ""
      }
    },
    delRank(id){
      fakeData_del({id:id})
      .then((res) => {
        this.$message.success("删除成功")
        setTimeout(() => {
          this.$refs.myTable.getList()
        }, 1500)
      })
    
    },
    //弹窗确认
    handleOk(){
      let func = null
      if(this.fakeId){
        func = fakeData_edit
      } else {
        func = fakeData_add
      }
      let parmas = {
        id:this.fakeId,
        params:this.formModel
      }
      func(parmas)
        .then((res) => {
          this.$message.success("操作成功")
          setTimeout(() => {
            this.confirmLoading = false
            this.showModel = false
            this.$refs.myTable.getList()
          }, 1500)
        })
        .catch(() => {
          this.handleCancel()
        })
    },
    // 弹窗取消
    handleCancel() {
      this.showModel = false
      this.confirmLoading = false
      
    },
  },
}
</script>
