<!-- 搞单列表 -->
<template>
  <div>
    <div style="background-color: #fff; padding: 16px; margin-bottom: 20px">
      <div>
        <!-- 搜索条件 -->
        <a-form-model
          size="small"
          layout="inline"
          :model="ajaxparams"
          @keyup.native.enter="$refs.myTable.search()"
        >
          <a-form-model-item label="充值原因">
            <a-input
              placeholder="请输入充值原因"
              v-model="ajaxparams['remark_like']"
            />
          </a-form-model-item>
          <a-form-model-item label="申请日期">
            <a-date-picker
              v-model="ajaxparams['apply_date']"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              value="null"
            />
          </a-form-model-item>

          <a-form-model-item>
            <a-button
              icon="search"
              @click="$refs.myTable.search()"
              type="primary"
              >搜索</a-button
            >
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click="add" type="primary"> 添加 </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <MyTable
        class="mt10"
        :apiUrl="orders_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
        :isSelection="false"
      >
        <a-space slot="img" slot-scope="item, row, index">
          <img
            v-if="row.img"
            style="width: 30px; heigth: 30px"
            :src="IMGHOST + '/' + row.img"
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="user_level" slot-scope="item, row, index">
          <a-tag
            :color="t.color"
            v-for="(t, i) in levels"
            :key="i"
            v-if="row.user_level == t.key"
            >{{ t.vip }}</a-tag
          >
        </a-space>
        <a-space slot="order_sn" slot-scope="item, row, index">
          <router-link
            target="_blank"
            :to="{ path: '/orders/detail', query: { id: row.order_sn } }"
          >
            <span>{{ row.order_sn }}</span>
          </router-link>
        </a-space>
        <a-space slot="thumb" slot-scope="item, row, index">
          <div class="imgUploadContent">
            <imgUpload
              v-if="row.img"
              :disabled="true"
              ref="logo"
              :list="[row.img]"
              :maxLength="1"
              :type="'img'"
            ></imgUpload>
          </div>
        </a-space>
      </MyTable>

      <!-- 添加 -->
      <a-modal
        :maskClosable="false"
        v-model="dialogVisible"
        title="添加"
        :confirm-loading="submitLoading"
        @ok="onSubmit"
        @cancel="handleCancel"
      >
        <a-form-model :rules="rules" ref="formModel" size="small" :model="form">
          <a-form-model-item label="订单号" prop="order_sn">
            <a-input placeholder="请输入订单号" v-model="form.order_sn" />
          </a-form-model-item>
          <a-form-model-item label="用户名称" prop="username">
            <a-input placeholder="请输入用户名称" v-model="form.username" />
          </a-form-model-item>
          <a-form-model-item label="业务经理名" prop="manager_name">
            <a-input
              placeholder="请输入业务经理名"
              v-model="form.manager_name"
            />
          </a-form-model-item>
          <a-form-model-item label="申请人" prop="apply_name">
            <a-input placeholder="请输入申请人" v-model="form.apply_name" />
          </a-form-model-item>
          <a-form-model-item label="共富币" prop="coin">
            <a-input placeholder="请输入共富币" v-model="form.coin" />
          </a-form-model-item>
          <a-form-model-item label="金额" prop="amount">
            <a-input
              type="number"
              placeholder="请输入金额"
              v-model="form.amount"
            />
          </a-form-model-item>
          <a-form-model-item label="用户级别">
            <a-select
              allowClear
              class="w150"
              v-model="form.user_level"
              placeholder="请选择用户级别"
            >
              <a-select-option
                :label="item.vip"
                :value="item.key"
                v-for="(item, index) in levels"
                :key="index"
                >{{ item.vip }}</a-select-option
              >
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="充值原因" prop="remark">
            <a-textarea
              placeholder="请输入充值原因"
              v-model="form.remark"
              :auto-size="{ minRows: 3, maxRows: 8 }"
            >
            </a-textarea>
          </a-form-model-item>
          <a-form-model-item label="签字图" prop="img">
            <div class="fs12 color666">建议上传尺寸：200*200</div>
            <imgUpload
              :disabled="false"
              v-if="dialogVisible"
              :list="form.img ? [form.img] : []"
              :maxLength="1"
              :type="'img'"
              @result="uploadResult"
            ></imgUpload>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue";
import imgUpload from "@/components/myUpload.vue";

import { special_recharge_index, special_recharge_add } from "@/api/orders";
export default {
  components: { MyTable, imgUpload },
  data() {
    return {
      orders_index: special_recharge_index,
      IMGHOST: window.IMGHOST,

      ajaxparams: {}, //请求参数
      /*
			列表header
		*/
      columns: [
        // {
        //   title: "操作",
        //   key: "operation",
        //   width: 200,
        //   ellipsis: true,
        //   fixed: "left",
        //   scopedSlots: { customRender: "button" },
        // },
        {
          title: "订单号",
          key: "order_sn",
          dataIndex: "order_sn",
          width: 130,
        },
        {
          title: "充值金额",
          key: "amount",
          dataIndex: "amount",
          width: 100,
        },
        {
          title: "用户名称",
          width: 100,
          dataIndex: "username",
          key: "username",
        },
        {
          title: "充值原因",
          ellipsis: true,
          width: 300,
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "共富币余额",
          ellipsis: true,
          width: 100,
          dataIndex: "coin",
          key: "coin",
        },
        {
          title: "签字图",
          ellipsis: true,
          dataIndex: "img",
          key: "img",
          width: 220,
          scopedSlots: { customRender: "thumb" },
        },

        {
          title: "充值类型",
          ellipsis: true,
          width: 120,
          dataIndex: "user_level",
          key: "user_level",
          scopedSlots: { customRender: "user_level" },
        },

        {
          title: "申请人",
          ellipsis: true,
          dataIndex: "apply_name",
          key: "apply_name",
          width: 220,
        },
        {
          title: "提交日期",
          ellipsis: true,
          dataIndex: "apply_date",
          key: "apply_date",
          width: 220,
        },
      ],
      levels: [
        { vip: "vip", key: "vip", color: "#87d068" },
        { vip: "A1", key: "A1", color: "#2db7f5" },
        { vip: "A2", key: "A2", color: "#ff4500" },
        { vip: "A3", key: "A3", color: "#f50" },
      ],
      selectGoods: [],
      dialogVisible: false,
      submitLoading: false,
      form: {},
      rules: {
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
        img: [{ required: true, message: "请上传签字图", trigger: "blur" }],
      },
    };
  },
  methods: {
    add() {
      this.dialogVisible = true;
    },
    // 上传图片回调
    uploadResult(row) {
      console.log(row);
      if (row.type == "img") {
        this.form.img = row.url;
      }
    },
    async onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;
          special_recharge_add(this.form)
            .then((result) => {
              this.$message.success("操作成功");
              setTimeout(() => {
                this.$refs.myTable.getList();
                this.submitLoading = false;
                this.dialogVisible = false;
              }, 1500);
            })
            .catch(() => {
              this.submitLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.submitLoading = false;
      this.dialogVisible = false;
    },
  },
};
</script>
