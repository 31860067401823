import request from '@/utils/request'

// 区域经理列表
export function regionManager_index(data) {
  return request({
    url: '/merchant/common/regionManager',
    method: 'get',
    params:data
  })
}

// 创建区域经理
export function regionManager_add(data) {
  return request({
    url: '/merchant/regionManager/add',
    method: 'post',
    data:data
  })
}
// 编辑区域经理
export function regionManager_edit(data) {
  return request({
    url: '/merchant/regionManager/edit',
    method: 'post',
    data:data
  })
}
// 删除区域经理
export function regionManager_del(data) {
  return request({
    url: '/merchant/regionManager/del',
    method: 'post',
    data:data
  })
}




