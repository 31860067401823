import request from '@/utils/request'

// 资金变化列表
export function user_bill_index(data) {
  return request({
    url: '/merchant/userBill/index',
    method: 'get',
    params:data
  })
}

// 用户充值列表
export function user_recharge(data) {
  return request({
    url: '/merchant/UserMemberRechargeOrders/index',
    method: 'get',
    params:data
  })
}