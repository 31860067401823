<!-- 广告管理 -->

<style scoped>
.img {
  /* width:30px; */
  height: 30px;
}
.ellipsis-1 {
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
.w150 {
  width: 150px;
}
</style>
<template>
  <div class="pageCentent">
    <!-- 搜索条件 -->
    <a-form-model
      size="small"
      layout="inline"
      :model="ajaxparams"
      @keyup.native.enter="$refs.myTable.search()"
    >
      <a-form-model-item label="用户名">
        <a-input placeholder="请输入用户名" v-model="ajaxparams['2_username']" />
      </a-form-model-item>
      <a-form-model-item label="描述">
        <a-input placeholder="请输入描述" v-model="ajaxparams['1_desc']" />
      </a-form-model-item>
      <a-form-model-item label="路径">
        <a-input placeholder="请输入路径" v-model="ajaxparams['1_path']" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button icon="search" @click="$refs.myTable.search()" type="primary">搜索</a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <MyTable
        class="mt10"
        :isSelection="false"
        :apiUrl="apiUrl"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
      ></MyTable>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import { operateLog_index } from "@/api/config"
export default {
  components: { MyTable },
  data() {
    return {
      IMGHOST: window.IMGHOST,
      apiUrl: operateLog_index,
      ajaxparams: {},
      columns: [
        {
          title: "用户名",
          ellipsis: true,
          fixed: "left",
          dataIndex: "username",
          key: "username",
        },
        {
          title: "手机号码",
          ellipsis: true,
          fixed: "left",
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "描述",
          ellipsis: true,
          fixed: "left",
          dataIndex: "desc",
          key: "desc",
        },
        {
          title: "路径",
          ellipsis: true,
          fixed: "left",
          dataIndex: "path",
          key: "path",
        },
        {
          title: "IP",
          ellipsis: true,
          fixed: "left",
          dataIndex: "ip",
          key: "ip",
        },
        {
          title: "操作时间",
          ellipsis: true,
          fixed: "left",
          dataIndex: "ctime",
          key: "ctime",
        },
      ],
    }
  },
}
</script>
	