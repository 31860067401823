<!-- 广告管理 -->

<style scoped>
.img {
  /* width:30px; */
  height: 30px;
}

.ellipsis-1 {
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}

.w150 {
  width: 150px;
}
</style>
<template>
  <div class="pageCentent">
    <!-- 搜索条件 -->
    <a-form-model size="small" layout="inline" :model="ajaxparams" @keyup.native.enter="$refs.myTable.search()">
      <a-form-model-item label="姓名">
        <a-input placeholder="请输入姓名" v-model="ajaxparams['1_name_like']" />
      </a-form-model-item>
      <a-form-model-item label="业务经理">
        <a-input placeholder="请输入业务经理" v-model="ajaxparams['manager_name_no']" />
      </a-form-model-item>
      <a-form-model-item label="邀请人">
        <a-input placeholder="请输入邀请人" v-model="ajaxparams['recommend_name_no']" />
      </a-form-model-item>
      <a-form-model-item label="等级">
        <a-select allowClear class="w150" v-model="ajaxparams['1_level']" placeholder="请选择等级"  >
          <a-select-option :label="item.vip" :value="item.key" v-for="(item, index) in levels" :key="index">{{ item.vip }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否过期">
        <a-select allowClear class="w150" v-model="ajaxparams['expired_no']" placeholder="请选择是否过期"  >
          <a-select-option label="请选择" value="0">请选择</a-select-option>
          <a-select-option label="未过期" value="1">未过期</a-select-option>
          <a-select-option label="过期" value="2">过期</a-select-option>

        </a-select>
      </a-form-model-item>
      <a-form-model-item label="省份">
        <a-select style="width:200px" allowClear v-model="ajaxparams['1_province_code']" placeholder="请选择省份" :showSearch="true" optionFilterProp="label">
          <a-select-option :label="item.name" :value="item.code" v-for="(item, index) in regionTree" :key="index">{{ item.name
          }}</a-select-option>

        </a-select>
      </a-form-model-item>
      <a-form-model-item label="品牌">
        <a-select style="width:200px" allowClear v-model="ajaxparams['1_brand_id']" placeholder="请选择品牌">
          <a-select-option :value="item.id" v-for="(item, index) in brandData" :key="index">{{ item.brand_name
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="余额小于">
        <a-input placeholder="余额小于" v-model="ajaxparams['balance1_no']" type="number"/>
      </a-form-model-item>
      <a-form-model-item label="余额大于">
        <a-input placeholder="余额大于" v-model="ajaxparams['balance2_no']" type="number"/>
      </a-form-model-item>
      <a-form-model-item label="身份/金额变更日期">
        <a-date-picker v-model="ajaxparams['1_change_date']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" value=null />
      </a-form-model-item>
      <a-form-model-item label="合同日期">
        <a-date-picker v-model="ajaxparams['1_expired']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" value=null />
      </a-form-model-item>
      <a-form-model-item>
        <a-button icon="search" @click="$refs.myTable.search()" type="primary">搜索</a-button>
      </a-form-model-item>
      <a-form-model-item >
          <a-button  @click="doExport" type="primary"> 导出 </a-button>
        </a-form-model-item>
    </a-form-model>
    <a-button type="primary" class="mt10" @click="showModel('add')">添加</a-button>
    <div>
      <MyTable :isSelection="false" class="mt10" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
        <div slot="button" slot-scope="item,row,index">
          <a-button size="small" type="primary" @click="showModel('edit', row)">编辑</a-button>

          <a-button v-if="!row.is_salesman" size="small" type="default" class="ml10"
            @click="showModel('editVip', row)">会员等级</a-button>
          <a-button v-if="row.level == 'A4' || row.level == ''" size="small" type="default" class="ml10"
            @click="showModel('editBonusLevel', row)">股东等级</a-button>

          <a-button size="small" type="default" @click="showModel('balance', row)">修改余额</a-button>
          <a-button size="small" class="mt10 ml10" type="dashed" @click="showModel('bill', row)">余额明细</a-button>

           <a-button size="small" class="mt10 ml10" type="default" @click="showModel('cancel_amount', row)">取消金额</a-button>

           <!-- <a-button size="small" class="mt10 ml10" type="default" @click="showModel('coupon', row)">发券</a-button> -->
        </div>
        <a-space slot="status" slot-scope="item,row,index">
          <a-switch @click="switchClick(row, index)" :disabled="isDisabledSwitch" checked-children="正常"
            un-checked-children="锁定" :defaultChecked="row.status == 1 ? true : false" />
        </a-space>

        <a-space slot="is_experience" slot-scope="item,row,index">
          <a-switch @click="switchExperience(row, index)" :disabled="isDisabledSwitch" checked-children="体验"
            un-checked-children="非体验" :defaultChecked="row.is_experience == 1 ? true : false" />
        </a-space>

        <a-space slot="sort" slot-scope="item,row,index">
          <editable-cell :ref="'edittableCell' + row.id" :text="row.sort" @change="onCellChange($event, row, index)" />
        </a-space>
        <a-space slot="balance" slot-scope="item,row,index">
          <span>{{ (row.balance / 100).toFixed(2) }}</span>
        </a-space>
        <a-space slot="coin" slot-scope="item,row,index">
          <span>{{ row.coin / 100 }}</span>
        </a-space>
         <a-space slot="cancel_amount" slot-scope="item,row,index">
          <span>{{ row.cancel_amount / 100 }}</span>
        </a-space>
        <a-space slot="parent_name" slot-scope="item,row,index">
          <span>{{ row.parent_name ? row.parent_name : '-' }}</span>
        </a-space>

        <a-space slot="area_code" slot-scope="item,row,index">
          <a-tag v-for="(t, i) in (row.area_code ? province_code(row.area_code) : [])" :key="i">{{ t }}</a-tag>
        </a-space>
        <a-space slot="commission" slot-scope="item,row,index">
          <span>{{ row.commission / 100 }}</span>
        </a-space>
        <a-space slot="un_settle_commission" slot-scope="item,row,index">
          <span>{{ row.un_settle_commission / 100 }}</span>
        </a-space>
        <a-space slot="level" slot-scope="item,row,index">
          <a-tag :color="t.color" v-for="(t, i) in levels" :key="i" v-if="row.level == t.key">{{ row.is_salesman ? "业务员" :
            t.vip }}</a-tag>
        </a-space>
        <a-space slot="bonus_level" slot-scope="item,row,index">
          <a-tag v-for="(t, i) in bonusLevels" :key="i" v-if="row.bonus_level == t.key">{{ t.vip }}</a-tag>
        </a-space>
        <a-space slot="brand_id" slot-scope="item,row,index">
          <a-tag v-for="(t, i) in brandData" :key="i" v-if="t.id == item">{{ t.brand_name }}</a-tag>
        </a-space>

          <a-space slot="manager_id" slot-scope="item,row,index">
            <span>{{findManagerName(item)}}</span>
          </a-space>
      </MyTable>
    </div>
    <!-- 用户处理弹窗 -->
    <a-modal :width="modelType == 'bill' ? '1000px' : '500px'"
      :title="modelType == 'editVip' ? '更新会员' : modelType == 'add' ? '添加' :  modelType == 'coupon' ? '发券' : modelType == 'cancel_amount' ? '取消金额' : modelType == 'edit' ? '编辑用户' : modelType == 'bill' ? '余额明细' : modelType == 'editBonusLevel' ? '股东等级' : '修改余额'"
      :visible="isModalShow" :confirm-loading="confirmLoading" @ok="handleConfirm" @cancel="handleCancel">
      <div v-if="modelType == 'editVip'">
        <a-form-model :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" ref="formModel" size="small"
          :model="formModel">
          <a-form-model-item label="级别">
            <a-select allowClear @change="levelChnage" v-model="formModel['goods_name']" placeholder="请选择级别">
              <a-select-option :value="item.key" v-for="(item, index) in levels" :key="index">{{ item.vip
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="银行流水号" prop="trade_no">
            <a-input placeholder="请输入银行流水号" v-model="formModel.trade_no" />
          </a-form-model-item>
           <a-form-model-item label="备注" prop="remark">
            <a-input placeholder="请输入备注信息" v-model="formModel.remark" />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div v-else-if="modelType == 'editBonusLevel'">
        <a-form-model :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" ref="formModel" size="small"
          :model="formModel">
          <a-form-model-item label="级别">
            <a-select allowClear v-model="formModel['bonus_level']" placeholder="请选择级别">
              <a-select-option :value="item.key" v-for="(item, index) in bonusLevels" :key="index">{{ item.vip
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div v-else-if="modelType == 'cancel_amount'">
        <a-form-model :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" ref="formModel" size="small"
          :model="formModel">
          <a-form-model-item label="取消金额">
            <a-input type="number" placeholder="取消金额" v-model="formModel.cancel_amount" />
          </a-form-model-item>
        </a-form-model>
      </div>
       <!-- <div v-else-if="modelType == 'coupon'">
        <a-form-model :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" ref="formModel" size="small"
          :model="formModel">
          <a-form-model-item label="发券">
            <a-input type="number" placeholder="发券" v-model="formModel.coupon" />
          </a-form-model-item>
        </a-form-model>
      </div> -->
      
      <div v-else-if="modelType == 'edit' || modelType == 'add'">
        <a-form-model :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" ref="formModel" size="small"
          :model="formModel">
          <a-form-model-item label="姓名" prop="name">
            <a-input placeholder="请输入姓名" v-model="formModel.name" />
          </a-form-model-item>
          <a-form-model-item label="手机号" prop="mobile">
            <a-input placeholder="请输入手机号" v-model="formModel.mobile" />
          </a-form-model-item>
          
          <a-form-model-item label="归属地区" prop="area_code">
            <a-cascader v-model="formModel.area_code" :options="regionTree"
              :field-names="{ label: 'name', value: 'code', children: 'children' }" :show-search="{ filterProvince }"
              placeholder="请选择地区" />
          </a-form-model-item>
          <a-form-model-item label="所属品牌(业务员)" prop="brand_id">
            <a-select allowClear v-model="formModel['brand_id']" placeholder="请选择品牌" show-search
              optionFilterProp="children">
              <a-select-option :value="item.id" v-for="(item, index) in brandData" :key="index">{{ item.brand_name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="身份证号" prop="id_card">
            <a-input placeholder="请输入身份证号" v-model="formModel.id_card" />
          </a-form-model-item>
          <a-form-model-item label="收货地址" prop="address">
            <a-input placeholder="请输入收货地址" v-model="formModel.address" />
          </a-form-model-item>
          <a-form-model-item label="身份证正面" prop="front_id_card_pic">
            <myUpload v-if="isModalShow" :maxLength="1" :type="'front_id_card_pic'"
              :list="formModel.front_id_card_pic ? [formModel.front_id_card_pic] : []" @remove="uploadRemove"
              @result="uploadResult"></myUpload>
          </a-form-model-item>
          <a-form-model-item label="身份证反面" prop="back_id_card_pic">
            <myUpload v-if="isModalShow" :maxLength="1" :type="'back_id_card_pic'"
              :list="formModel.back_id_card_pic ? [formModel.back_id_card_pic] : []" @remove="uploadRemove"
              @result="uploadResult"></myUpload>
          </a-form-model-item>
          <a-form-model-item label="营业执照" prop="license_pic">
            <myUpload v-if="isModalShow" :maxLength="1" :type="'license_pic'"
              :list="formModel.license_pic ? [formModel.license_pic] : []" @remove="uploadRemove" @result="uploadResult">
            </myUpload>
          </a-form-model-item>
          <a-form-model-item label="店面(公司)" prop="storefront_pic">
            <myUpload v-if="isModalShow" :maxLength="1" :type="'storefront_pic'"
              :list="formModel.storefront_pic ? [formModel.storefront_pic] : []" @remove="uploadRemove"
              @result="uploadResult"></myUpload>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div v-else-if="modelType == 'balance'">
        <a-form-model :rules="balancerules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" ref="formModel"
          size="small" :model="formModel">
          <a-form-model-item label="收支类型" prop="type">
            <a-select default-value="1" style="width: 120px" v-model="formModel.type">
              <a-select-option value="1">收入</a-select-option>
              <a-select-option value="2">支出</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="余额类型" prop="type">
            <a-select default-value="balance" style="width: 120px" v-model="formModel.amount_type">
              <a-select-option value="balance">余额</a-select-option>
              <a-select-option value="coin">共富币</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="操作金额" prop="value">
            <a-input placeholder="请输入金额" v-model="formModel.value" />
          </a-form-model-item>
          <a-form-model-item label="备注" prop="remark">
            <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" placeholder="请输入备注" v-model="formModel.remark" />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div v-else-if="modelType == 'consignee'">
        <a-form-model ref="formModel" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" size="small"
          :model="formModel">
          <a-form-model-item label="收货人姓名" prop="user_name">
            <a-input placeholder="请输入收货人姓名" v-model="formModel.user_name" />
          </a-form-model-item>
          <a-form-model-item label="收货人手机号" prop="mobile">
            <a-input placeholder="请输入收货人手机号" v-model="formModel.mobile" />
          </a-form-model-item>
          <a-form-model-item label="地区" prop="city">
            <a-cascader v-model="formModel.city" :options="regionTree"
              :field-names="{ label: 'name', value: 'code', children: 'children' }" placeholder="请选择地区" />
          </a-form-model-item>
          <a-form-model-item label="详细地址" prop="address">
            <a-textarea placeholder="请输入详细地址" v-model="formModel.address" />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div v-else>
        <MyTable v-if="isModalShow" :isSelection="false" class="mt10" :apiUrl="detailsApi" :params="formModel"
          ref="myTable" :columns="detailsColumns">
          <a-space slot="type" slot-scope="item,row,index">
            <span>{{ row.type == 1 ? '收入' : '支出' }}</span>
          </a-space>
          <a-space slot="balance" slot-scope="item,row,index">
            <span>{{ !row.is_coin ? (row.balance / 100).toFixed(2) : "-" }}</span>
          </a-space>
          <a-space slot="coin" slot-scope="item,row,index">
            <span>{{ row.is_coin ? (row.balance / 100).toFixed(2) : "-" }}</span>
          </a-space>
          <a-space slot="value" slot-scope="item,row,index">
            <span>{{ (row.value / 100).toFixed(2) }}</span>
          </a-space>

        

        </MyTable>
      </div>
    </a-modal>
    <!-- 用户确认框 -->
    <a-modal title="信息确认" :visible="editVipSure" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="editVipSure = false">
      <a-form-model ref="editVipSure" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" size="small"
        :model="formModel">
        <a-form-model-item label="省份">
            <a-input :value="formModel.province" read-only />
        </a-form-model-item>
        <a-form-model-item label="客户">
          <a-input v-model="formModel.name" read-only />
        </a-form-model-item>
        <a-form-model-item label="级别">
            <a-input :value="findLevel(formModel.goods_name)" read-only />
        </a-form-model-item>
        <a-form-model-item label="充值金额">
          <a-input :value="findLevelMoney(formModel.goods_name)" read-only />
        </a-form-model-item>
        <a-form-model-item label="银行流水号">
          <a-input v-model="formModel.trade_no" read-only />
        </a-form-model-item>
         <a-form-model-item label="备注">
          <a-input v-model="formModel.remark" read-only />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import myUpload from "@/components/myUpload.vue"
import {
  users_bonus_level,
  users_level,
  users_index,
  users_able,
  users_add,
  users_edit,
  users_bill,
  users_balance,
  users_getAddress,
  users_changeAddress,
  users_authCode,
  users_cancel_amount,
  users_distribute_coupon,
  users_experience,
} from "@/api/users"
import { regionManager_index } from "@/api/regionManager"
import { brand_index } from "@/api/goods"
import moment from "moment"
import { user_list }from "@/api/export"

export default {
  components: { MyTable, myUpload },
  data() {
    return {
      IMGHOST: window.IMGHOST,
      apiUrl: users_index,
      detailsApi: users_bill,
      ajaxparams: {},
      editVipSure: false,
      levels: [
        { vip: "vip", key: "vip", color: "#87d068" },
        { vip: "A1", key: "A1", color: "#2db7f5" },
        { vip: "A2", key: "A2", color: "#ff4500" },
        { vip: "A3", key: "A3", color: "#f50" },
        { vip: "无会员", key: "", color: "#909399" },
        { vip: "业务员", key: "A4", color: "#709399" },
      ],
      bonusLevels: [
        { vip: "非合伙人", key: 0 },
        { vip: "10万合伙人", key: 1 },
        { vip: "30万合伙人", key: 3 },
        { vip: "50万合伙人", key: 5 },
      ],
      levelMoney: [
        { vip: "vip", money: 1 },
        { vip: "A1", money: 10 },
        { vip: "A2", money: 50 },
        { vip: "A3", money: 100 },
      ],

      columns: [
        {
          title: "用户名",
          ellipsis: true,
          fixed: "left",
          width: 160,
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "邀请人",
          ellipsis: true,
          dataIndex: "parent_name",
          fixed: "left",
          key: "parent_name",
          width: 120,
          scopedSlots: { customRender: "parent_name" },
        },
        {
          title: "业务经理",
          ellipsis: true,
          dataIndex: "manager_id",
          fixed: "left",
          key: "manager_id",
          width: 120,
          scopedSlots: { customRender: "manager_id" },
        },
        {
          title: "余额/等级变更",
          ellipsis: true,
          dataIndex: "change_date",
          key: "change_date",
          width: 140,
          // scopedSlots: { customRender: "change_date" },
        },
        {
          title: "手机号码",
          ellipsis: true,
          width: 150,
          dataIndex: "mobile",
          key: "mobile",
          scopedSlots: { customRender: "mobile" },
        },
        {
          title: "会员等级",
          ellipsis: true,
          dataIndex: "level",
          key: "level",
          width: 120,
          scopedSlots: { customRender: "level" },
        },
        // {
        //   title: "业务品牌",
        //   ellipsis: true,
        //   dataIndex: "brand_id",
        //   key: "brand_id",
        //   width: 120,
        //   scopedSlots: { customRender: "brand_id" },
        // },
        {
          title: "合伙人等级",
          ellipsis: true,
          dataIndex: "bonus_level",
          key: "bonus_level",
          width: 120,
          scopedSlots: { customRender: "bonus_level" },
        },
        {
          title: "余额",
          ellipsis: true,
          dataIndex: "balance",
          key: "balance",
          width: 120,
          scopedSlots: { customRender: "balance" },
        },
        {
          title: "共富币",
          ellipsis: true,
          dataIndex: "coin",
          key: "coin",
          width: 100,
          scopedSlots: { customRender: "coin" },
        },
        {
          title: "取消金额",
          ellipsis: true,
          dataIndex: "cancel_amount",
          key: "cancel_amount",
          width: 100,
          scopedSlots: { customRender: "cancel_amount" },
        },
     
        {
          title: "省份",
          ellipsis: true,
          dataIndex: "area_code",
          key: "area_code",
          width: 80,
          scopedSlots: { customRender: "area_code" },
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          key: "status",
          width: 120,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "体验号",
          ellipsis: true,
          dataIndex: "is_experience",
          key: "is_experience",
          width: 120,
          scopedSlots: { customRender: "is_experience" },
        },
        {
          title: "合同时间",
          ellipsis: true,
          dataIndex: "expired",
          key: "expired",
          width: 120,
          scopedSlots: { customRender: "expired" },
        },
        {
          title: "最后更新时间",
          dataIndex: "update_at",
          key: "update_at",
          width: 120,
        },
        {
          title: "创建时间",
          dataIndex: "create_at",
          key: "create_at",
          width: 120,
        },
        {
          title: "操作",
          key: "operation",
          width: 200,
          fixed: "right",
          scopedSlots: { customRender: "button" },
        },
      ],
      detailsColumns: [
        {
          title: "ID",
          ellipsis: true,
          dataIndex: "id",
          key: "id",
          scopedSlots: { customRender: "id" },
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "操作金额",
          ellipsis: true,
          dataIndex: "value",
          key: "value",
          scopedSlots: { customRender: "value" },
        },
        {
          title: "现金余额",
          ellipsis: true,
          dataIndex: "balance",
          key: "balance",
          scopedSlots: { customRender: "balance" },
        },
        {
          title: "共富币余额",
          ellipsis: true,
          dataIndex: "coin",
          key: "coin",
          scopedSlots: { customRender: "coin" },
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "create_at",
          key: "create_at",
          width: 200,
        },
      ],
      modelType: "",
      formModel: {
        id: "",
        company_name: 0,
        name: "",
        mobile: "",
        bonus_level: 0,
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        company_name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        id_card: [{ required: true, message: "请输入地址", trigger: "blur" }],
      },
      balancerules: {
        value: [{ required: true, message: "请输入操作金额", trigger: "blur" }],
      },
      isModalShow: false,
      confirmLoading: false,
      ad_position: [],
      defaultImg: [],
      isDisabledSwitch: false,
      regionTree: [],
      usersData: [],
      searchUsers: [],
      regionManagerData: [],
      timeout: null,
      regionData: [],
      modelInput: "",
      brandData: [],
      typeInfo:{
        "editVip":"level_auth_code",
        "edit":"edit_auth_code",
        "balance":"balance_auth_code",
        "editBonusLevel":"bonus_auth_code",
      },
      managerInfo:[]
    }
  },
  computed: {
   
  },
  created() {
    // this.merchant_config_index()
  },
  mounted() {
    this.regionData = this.$db.get("regionList").data
    this.regionTree = this.$common.treeData(this.regionData, "pcode", "code", 0)
    // document.addEventListener('visibilitychange', this.handleVisiable)
    this.getUserMayor()
    this.getUsers("")
    brand_index({ page: 1, limit: 100 }).then((res) => {
      this.brandData = res.list
    })
    this.managerInfo = this.$db.get('manager_index').data
  },
  destroyed() {
    // console.log('触发了')
    // document.removeEventListener('visibilitychange', this.handleVisiable)
  },
  methods: {
    doExport(){
      //导出
      user_list(this.ajaxparams).then(res => {
        this.$message.success("添加导出队列成功")
        this.$db.set('is_export',1)
      },err => {
        this.$message.warning("导出失败")
      })
	},

    findLevel(key){
      if(!key) return "无会员"
			let rest = this.levels.find((item)=> item.key == key)
      return rest.vip
		},
    findLevelMoney(key){
      if(!key) return 0
			let rest = this.levelMoney.find((item)=> item.vip == key)
      if(rest) return rest.money + "万"
      return 0
		},
    findProvinceName(code){
			let rest = this.regionData.find((item)=>{return code==item.code})
			if(rest){
				return rest.name
			}
			return ''
		},
    filterProvince(inputValue, path) {
      return path.some((option) => option.name.indexOf(inputValue) > -1)
    },
    filterBrand(inputValue, path) {
      return path.some((option) => option.brand_name.indexOf(inputValue) > -1)
    },
    province_code(codes) {
      codes = codes.split(",")
      let _this = this
      let names = []
      for (let i = 0; i < codes.length; i++) {
        let result = _this.regionTree.find((item) => {
          return codes[i] == item.code
        })
        if (result) {
          names.push(result.name)
        }
      }
      return names
    },
    levelChnage() {
      delete this.formModel.belongs
      this.belongsChange()
    },
    belongsChange() {
      delete this.formModel.pid
      delete this.formModel.manage_id
    },
    getUserMayor(value) {
      let params = {
        limit: 9999,
        page: 1,
      }
      regionManager_index(params).then((res) => {
        this.regionManagerData = res
      })
    },
    getUsers(value, type = "1_name") {
      let params = {
        limit: 20,
        page: 1,
        "1_level": 3,
      }
      params[type] = value
      users_index(params).then((res) => {
        if (!this.isModalShow) {
          this.searchUsers = res.list
          if (!this.modelType) {
            this.usersData = res.list
          }
        } else {
          this.usersData = res.list
        }
      })
    },
    uploadRemove(row) {
      if (row.type == "front_id_card_pic") {
        this.formModel.front_id_card_pic = ""
      } else if (row.type == "back_id_card_pic") {
        this.formModel.back_id_card_pic = ""
      } else if (row.type == "license_pic") {
        this.formModel.license_pic = ""
      } else if (row.type == "storefront_pic") {
        this.formModel.storefront_pic = ""
      }
    },
    uploadResult(row) {
      if (row.type == "front_id_card_pic") {
        this.formModel.front_id_card_pic = row.url
      } else if (row.type == "back_id_card_pic") {
        this.formModel.back_id_card_pic = row.url
      } else if (row.type == "license_pic") {
        this.formModel.license_pic = row.url
      } else if (row.type == "storefront_pic") {
        this.formModel.storefront_pic = row.url
      }
    },
    async switchClick(val, index) {
      //禁用/启用
      users_able({ id: val.id }).then(() => {
        this.$message.success("操作成功")
        setTimeout(() => {
          this.isDisabledSwitch = false
          // this.$refs.myTable.getList()
        }, 1500)
      })
    },
    async switchExperience(val, index) {
      //禁用/启用
      users_experience({ id: val.id }).then(() => {
        this.$message.success("操作成功")
        setTimeout(() => {
          this.isDisabledSwitch = false
          // this.$refs.myTable.getList()
        }, 1500)
      })
    },
    showModel(type, row) {
      this.modelType = type
      let _this = this
      if (
        type == "editVip" ||
        type == "edit" ||
        type == "balance" ||
        type == "editBonusLevel"
      ) {
        if (type == "editVip") {
          this.formModel = {
            id: row.id,
            goods_name: row.level,
            name:row.name,
            province: this.findProvinceName(row.province_code),
            trade_no: "",
            remark:"",
          }
        } else if (type == "edit") {
          this.formModel = {
            name: row.name,
            id: row.id,
            pid: row.pid,
            brand_id: row.brand_id,
            id_card: row.id_card,
            front_id_card_pic: row.front_id_card_pic,
            back_id_card_pic: row.back_id_card_pic,
            license_pic: row.license_pic,
            storefront_pic: row.storefront_pic,
            level: row.level,
            belongs: row.belongs,
            manage_id: row.manage_id,
            area_code: row.area_code
              ? row.area_code.split(",").map(Number)
              : [],
            mobile: row.mobile,
            company_name: row.company_name,
            no: row.no,
          }
        } else if (type == "balance") {
          this.formModel = {
            id: row.id,
            type: "1",
            amount_type:"balance",
            value: "",
            remark: "",
          }
        } else if (type === "editBonusLevel") {
          this.formModel = {
            id: row.id,
            bonus_level: row.bonus_level,
            trade_no: [],
          }
        }
        let userInfo = JSON.parse(this.$Cookies.get("userInfo"));
        let auth_code = userInfo[_this.typeInfo[type]]
        if (!auth_code) {
          this.$error({
            title: "您没有权限",
            okText: "确认",
            onOk() { },
          })
          return
        }
        _this.modelInput = undefined
        this.$warning({
          title: "敏感操作鉴权?",
          okText: "确认",
          content: (
            <div>
              <a-input
                placeholder="请输入操作码"
                vModel={_this.modelInput}
              ></a-input>
            </div>
          ),
          onOk() {
            if (auth_code == _this.modelInput) {
              _this.isModalShow = true
            } else {
              _this.$message.error('操作码不正确');
              return
            }
          },
          onCancel() {
            console.log("Cancel")
          },
        })
      } else {
        if (type == "add") {
          this.formModel = {
            name: "",
            mobile: "",
            area_code: [],
          }
        } else if (type == "cancel_amount") {
          this.formModel = {
            id: row.id,
            cancel_amount:0,
          }
        }else if (type == "coupon") {
          this.formModel = {
            id: row.id,
          }
        }else if (type == "bill") {
          this.formModel = {
            uid: row.id,
          }
        } else if (type == "consignee") {
          //收货地址
          this.formModel = {}
          users_getAddress({ id: row.id }).then((res) => {
            this.formModel = {
              id: row.id,
              city: [res.province, res.city, res.county],
              user_name: res.user_name,
              mobile: res.mobile,
              address: res.address,
            }
          })
        }
        this.isModalShow = true
      }
    },
    // 弹窗取消
    handleCancel() {
      this.isModalShow = false
      this.confirmLoading = false
      
    },
    // 弹窗确认
    handleConfirm() {
      if (
        this.modelType == "editBonusLevel" ||
        this.modelType == "edit" ||
        this.modelType == "balance" ||
        this.modelType == "add" ||
        this.modelType == "consignee" ||
        this.modelType == "editVip" || 
        this.modelType == "cancel_amount" ||
        this.modelType == "coupon"
      ) {
        this.$refs.formModel.validate(async (valid) => {
          if (valid) {
            this.confirmLoading = true
            let res = null
            let parmas = JSON.parse(JSON.stringify(this.formModel))
            if (this.modelType == "editBonusLevel") {
              users_bonus_level(parmas)
                .then((res) => {
                  this.$message.success("操作成功")
                  setTimeout(() => {
                    this.confirmLoading = false
                    this.isModalShow = false
                    this.$refs.myTable.getList()
                  }, 1500)
                })
                .catch(() => {
                  this.confirmLoading = false
                })
            } else if (this.modelType == "editVip") {
              this.editVipSure = true
              this.confirmLoading = false
            } else if (this.modelType == "add") {
              parmas.area_code = parmas.area_code.join(",")
              users_add(parmas)
                .then((res) => {
                  this.$message.success("操作成功")
                  setTimeout(() => {
                    this.confirmLoading = false
                    this.isModalShow = false
                    this.$refs.myTable.getList()
                  }, 1500)
                })
                .catch(() => {
                  this.confirmLoading = false
                })
            } else if (this.modelType == "edit") {
              parmas.area_code = parmas.area_code.join(",")
              users_edit(parmas)
                .then((res) => {
                  this.$message.success("操作成功")
                  setTimeout(() => {
                    this.confirmLoading = false
                    this.isModalShow = false
                    this.$refs.myTable.getList()
                  }, 1500)
                })
                .catch(() => {
                  this.confirmLoading = false
                })
            } else if (this.modelType == "cancel_amount") {
              users_cancel_amount(parmas)
                .then((res) => {
                  this.$message.success("操作成功")
                  setTimeout(() => {
                    this.confirmLoading = false
                    this.isModalShow = false
                    this.$refs.myTable.getList()
                  }, 1500)
                })
                .catch(() => {
                  this.confirmLoading = false
                })
            }else if (this.modelType == "coupon") {
              users_distribute_coupon(parmas)
                .then((res) => {
                  this.$message.success("操作成功")
                  setTimeout(() => {
                    this.confirmLoading = false
                    this.isModalShow = false
                  }, 1500)
                })
                .catch(() => {
                  this.confirmLoading = false
                })
            }else if (this.modelType == "balance") {
              users_balance(parmas)
                .then((res) => {
                  this.$message.success("操作成功")
                  setTimeout(() => {
                    this.confirmLoading = false
                    this.isModalShow = false
                    this.$refs.myTable.getList()
                  }, 1500)
                })
                .catch(() => {
                  this.confirmLoading = false
                })
            } else if (this.modelType == "consignee") {
              if (parmas.city.length == 0) {
                this.confirmLoading = false
                return
              }
              let city = JSON.parse(JSON.stringify(parmas.city))
              parmas.province = city[0]
              parmas.city = city[1]
              parmas.county = city[2]
              users_changeAddress(parmas)
                .then((res) => {
                  this.$message.success("操作成功")
                  setTimeout(() => {
                    this.confirmLoading = false
                    this.isModalShow = false
                    this.$refs.myTable.getList()
                  }, 1500)
                })
                .catch(() => {
                  this.confirmLoading = false
                })
            }
          } else {
            return false
          }
        })
      } else {
        this.confirmLoading = false
        this.isModalShow = false
      }
    },
    handleOk() {
      let parmas = JSON.parse(JSON.stringify(this.formModel))
      users_level(parmas)
        .then((res) => {
          this.$message.success("操作成功")
          setTimeout(() => {
            this.confirmLoading = false
            this.isModalShow = false
            this.editVipSure = false
            this.$refs.myTable.getList()
          }, 1500)
        })
        .catch(() => {
          this.handleCancel()
        })
    },
    searchPicker(date, dateString) {
      if (dateString.length > 0 && dateString[0]) {
        this.ajaxparams.ctime_between = dateString[0] + " - " + dateString[1]
      } else {
        this.ajaxparams.ctime_between = null
      }
    },
    findManagerName(id){
			let rest = this.managerInfo.find((item)=>{return id==item.id})
			if(rest){
				return rest.manager_name
			}
			return '-'
		},
    // //返回页面刷新
    // handleVisiable(e) {
    // 	if (e.target.visibilityState === 'visible') { //显示页面
    // 		this.$refs.myTable.getList()
    // 		// window.location.href="about:blank";
    // 		// window.close();
    // 	}
    // },
  },
}
</script>
	