<!-- 广告管理 -->

<style scoped>
	.img{
		/* width:30px; */
		height:30px
	}
	.ellipsis-1{
		max-width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; 
		word-wrap: break-word;
		 word-break: break-all 
	}
	.w150{
		width: 150px;
	}
</style>
<template>
  <div class="pageCentent">
    <a-form-model size="small" layout="inline" @keyup.native.enter="search()">
      <a-form-model-item label="姓名">
        <a-input  placeholder="请输入姓名" v-model="ajaxparams['company_name_like']" />
      </a-form-model-item>
      <a-form-model-item label="业务经理">
        <a-input  placeholder="请输入业务经理" v-model="ajaxparams['manager_name_no']" />
      </a-form-model-item>
      <a-form-model-item label="省份">
				<a-select style="width:200px" allowClear v-model="ajaxparams['province']" placeholder="请选择省份" :showSearch="true" optionFilterProp="label">
				<a-select-option :label="item.name" :value="item.name" v-for="(item, index) in provinceData" :key="index">{{ item.name
				}}</a-select-option>
				</a-select>
			</a-form-model-item>
      <a-form-model-item label="部门">
        <a-select style="width: 120px" allowClear v-model="ajaxparams.team_id_no" placeholder="请选择部门">
          <a-select-option value="1">雄狮队</a-select-option>
          <a-select-option value="2">战狼队</a-select-option>
          <a-select-option value="3">雄鹰队</a-select-option>
          <a-select-option value="4">雪豹队</a-select-option>
          <a-select-option value="5">预备1队</a-select-option>
          <a-select-option value="6">预备2队</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="地址">
        <a-input  placeholder="请输入地址" v-model="ajaxparams['manager_name_no']" />
      </a-form-model-item>
      <a-form-model-item >
        <a-button icon="search" @click="$refs.myTable.search()" type="primary"> 搜索</a-button>
      </a-form-model-item>
      
    </a-form-model>
    <div >
      <MyTable class="mt10" :isSelection="false" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
        <a-space slot='status' slot-scope="item,row,index">
          <span>{{row.status==1?'公选':row.status==2?'自选':'已转化'}}</span>
        </a-space>
        <a-space slot='button' slot-scope="item,row,index">
            <a-button @click="openToLog(row)" size="small" type="link">沟通详情</a-button>
        </a-space>
      </MyTable>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {nongzi_index} from '@/api/manager'
export default {
	components:{MyTable},
  data() {
    return {
		apiUrl:nongzi_index,
		ajaxparams:{},
		columns:[
			{ title: '姓名', ellipsis: true,dataIndex: 'company_name',key: 'company_name',width: 120},
			{ title: '市场', ellipsis: true,dataIndex: 'status',key: 'status',width: 120, scopedSlots: { customRender: 'status' } },
			{ title: '地址', ellipsis: true,dataIndex: 'register_address', key: 'register_address',width: 120},
			{ title: '操作', key: 'operation',fixed: 'right', width: 120, scopedSlots: { customRender: 'button' } }
		],
    provinceData:[],
    regionData:[]
	}
  },
  created() {
    this.regionData = this.$db.get('regionList').data
    this.provinceData = this.regionData.filter(item => {
      return item.depth == 1
    })
  },
  mounted() {
  },
  destroyed() {  
  },
  methods: {
    openToLog(row){
      this.$router.push('/manager/contactLog?id='+row.id+'&role=2')
    }
	
  },
}
</script>
	