<!-- 账号管理 -->
<style scoped>
</style>
<template>
  <div >
	<div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
		<a-button type="primary"  @click="dialogClick('add')">
		  添加
		</a-button>
		<a-table class="mt10" :row-selection="{onChange:onSelectChange}" :scroll="{ x: '100%', y: 0 }" :data-source="tableData" :columns="columns">
			<a-space slot='button' slot-scope="item,row,index">
				<a-button size="small" type="primary"  @click="dialogClick('edit',row)">
				  编辑
				</a-button>
				<a-popconfirm
				    title="确定是否删除?"
				    ok-text="确定"
				    cancel-text="取消"
				    @confirm="delConfirm(row)" >
					<a-button size="small" type="danger" >
					  删除
					</a-button>
				</a-popconfirm>
			</a-space>
			<a-space slot='avatar' slot-scope="item,row,index">
				<img v-if="row.avatar" style="width:30px;heigth:30px" :src="IMGHOST+'/'+row.avatar" />
				<span v-else>-</span>
			</a-space>
			<a-space slot='status' slot-scope="item,row,index">
				<a-switch @click="switchClick(row,index)" :disabled="isDisabledSwitch" checked-children="启用" un-checked-children="禁用" :defaultChecked="row.status==1?true:false" />
			</a-space>
			<a-space slot='roles' slot-scope="item,row,index">
				<span v-if="row.id==1">超级管理员</span>
				<span v-else>{{row.role.name}}</span>
			</a-space>
		</a-table>
	</div>
	<a-modal :maskClosable="false" v-model="dialogVisible" :title="dialogType=='add'?'添加':'编辑'">
		<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
			<a-form-item label="用户名">
				<a-input
					v-decorator="[
					  'username',
					  { rules: [{ required: true, message: '请输入用户名' }] },
					]"
					placeholder="请输入用户名"
				  />
			</a-form-item>
			<a-form-item label="手机号">
				<a-input
					v-decorator="[
					  'mobile',
					  { rules: [
						  { required: true , message: '请输入手机号'}
						] 
					  },
					]"
					placeholder="请输入手机号"
				  />
			</a-form-item>
			<a-form-item label="密码">
				<a-input
					v-decorator="[
					  'password',
					  { rules: [
						  { required: true , min: 6, max: 18, message: '密码长度必须为6-18个字符之间'}
						] 
					  },
					]"
					placeholder="请输入密码(6-18位)"
				  />
			</a-form-item>
			<a-form-item label="角色">
				<a-select style="width: 100%" v-decorator="[
					  'role_id',
					  { rules: [{ required: true, message: '请选择角色' }] },
					]"
					placeholder="请选择角色" @change="formSelectChange"
				  >
				  <a-select-option v-for="(item,index) in roleData" :key="index" :value="item.id">
					{{item.name}}
				  </a-select-option>
				</a-select>
			</a-form-item>
		</a-form>
	  <template slot="footer">
		<a-button key="back" @click="dialogVisible=false">
		  取消
		</a-button>
		<a-button key="submit" type="primary" :loading="submitLoading" @click="onSubmit">
		  提交
		</a-button>
	  </template>
	  
	</a-modal>
  </div>
</template>
<script>
// import MyTable from "@/components/myTable.vue"
// import { export2Excel } from '@/utils/excelFu.js'
export default {
	// components:{MyTable},
  data() {
    return {
		IMGHOST:window.IMGHOST,
		apiUrl:'merchant_admin_index',//请求地址
		ajaxparams:{},//请求参数
		rowSelection:[],
		/*
			列表header
		*/
		columns:[
			{
			  title: '操作',
			  key: 'operation',
			  fixed: 'left',
			  width: 150,
			  ellipsis: true,
			  scopedSlots: { customRender: 'button' },
			},
			{ title: '用户名', ellipsis: true, width: 150, dataIndex: 'username', key: 'username' },
			{ title: '手机号码',ellipsis: true, width: 150, dataIndex: 'mobile', key: 'mobile' },
			{ 
				title: '头像', ellipsis: true,dataIndex: 'avatar', key: 'avatar', width: 100,
				scopedSlots: { customRender: 'avatar' },
			},
			{ 
				title: '状态', ellipsis: true,dataIndex: 'status', key: 'status',width: 150,
				scopedSlots: { customRender: 'status' },
			},
			{ title: '角色', ellipsis: true,dataIndex: 'roles', key: 'roles',width: 150,
				scopedSlots: { customRender: 'roles' },
			},
			{ title: '最后登录IP', ellipsis: true,dataIndex: 'last_login_ip', key: 'last_login_ip',width: 150},
			{ title: '创建时间', ellipsis: true,dataIndex: 'ctime', key: 'ctime',width: 220},
			{ title: '更新时间', ellipsis: true,dataIndex: 'utime', key: 'utime',width: 220},
		],
		isDisabledSwitch:false,//是否禁用Switch
		submitLoading:false,
		dialogType:'add',
		dialogVisible:false,
		form:this.$form.createForm(this,{
			// id:0,
			username:'',
			password:'',
			role_id:'',
			mobile:'',
		}),
		roleData:[],
		editRowId:0,
		loading:false,
		tableData:[]
    }
  },
  created() {
	  this.getList()
	  this.merchant_role_index();
  },
  methods: {
	async getList(){
		this.loading = true
		let res = await this.$api.merchant_admin_index(this.params)
		if(res.code==200){
		   let result = res.result&&res.result.list?res.result.list:res.result
		   for(let i = 0; i < result.length; i++){
			   result[i].key = i
		   }
		   this.tableData = result
		}
		this.loading = false
	},
	formSelectChange(value){
		console.log(value);
		// this.form.setFieldsValue({
		//   note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
		// });
	},
	switchChange(val){
		console.log(val)
	},
	async merchant_role_index(){//获取所有角色
		let res = await this.$api.merchant_role_index({page:1,limit:10000,status:1})
		if(res.code==200){
			res.result.splice(0,0,{
				id: 1,
				menu_ids: "15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,10,11,12,13,14,4,5,6,7,8,9,1,2,3",
				name: "超级管理员"
			})
			this.roleData = res.result
		}
	},
	async switchClick(val,index){//禁用/启用
		this.isDisabledSwitch = true
		let res = await this.$api.merchant_admin_able({status:val.status?0:1,id:val.id})
		if(res.code==200){
			this.$message.success('操作成功');
			this.$refs.myTable.tableData[index].status= val?1:0
		}
		setTimeout(()=>{
			this.isDisabledSwitch = false
		},1500)
	},
	onSelectChange(val){
		console.log(val)
		
	},
	async onSubmit(){
		this.form.validateFields(async (err, values) => {
			if (!err) {
				this.submitLoading = true
				let model = Object.assign({}, this.form.getFieldsValue());
				if(this.dialogType=='add'){
					let res = await this.$api.merchant_admin_add(model)
					if(res.code==200){
						this.$message.success('操作成功');
						setTimeout(()=>{
							this.$refs.myTable.getList()
						},1500)
					}
				}else{
					model.id = this.editRowId
					let res = await this.$api.merchant_admin_edit(model)
					if(res.code==200){
						this.$message.success('操作成功');
						setTimeout(()=>{
							this.$refs.myTable.getList()
						},1500)
					}
				}
				this.submitLoading = false;
				this.dialogVisible=false
			}
		});
	},
	async delConfirm(val){//删除
		let res = await this.$api.merchant_admin_del({id:val.id})
		if(res.code==200){
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.$refs.myTable.getList()
			},1500)
		}
	},
    dialogClick(type,row)  {
		this.dialogType=type;
		
		if(type=='edit'){
			this.dialogVisible=true
			this.editRowId = row.id
			this.$nextTick(()=>{
				this.form.setFieldsValue({
				  // id:row.id,
				  username:row.username,
				  password:'',
				  role_id:row.role_id,
				  mobile:row.mobile,
				});
			})
		}else{
			this.editRowId = 0
			if(this.roleData.length==0){
				this.$message.warning('请前往创建角色');
				return
			}
			this.dialogVisible=true
			this.$nextTick(()=>{
				this.form.setFieldsValue({
				  // id:0,
				  username:'',
				  password:'',
				  role_id:this.roleData[0].id,
				  mobile:'',
				});
			})
		}
    },
  },
}
</script>
