<!-- 账号管理 -->
<style scoped>
	.width150{
		width: 150px;
	}
</style>
<template>
  <div >
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">

      <a-form-model  size="small" layout="inline" @keyup.native.enter="search()">
        <a-form-model-item label="选择日期">
         <a-range-picker @change="prickerChange" :allowClear="false" v-model="pickerValue" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"  />
        </a-form-model-item>
      </a-form-model>
      <a-descriptions class="mt20"   title="数据总览表"></a-descriptions>
      <a-table :pagination="false" :scroll="{x: '100%'}" :columns="columns" :data-source="tableData">
        <span slot="duration" slot-scope="text">{{ returnTime(text) }}</span  >
        <span slot="performance" slot-scope="text">{{ text/100 }}</span  >
      </a-table>
      <a-descriptions class="mt20"  >
        <div slot="title" class="flex justify-between align-center">
          <div>业务经理详情</div>
          <a-select style="width: 120px" allowClear v-model="team_id" placeholder="请选择部门" @change="handleChange">
            <a-select-option :value="index" v-for="(item,index) in titleObj" :key="index">{{item}}</a-select-option>
          </a-select>
        </div>

      </a-descriptions>
      <div class="mb10" v-show="!team_id||team_id==item.team_id" v-for="(item,index) in list" :key="index">
        <a-card :title="item.manager_name" clumn="4" size="small">
          <a-descriptions bordered size="small">
            <a-descriptions-item label="部门">
              {{titleObj[item.team_id]?titleObj[item.team_id]:'-'}}
            </a-descriptions-item>
            <a-descriptions-item label="业绩">
              {{item.performance/100}}
            </a-descriptions-item>
            <a-descriptions-item label="会员">
              {{item['vip']}}
            </a-descriptions-item>
            <a-descriptions-item label="A1">
               {{item['A1']}}
            </a-descriptions-item>
            <a-descriptions-item label="A2">
               {{item['A2']}}
            </a-descriptions-item>
            <a-descriptions-item label="A3">
               {{item['A3']}}
            </a-descriptions-item>
            <a-descriptions-item label="面谈数">
               {{item['visit']}}
            </a-descriptions-item>
            <a-descriptions-item label="微信沟通">
               {{item['wechat']}}
            </a-descriptions-item>
            <a-descriptions-item label="电话沟通">
               {{item['mobile']}}
            </a-descriptions-item>
            <a-descriptions-item label="通话时长">
               {{returnTime(item['duration']) }}
            </a-descriptions-item>
            <a-descriptions-item label="产品分享">
               {{item['product_share']}}
            </a-descriptions-item>
            <a-descriptions-item label="消息分享">
               {{item['notice_share']}}
            </a-descriptions-item>
            <a-descriptions-item label="新增会员">
               {{item['new_payment_user']}}
            </a-descriptions-item>
            <a-descriptions-item label="新增裂变客户">
               {{item['new_customer_recommend']}}
            </a-descriptions-item>
            <a-descriptions-item label="新增陌生客户">
               {{item['strange']}}
            </a-descriptions-item>
            <a-descriptions-item label="超30日未联系">
               {{item['un_contact']}}
            </a-descriptions-item>
            <a-descriptions-item label="活跃会员数">
               {{item['activity_user']}}
            </a-descriptions-item>
          </a-descriptions>
        </a-card>
      </div>
    </div>
  </div>
</template>
<script>
import { managerData_index } from '@/api/manager'
import moment from "moment"
export default {
  data() {
    return {
      ajaxparams:{
        start_date:moment(new Date().getTime()- 86400000).format('YYYY-MM-DD'),
        end_date:moment(new Date().getTime()).format('YYYY-MM-DD')
      },//请求参数
      team_id:undefined,
      /*
        列表header
      */
      columns:[
        { title: '部门', fixed: "left",  width: 120, dataIndex: 'title', key: 'title'},
        { title: '销售业绩',  width: 120, dataIndex: 'performance', key: 'performance' ,scopedSlots: { customRender: "performance" }},
        { title: '会员数',  width: 80, dataIndex: 'vip', key: 'vip'},
        { title: 'A1',  width: 80, dataIndex: 'A1', key: 'A1'},
        { title: 'A2',  width: 80, dataIndex: 'A2', key: 'A2'},
        { title: 'A3',  width: 80, dataIndex: 'A3', key: 'A3'},
        { title: '活跃会员',  width: 100, dataIndex: 'activity_user', key: 'activity_user'},
        { title: '超30日未联系',  width: 120, dataIndex: 'un_contact', key: 'un_contact'},
        { title: '新增裂变客户',ellipsis: true, width: 120, dataIndex: 'new_customer_recommend', key: 'new_customer_recommend' },
        { title: '新增会员或合伙人',ellipsis: true, width: 150, dataIndex: 'new_payment_user', key: 'new_payment_user'},
        { title: '新增陌生客户',ellipsis: true, width: 120, dataIndex: 'strange', key: 'strange'},
        { title: '电话跟进',width: 100,dataIndex: 'mobile', key: 'mobile'},
        { title: '通话时长', width:150, dataIndex: 'duration', key: 'duration',scopedSlots: { customRender: "duration" }},
        { title: '微信跟进',  width: 100,dataIndex: 'wechat', key: 'wechat'},
        { title: '面谈',  width: 100,dataIndex: 'visit', key: 'visit'},
        { title: '产品分享',  width: 100,dataIndex: 'product_share', key: 'product_share'},
        { title: '消息分享',  width: 100,dataIndex: 'notice_share', key: 'notice_share'},
      ],
      tableData:[],
      list:[],
      titleObj:{
        1:'雄狮队',
        2:'战狼队',
        3:'雄鹰队',
        4:'雪豹队',
        5:'预备1队',
        6:'预备2队',
      },
      pickerValue:[moment(new Date().getTime()- 86400000).format('YYYY-MM-DD'),moment(new Date().getTime()).format('YYYY-MM-DD')]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    returnTime(duration){
      let m = parseInt(duration/60)
      let s = duration%60
      return m+'分'+s+'秒'
    },
    prickerChange(e){
      
      this.ajaxparams.start_date = e[0]
      this.ajaxparams.end_date = e[1]
      this.getList()
    },
    handleChange(e){

    },
    search(){
      this.getList()
    },
    async getList(){
      function sumObjectValues(obj1, obj2) {
        let sum = {};
        for (let key in obj1) {
          if(key == 'team_id'||key == 'title'){
            sum[key] = obj1[key]
          }else if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
            let num = (obj1[key] - 0) + (obj2[key] - 0)
            sum[key] = num != NaN?num:''
          }
        }
        return sum;
      }
      managerData_index(this.ajaxparams).then((res)=>{
        let data = []
        this.list = res
        for (let i = 0; i < res.length; i++) {
          
          res[i].title = this.titleObj[res[i].team_id]?this.titleObj[res[i].team_id]:'-'
          let resIndex = data.findIndex((row)=>{return row.team_id === res[i].team_id})

          if(resIndex !== -1 ){
            let sumData = sumObjectValues(data[resIndex] , res[i])
            console.log(sumData)
            data[resIndex] = sumData
          }else{
            let pushData = Object.assign({},res[i])
            data.push(pushData)
          }
        }
        let totalData = {}
        for (let i = 0; i < data.length; i++) {
          if(i === 0){
            totalData = data[i]
          }else{
            let sumData = sumObjectValues(totalData , data[i])
            totalData = sumData
          }
          
        }
        totalData.title = '总计'
        data.push(totalData)
        this.tableData = data
      })
      
    }
  }
  
}
</script>

