<style scoped >
	.item{
		position: relative;
		cursor: move;
		border: 2px solid #FFFFFF;
	}
	.item div{
		pointer-events: none !important;
	}
	
	.item:hover{
		border: 2px dashed red;
	}
	.view-content{
		margin-left: 30px;
		width: 379px;
		height: 667px;
		background-color: #FFFFFF;
		box-shadow: 0 0 20px  #f6f6f6;
		border-radius: 10px;
		overflow-y: scroll;
	}
	.ul{
		width: 375px;
		height: 667px;
		background-color: #FFFFFF;
		box-shadow: 0 0 20px  #f2f2f2;
		border-radius: 10px;
		padding: 20px;
	}
	.li{
		cursor: move;
		float: left;
		margin-bottom: 10px;
		width: 60px;
		height: 60px;
		border-radius: 7px;
		margin-right: 5px;
		box-shadow: 0 0 20px  #f2f2f2;
	}
	.ant-carousel >>> .slick-slide {
		pointer-events: none !important;
	  text-align: center;
	  height: 160px;
	  line-height: 160px;
	  background: #364d79;
	  overflow: hidden;
	}
	
	.ant-carousel >>> .slick-slide h3 {
	  color: #fff;
	}
	.image{
		width: 100%;
		background-color: #CCCCCC;
	}
	.images{
		width: 100%;
	}
	.blank{
		width: 100%;
		height: 20px;
		background-color: #F6F6F6;
	}
	.category>img{
		width: 70px;
		height:70px;
	}
	.goods .goodsItem{
		width: 185px;
		border-radius: 7px;
		box-shadow: 0 0 20px  #f2f2f2;
		overflow: hidden;
	}
	.goods .goodsItem img{
		height: 185px;
	}
	.goods .goodsItem .price{
		color: red;
	}
	.delBtn{
		/* width: 100px;
		height: 40px; */
		padding: 1px 5px;
		background-color: #F2F2F2;
		color: red;
		position: absolute;
		right: 0;
		bottom: 0;
		font-size: 12px;
		
		
	}
	.view-form{
		margin-left: 30px;
		width: 379px;
		height: 667px;
		background-color: #FFFFFF;
		box-shadow: 0 0 20px  #f6f6f6;
		border-radius: 10px;
		
		overflow-y: scroll;
	}
	.closeBg{
		color: #fff;
		padding: 5px 0;
		background-color:#8cc1cd;
	}
	.submit{
		position: absolute;
		bottom: 20px;
		left: 0;
	}
</style>

<!-- 拖拽元素 -->
<template>
	<div class="flex justify-start align-start">
		<div @dragstart="dragStart" class=" ul psr" @dragend="dragEnd" >
			<div class="li flex flex-column justify-center align-center" v-for="(val, key, index) in typeList" draggable  :data-type="key" :key="index + 1"  >
				<a-icon :type="val.icon" />
				<p>{{val.name}}</p>
			</div>
			<div class="clear-both"></div>
			<div class="submit w-100 flex justify-center align-center pl20 pr20">
				<a-button type="danger" block @click="merchant_pages_edit">
					保存页面
				</a-button>
			</div>
		</div>
		<!-- 释放区域 -->
		<div class="view-content" @drop="drog" @dragover="dragOver" >
			<div class="text-center pt10 pb10" @click="dragClick" :data-index="-1">
				{{page_name}}
			</div>
			<div :data-index="index" @click="dragClick" draggable @dragstart="dragStart"  @dragend="dragEnd" class="item" v-for="(item,index) in view" :key="index">
				<div v-if="item.type=='images'" class="images flex justify-around align-center flex-wrap" >
					<img :style="'width:'+(375-((item.options.row-1)*item.options.space))/item.options.row+'px;margin-bottom:'+item.options.space+'px'" v-for="(t,i) in (item.data.length>=item.options.row?item.data:item.options.row)" :key="i" :src="t.image_url?t.image_url:empty_img" >
				</div>
				<div v-else-if="item.type=='image'" class="image" >
					<img class="w-100" :src="item.data.image_url?item.data.image_url:empty_img" >
				</div>
				<div v-else-if="item.type=='banner'" class="banner" >
					<a-carousel autoplay effect="fade">
						<div v-for="(t,i) in (item.data.length>=2?item.data:2)" :key="i">
							<img class="w-100" :src="t.image_url?t.image_url:empty_img" >
						</div>
					</a-carousel>
				</div>
				<div v-else-if="item.type=='blank'" class="blank"></div>
				<div v-else-if="item.type=='notice'" class="notice flex justify-start align-center">
					<a-icon type="sound" />
					<span >{{item.options.content}}</span>
				</div>
				<div v-else-if="item.type=='goods'" class="goods">
					<div v-if="item.options.is_more" class="flex justify-between align-center pl10 pr10 pt10">
						<div class="fs16">
							{{item.options.name}}
						</div>
						<div class="fs12">
							查看更多>>
						</div>
					</div>
					<div class="flex justify-between align-center flex-wrap">
						<div class="goodsItem pb10 mb10 flex flex-column justify-start align-center" v-for="(t,i) in (item.data.length>=2?item.data:2)" :key="i">
							<img class="w-100" :src="t.image?t.image:empty_img" >
							<div class="mt10 name w-100 pl10 pr10">
								<span class="ellipsis-1 ">
									{{t.name?t.name:('商品'+i)}}
								</span>
							</div>
							<div class="price pt10 w-100 pl10 pr10">
								¥{{t.price?t.price:'99'}}
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="item.type=='category'" class="category flex justify-around align-center flex-wrap">
					<img v-for="(t,i) in item.options.row?item.options.row:4" :key="i" :src="item.data[i]?item.data[i].image:'https://b2c.jihainet.com/static/images/empty.png'" >
				</div>
				<a-popconfirm title="确定要删除吗?" ok-text="确定" cancel-text="取消"  @confirm="delView(index,view)" >
					<a class="delBtn" href="#" v-if="clickId==item.id">
						删除
					</a>
				</a-popconfirm>
			</div>
			<div class="w-100 pb20"></div>
		</div>
		<div class="view-form">
			<!-- <div class="text-center pt10 pb10" >
				{{clickType=='banner'?'图片轮播':'页面顶部'}}
			</div> -->
			<a-form-model
				class="mt20"
				ref="form"
				:model="form"
				:rules="rules"
				:label-col="{ span:6 }" 
				:wrapper-col="{ span: 17 }">
				<a-form-model-item label="页面名称"  v-if="clickType=='page_name'">
					<a-input v-model="page_name" placeholder="请输入页面名称" />
				</a-form-model-item>
				<div v-if="clickType=='banner'" >
					<div v-for="(item,index) in form.data" :key="index">
						<div class="closeBg flex justify-between align-center">
							<div class="pl10">
								图片{{index+1}}
							</div>
							<a-button type="link" @click="delForm(index,form.data)">
							     <a-icon type="close" :style="{color: '#fff'}"  />
							</a-button>
						</div>
						<a-form-model-item class="mt10" label="图片"  >
							<myUpload @remove="uploadRemove" ref="myUpload" :list="item.image_url?[item.image_url]:[]" :maxLength="1" :type="'banner_'+index" @result="uploadResult"></myUpload>
						</a-form-model-item>
						<a-form-model-item label="链接类型"  >
							<a-select v-model="item.type" >
								<a-select-option :value="1">商品</a-select-option>
								<a-select-option :value="2">店铺</a-select-option>
							</a-select>
						</a-form-model-item>
						<a-form-model-item label="目标ID"  >
							<a-input v-model="item.target" placeholder="请输入跳转目标ID" />
						</a-form-model-item>
					</div>
					<a-button type="primary" block @click="addForm(form.data)">
					    添加图片
					</a-button>
				</div>
				<div v-else-if="clickType=='images'" >
					<a-form-model-item label="间距(px)"  >
						<a-input v-model="form.options.space" placeholder="请输入间距" />
					</a-form-model-item>
					<a-form-model-item label="样式"  >
						<a-radio-group v-model="form.options.row">
							<a-radio :value="1">一行一个</a-radio>
							<a-radio :value="2">一行两个</a-radio>
							<a-radio :value="3">一行三个</a-radio>
							<a-radio :value="4">一行四个</a-radio>
						</a-radio-group>
					</a-form-model-item>
					<!-- <a-form-model-item label="样式"  >
						<a-input v-model="form.options.style" placeholder="请输入样式" />
					</a-form-model-item> -->
					<div v-for="(item,index) in form.data" :key="index">
						<div class="closeBg flex justify-between align-center">
							<div class="pl10">
								图片{{index+1}}
							</div>
							<a-button type="link" @click="delForm(index,form.data)">
							     <a-icon type="close" :style="{color: '#fff'}"  />
							</a-button>
						</div>
						<a-form-model-item class="mt10" label="图片"  >
							<myUpload @remove="uploadRemove" ref="myUpload" :list="item.image_url?[item.image_url]:[]" :maxLength="1" :type="'images_'+index" @result="uploadResult"></myUpload>
						</a-form-model-item>
						<a-form-model-item label="链接类型"  >
							<a-select v-model="item.type" >
								<a-select-option :value="1">商品</a-select-option>
								<a-select-option :value="2">店铺</a-select-option>
							</a-select>
						</a-form-model-item>
						<a-form-model-item label="目标ID"  >
							<a-input v-model="item.target" placeholder="请输入跳转目标ID" />
						</a-form-model-item>
					</div>
					<a-button type="primary" block @click="addForm(form.data)">
					    添加图片
					</a-button>
				</div>
				<div v-else-if="clickType=='image'">
					<a-form-model-item class="mt10" label="图片"  >
						<myUpload @remove="uploadRemove" ref="myUpload" :list="form.data.image_url?[form.data.image_url]:[]" :maxLength="1" :type="'image'" @result="uploadResult"></myUpload>
					</a-form-model-item>
					<a-form-model-item label="链接类型"  >
						<a-select v-model="form.data.type" >
							<a-select-option :value="1">商品</a-select-option>
							<a-select-option :value="2">店铺</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="目标ID"  >
						<a-input v-model="form.data.target" placeholder="请输入跳转目标ID" />
					</a-form-model-item>
				</div>
				<div v-else-if="clickType=='notice'">
					<a-form-model-item label="公告内容"  >
						<a-input type="textarea" v-model="form.options.content" placeholder="请输入公告内容" />
					</a-form-model-item>
				</div>
				<div v-else-if="clickType=='blank'">
					<a-form-model-item label="背景颜色"  >
						<a-input v-model="form.options.background_color" placeholder="请输入背景颜色(如:#FFFFFF、rgb(255,255,255))" /> 
					</a-form-model-item>
					<a-form-model-item label="高度"  >
						<a-input v-model="form.options.height" placeholder="请输入高度(px)" />
					</a-form-model-item>
				</div>
				<div v-else-if="clickType=='goods'">
					<a-form-model-item label="商品组名称"  >
						<a-input v-model="form.options.name" placeholder="请输入商品组名称" /> 
					</a-form-model-item>
					<a-form-model-item label="数据来源"  >
						<a-select v-model="form.options.data_sources" >
							<a-select-option :value="1">限时购</a-select-option>
							<a-select-option :value="2">拼团</a-select-option>
							<a-select-option :value="3">分类</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="分类标签" v-if="form.options.data_sources==3" >
						<a-select class="w-100" placeholder="请选择标签" v-model="form.options.sub_sources">
							<a-select-option v-for="(item,index) in category_label" :key="index" :value="item.id" :label="item.category_name">
								{{item.category_name}}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="更多按钮" >
						<a-radio-group v-model="form.options.is_more">
							<a-radio :value="true">显示</a-radio>
							<a-radio :value="false">隐藏</a-radio>
						</a-radio-group>
					</a-form-model-item>
					<a-form-model-item label="显示类型" >
						<a-radio-group v-model="form.options.style.display_type">
							<a-radio :value="1">列表</a-radio>
							<a-radio :value="2">横向滚动</a-radio>
						</a-radio-group>
					</a-form-model-item>
					<a-form-model-item label="显示列数" v-if="form.options.style.display_type==2" >
						<a-radio-group v-model="form.options.style.column">
							<a-radio :value="1">1</a-radio>
							<a-radio :value="2">2</a-radio>
							<a-radio :value="3">3</a-radio>
						</a-radio-group> 
					</a-form-model-item>
					<a-form-model-item label="显示数量"  >
						<a-input v-model="form.options.limit" placeholder="请输入显示数量" /> 
					</a-form-model-item>
				</div>
				<!-- <a-form-model-item label="状态"  prop="status" ref="status">
					<a-radio-group v-model="form.status">
						<a-radio :value="1">启用</a-radio>
						<a-radio :value="0">禁用</a-radio>
					</a-radio-group>
				</a-form-model-item> -->
			</a-form-model>
		</div>
	</div>
</template>
<!-- 拖拽元素列表数据 -->
<script>
import myUpload from "@/components/myUpload.vue"
export default {
	components:{
		myUpload
	},
	data() {
		return {
			IMGHOST:window.IMGHOST,
			typeList: {
				banner: {
					name: '轮播图',
					icon: 'picture',
					com: 'Banner'
				},
				goods: {
					name: '商品组',
					icon: 'gift',
					com: 'Goods'
				},
				images: {
					name: '图片组',
					icon: 'picture',
					com: 'Images'
				},
				image: {
					name: '图片',
					icon: 'picture',
					com: 'Images'
				},
				notice: {
					name: '文字公告',
					icon: 'picture',
					com: 'Notice'
				},
				blank: {
					name: '辅助线',
					icon: 'picture',
					com: 'Blank'
				},
				category: {
					name: '分类',
					icon: 'picture',
					com: 'Category'
				}
				
			},
			empty_img:'https://b2c.jihainet.com/static/images/empty-banner.png',
			type:'',
			isPush:false,
			view: [],
			index:0,
			// 判断我点击的是哪个
			clickId:null,
			clickIndex:null,
			clickType:'',
			rules:{},
			category_label:[],
			form:{
				
			},
			page_name:'页面顶部'
			
		}
	},
	mounted() {
		this.init()
	},
	methods:{
		// 初始化
		init(){
			this.merchant_pages_read();
			this.merchant_config_index('category_label');
		},
		async merchant_pages_read(){
			let res = await this.$api.merchant_pages_read()
			if(res.code==200){
				for (var i = 0; i < (res.result?res.result.module.length:0); i++) {
					let arr = {
						id:i,
						data:res.result.module[i].data,
						options:res.result.module[i],
						type:res.result.module[i].type
					}
					this.$delete(arr.options, 'data')
					this.$delete(arr.options, 'type')
					this.view.push(arr)
				}
			}
		},
		// 提交保存
		async merchant_pages_edit(){
			let parmas = {
				page_name:this.page_name,
				module:[]
			}
			for (var i = 0; i < this.view.length; i++) {
				let arr = JSON.parse(JSON.stringify(this.view[i].options))
					arr.type = this.view[i].type
					if(this.view[i].data){
						arr.data = JSON.parse(JSON.stringify(this.view[i].data))
					}
					parmas.module.push(arr)
			}
			let res = await this.$api.merchant_pages_edit(parmas)
			if(res.code==200){
				this.$message.success('操作成功');
			}
		},
		
		// 获取配置接口
		async merchant_config_index(type){
		  	let res = await this.$api.merchant_config_index({option:type})
		  	if(res.code==200){
				if(type=="category_label"){//分类标签
					this.category_label =  res.result.category_label
				}
		  	}
		},
		// 上传图片
		uploadResult(row){
			if(row.type.indexOf('banner')!=-1||row.type.indexOf('images')!=-1){
				let typeArray = row.type.split('_')
				this.form.data[typeArray[1]].image_url = this.IMGHOST+row.url
			}else if(row.type=='image'){
				this.form.data.image_url = this.IMGHOST+row.url
			}
		},
		// 删除图片
		uploadRemove(row){
			if(row.type.indexOf('banner')!=-1||row.type.indexOf('images')!=-1){
				let typeArray = row.type.split('_')
				this.form.data[typeArray[1]].image_url = ''
			}else if(row.type=='image'){
				this.form.data.image_url = ''
			}
		},
		addForm(data){
			if(this.clickType=='banner'||this.clickType=='images'){
				data.push({image_url:'',type:1,target:''})
			}
		},
		delForm(index,data){
			if(data.length>1){
				data.splice(index,1)
			}else{
				this.$message.warning('至少需要一张图片')
			}
		},
		delView(index,data){
			this.clickIndex=null;
			this.clickType='';
			data.splice(index,1)
		},
		// 结束拖拽
		dragEnd(e) {
			this.$delete(this.view[this.index], 'status')
			if(this.index!=undefined){
				this.clickIndex = this.index;
				this.clickId = this.view[this.clickIndex].id;
				this.clickType = this.view[this.clickIndex].type;
				this.form = {
					data:this.view[this.clickIndex].data,
					options:this.view[this.clickIndex].options,
				}
			}
			this.isPush = false
			this.type = null
			return false
		},
		// 已放置到指定位置
		drog(e) {
			e.preventDefault()
			e.stopPropagation()
			this.dragEnd()
		},
		// 点击开始
		dragClick(e){
			console.log(e.target.dataset.index)
			if(e.target.dataset.index!=undefined&&e.target.dataset.index != -1){
				this.clickIndex = e.target.dataset.index;
				this.clickId = this.view[this.clickIndex].id;
				this.clickType = this.view[this.clickIndex].type;
				this.form = {
					data:this.view[this.clickIndex].data,
					options:this.view[this.clickIndex].options,
				}
			}else if(e.target.dataset.index == -1){
				this.clickType = 'page_name'
			}
		},
		// 拖拽开始
		dragStart(e) {
			this.type = e.target.dataset.type
		},
		// 'view-content': 外层盒子的class，直接 push
		// 'item': 盒子内部的元素，需计算位置，进行变换操作
		dragOver(e) {
			let className = e.target.className
			let name = className !== 'view-content' ? 'item' : 'view-content'
			// 组件的默认数据
			const defaultData = {
				id:this.view.length,
				type: this.type, // 组件类型
				status: 2,   // 默认状态
				data: null,   // 基本数据
				options: {}   // 其他操作
			}
			//判断数据类型，对应添加data数据
			if(this.type=='banner'){
				defaultData.data = [{image_url:'',type:1,target:''},{image_url:'',type:1,target:''}];
			}else if(this.type=='image'){
				defaultData.data = {image_url:'',type:1,target:''};
			}else if(this.type=='images'){
				defaultData.options = {style:'',space:4,row:2};
				defaultData.data = [{image_url:'',type:1,target:''},{image_url:'',type:1,target:''}];
			}else if(this.type=='notice'){
				defaultData.options = {content:''};
			}else if(this.type=='blank'){
				defaultData.options = {background_color:'',height:''};
			}else if(this.type=='goods'){
				defaultData.options = {
					data_sources:3,
					sub_sources:this.category_label.length>0?this.category_label[0].id:'',
					name:'',
					is_more:false,
					style:{
						display_type:1,
						column:2,
					},
					limit:30
				};
			}

			if (name == 'view-content') {
				if (!this.isPush) {
					this.index = this.view.length
					this.isPush = true
					this.view.push(defaultData)
				}
			} else if (name == 'item') {
			let target = e.target
			let [ y, h, curIndex ] = [ e.offsetY, target.offsetHeight, target.dataset.index ]
			let direction = y < (h / 2) // 计算鼠标处于当前元素的位置，来决定拖拽元素的上/下
			if (!this.isPush) {
			  // first
				if(this.type){
					if (direction) {
						if (curIndex == 0) {
							this.view.unshift(defaultData)
						} else {
							this.view.splice(curIndex, 0, defaultData)
						}
					} else {
						curIndex = (curIndex-0) + 1
						this.view.splice(curIndex, 0, defaultData)
					}
				}
			} else {
				// Moving
				if (direction) {
					var i = curIndex == 0 ? 0 : curIndex - 1
					var result = this.view[i]['status'] == 2
				} else {
					var i = (curIndex-0) + 1
					var result = this.view.length > i && this.view[i]['status'] == 2
				}
				// 拖拽元素是否需变换位置
				if (result) return;
					const temp = this.view.splice(this.index, 1)
					this.view.splice(curIndex, 0, temp[0])
				}
				this.index = curIndex // 拖拽元素位置
				this.isPush = true  // 进入则push，即true
			}
		}
	}
}
// com 为对应的视图组件，在释放区域显示

</script>