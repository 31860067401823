import request from '@/utils/request'

// 管理员列表
export function admin_index(data) {
  return request({
    url: '/merchant/admin/index',
    method: 'get',
    params:data
  })
}

// 创建管理员
export function admin_add(data) {
  return request({
    url: '/merchant/admin/add',
    method: 'post',
    data:data
  })
}
// 编辑管理员
export function admin_edit(data) {
  return request({
    url: '/merchant/admin/edit',
    method: 'post',
    data:data
  })
}
// 删除管理员
export function admin_del(data) {
  return request({
    url: '/merchant/admin/del',
    method: 'post',
    data:data
  })
}
// 更新管理员状态
export function admin_able(data) {
  return request({
    url: '/merchant/admin/able',
    method: 'post',
    data:data
  })
}




