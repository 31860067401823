<style scoped>
	.img{
		/* width:30px; */
		height:30px
	}
	.ellipsis-1{
		max-width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; 
		word-wrap: break-word;
		 word-break: break-all 
	}
	.w150{
		width: 150px;
	}
</style>
<template>
  <div class="pageCentent">
    <div >
		<!-- <a-space>
			<a-popconfirm
			title="确定批量处理?"
			ok-text="确定"
			cancel-text="取消"
			@confirm="batchOpt" >
				<a-button size="small" type="primary" >批量处理</a-button>
			</a-popconfirm>
			
		</a-space> -->
		<!-- 搜索条件 -->
		<a-form-model  size="small" layout="inline" :model="ajaxparams"  @keyup.native.enter="$refs.myTable.search()">
			<a-form-model-item label="用户">
				<a-input  placeholder="请输入用户名" v-model="ajaxparams['2_name']" />
			</a-form-model-item>
			<a-form-model-item label="会员等级">
				<a-select allowClear class="w150" v-model="ajaxparams['2_level']" placeholder="请选择等级"  >
				<a-select-option :label="item.vip" :value="item.key" v-for="(item, index) in levels" :key="index">{{ item.vip }}</a-select-option>
				</a-select>
			</a-form-model-item>
			
			<a-form-model-item label="是否处理">
				<a-select allowClear class="w150" v-model="ajaxparams['1_state']" placeholder="请选择"  >
				<a-select-option value="1">是</a-select-option>
				<a-select-option value="0">否</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="礼品关键词">
				<a-input  placeholder="请输入礼品关键词" v-model="ajaxparams['1_remark_like']" />
			</a-form-model-item>
			
			<a-form-model-item label="月份" >
				<a-month-picker v-model="ajaxparams['1_date']" format="YYYYMM" valueFormat="YYYYMM" value=null />
			</a-form-model-item>
			<a-form-model-item >
				<a-button icon="search" @click="$refs.myTable.search()" type="primary"> 搜索</a-button>
			</a-form-model-item>
		</a-form-model>
      <MyTable class="mt10" :disableSelection="{key:'state',value:0}" @selectRow="chnageSelectRow" :isSelection="false" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
		 <a-space slot='button' slot-scope="item,row,index">
			<a-button size="small" type="primary" v-if="row.state == 0" @click="showDialog(row,'state')">
				处理
			</a-button>
			<a-button size="small" @click="showDialog(row,'remark')">
				修改备注
			</a-button>
        </a-space>
	
        <a-space slot='state' slot-scope="item,row,index">
			<span>{{row.state==0?'未处理':'已处理'}}</span>
        </a-space>

		<a-space slot='update_at' slot-scope="item,row,index">
			<span>{{row.state == 1 ? row.update_at :'-'}}</span>
        </a-space>

		<a-space slot='create_at' slot-scope="item,row,index">
			<span>{{row.create_at.substr(0,10)}}</span>
        </a-space>
      </MyTable>
    </div>
	<a-modal :title="dialogType==='state'?'发放福利':'修改备注'" :visible="dialogVisible" @ok="approved" @cancel="dialogVisible = false">
		<a-form-model-item label="备注">
			<a-textarea placeholder="请输入备注信息" v-model="remark"  :auto-size="{ minRows: 3, maxRows: 8 }"> </a-textarea>
        </a-form-model-item>
	</a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {userWelfarePackage_index,userWelfarePackage_batchOpt,userWelfarePackage_changeRemark} from '@/api/users'
export default {
	components:{MyTable},
  data() {
    return {
		apiUrl:userWelfarePackage_index,
		ajaxparams:{},
		dialogVisible:false,
		columns:[
			{ title: '用户', ellipsis: true,dataIndex: 'name',fixed: 'left', key: 'name',width: 120,scopedSlots: { customRender: 'name' }},
			{ title: '会员等级', ellipsis: true, width: 120, dataIndex: 'level', key: 'level' },
			{ title: '发放月份', ellipsis: true, width: 120, dataIndex: 'date', key: 'date' },
			{ title: '状态', ellipsis: true,dataIndex: 'state', key: 'state',width: 100,scopedSlots: { customRender: 'state' }},
			{ title: '备注', dataIndex: 'remark', key: 'remark',width: 150 },
			{ title: '创建时间', ellipsis: true,dataIndex: 'create_at', key: 'create_at',width: 200,scopedSlots: { customRender: 'create_at' }},
			{ title: '处理时间', ellipsis: true,dataIndex: 'update_at', key: 'update_at',width: 200,scopedSlots: { customRender: 'update_at' }},
			{ title: '操作', key: 'operation',fixed: 'right', width: 120, scopedSlots: { customRender: 'button' } }
		],
		selectRow:[],
		remark:"",
		currentId:0,
		levels: [
			{ vip: "vip", key: "vip", color: "#87d068" },
			{ vip: "A1", key: "A1", color: "#2db7f5" },
			{ vip: "A2", key: "A2", color: "#ff4500" },
			{ vip: "A3", key: "A3", color: "#f50" },
		],
		dialogType:''
	}
  },
  created() {
  },
  mounted() {
  },
  destroyed() {  
  },
  methods: {
	showDialog(row,type){
		this.dialogType = type
		this.remark = type==='state'?"":row.remark
		this.currentId = row.id
		this.dialogVisible = true
		// console.log(this.remark,this.currentId)
	},
	chnageSelectRow(row){
		this.selectRow = row
	},
	//批量处理 隐藏掉
	// batchOpt(){
	// 	if(this.selectRow.length==0){
	// 		this.$message.warning('请选择处理数据');
	// 		return 
	// 	}else{
	// 		let ids = []
	// 		for (let i = 0; i < this.selectRow.length; i++) {
	// 			ids.push(this.selectRow[i].id)
	// 		}
	// 		this.approved({id:ids.join(',')})
	// 	}
	// },
	approved(){
		if(this.dialogType==='state'){
			userWelfarePackage_batchOpt({id:this.currentId,remark:this.remark}).then((res)=>{
				this.$message.success('操作成功');
				setTimeout(()=>{
					this.$refs.myTable.getList()
					this.dialogVisible = false
				},1500)
			})
		}else if(this.dialogType === 'remark'){
			userWelfarePackage_changeRemark({id:this.currentId,remark:this.remark}).then((res)=>{
				this.$message.success('操作成功');
				setTimeout(()=>{
					this.$refs.myTable.getList()
					this.dialogVisible = false
				},1500)
			})
		}
	},
  },
}
</script>
	