<!-- 广告管理 -->

<style scoped>
	.img{
		/* width:30px; */
		height:30px
	}
	.ellipsis-1{
		max-width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; 
		word-wrap: break-word;
		 word-break: break-all 
	}
	.w150{
		width: 150px;
	}
</style>
<template>
  <div class="pageCentent">
    <div >
      <MyTable class="mt10" :isSelection="false" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
		 <a-space slot='button' slot-scope="item,row,index" v-if="row.state == 0">
			<a-button size="small" type="danger" @click="showDialog('reject',row.id)">
				拒绝
			</a-button>
			<a-button size="small" type="primary" @click="showDialog('deal',row.id)">
				处理
			</a-button>
        </a-space>
        <a-space slot='state' slot-scope="item,row,index">
			<span>{{row.state==0?'未处理':'已处理'}}</span>
        </a-space>
		<a-space slot='id_card' slot-scope="item,row,index">
			<span>{{$common.hiddenString(row.id_card,'********',6)}}</span>
        </a-space>
      </MyTable>
    </div>
	
	<a-modal title="处理申请" :visible="dialogVisible" @ok="approved" @cancel="dialogVisible = false">
		<a-form-model-item label="备注">
			<a-input placeholder="请输入备注信息" v-model="remark" > </a-input>
		</a-form-model-item>
		<a-form-model-item label="日期" v-if="buttonType === 'deal'">
			<a-date-picker @change="changeDate"/>
		</a-form-model-item>
	</a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {userTravelRegistration_index,userTravelRegistration_changeState} from '@/api/users'
export default {
	components:{MyTable},
  data() {
    return {
		IMGHOST:window.IMGHOST,
		apiUrl:userTravelRegistration_index,
		ajaxparams:{},
		dialogVisible:false,
		columns:[
			{ title: '会员名称', ellipsis: true,dataIndex: 'name',key: 'name',width: 120},
			{ title: '姓名', ellipsis: true,dataIndex: 'user_name',key: 'user_name',width: 120},
			{ title: '会员等级', ellipsis: true,dataIndex: 'level', key: 'level',width: 120},
			{ title: '手机号', ellipsis: true, width: 150, dataIndex: 'mobile', key: 'mobile'},
			{ title: '旅游时间', ellipsis: true, width: 150, dataIndex: 'date', key: 'date'},
			{ title: '身份证号', ellipsis: true, width: 220, dataIndex: 'id_card', key: 'id_card',scopedSlots: { customRender: 'id_card' }},
			{ title: '申请人数', ellipsis: true, width: 120, dataIndex: 'num', key: 'num' },
			{ title: '备注',  width: 120, dataIndex: 'remark', key: 'remark' },
			{ title: '状态', ellipsis: true,dataIndex: 'state', key: 'state',width: 100,scopedSlots: { customRender: 'state' }},
			{ title: '创建时间', ellipsis: true,dataIndex: 'create_at', key: 'create_at',width: 200},
			{ title: '操作', key: 'operation',fixed: 'right', width: 120, scopedSlots: { customRender: 'button' } }
		],
		buttonType:'deal',
		travelDate:"",
		remark:"",
		currentId:0,

	}
  },
  created() {
  },
  mounted() {
  },
  destroyed() {  
  },
  methods: {
	//选择日期
	changeDate(date,dateString){
		this.travelDate = dateString
	},
	showDialog(type,id){
		this.currentId = id
		this.buttonType = type
		this.dialogVisible = true
	},
	mobile(id_card){
		var mobile = id_card.substr(0, 6) + '*******' + id_card.slice(14)
		return mobile
	},
	
	approved(row){
		let postData = {
			id:this.currentId,
			state:this.buttonType === 'deal' ? 1 : -1
		}
		if(this.remark) postData.remark = this.remark
		if(this.travelDate) postData.date = this.travelDate
		userTravelRegistration_changeState(postData).then((res)=>{
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.dialogVisible = false
				this.$refs.myTable.getList()
			},1500)
		})
	},
  },
}
</script>
	