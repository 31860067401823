import request from '@/utils/request'

// 广告列表
export function ad_index(data) {
  return request({
    url: '/merchant/ad/index',
    method: 'get',
    params:data
  })
}

// 创建广告
export function ad_add(data) {
  return request({
    url: '/merchant/ad/add',
    method: 'post',
    data:data
  })
}
// 编辑广告
export function ad_edit(data) {
  return request({
    url: '/merchant/ad/edit',
    method: 'post',
    data:data
  })
}
// 删除广告
export function ad_del(data) {
  return request({
    url: '/merchant/ad/del',
    method: 'post',
    data:data
  })
}
// 更新广告状态
export function ad_lock(data) {
  return request({
    url: '/merchant/ad/lock',
    method: 'post',
    data:data
  })
}




