import request from '@/utils/request'

// 业务经理数据中心
export function managerData_index(data) {
  return request({
    url: '/merchant/managerData/index',
    method: 'get',
    params: data
  })
}

// 添加标语与通知
export function managerSlogan_add(data) {
  return request({
    url: '/merchant/managerSlogan/add',
    method: 'post',
    data: data
  })
}

// 获取标语列表
export function managerSlogan_index(data) {
  return request({
    url: '/merchant/managerSlogan/index',
    method: 'get',
    params: data
  })
}
// 农资大市场
export function nongzi_index(data) {
  return request({
    url: '/merchant/nongzi/index',
    method: 'get',
    params: data
  })
}
// 沟通列表
export function contactLog_index(data) {
  return request({
    url: '/merchant/contactLog/index',
    method: 'get',
    params: data
  })
}
// 奖品领取列表
export function userPrize_index(data) {
  return request({
    url: '/merchant/userPrize/index',
    method: 'get',
    params: data
  })
}

// 奖品信息修改字段
export function userPrize_changeField(data) {
  return request({
    url: '/merchant/userPrize/changeField',
    method: 'post',
    data: data
  })
}


// 用户任务列表
export function userTask_index(data) {
  return request({
    url: '/merchant/userTask/index',
    method: 'get',
    params: data
  })
}

// 征集圈列表
export function userPost_index(data) {
  return request({
    url: '/merchant/userPost/index',
    method: 'get',
    params: data
  })
}
// 审核征集圈
export function userPost_changeStatus(data) {
  return request({
    url: '/merchant/userPost/changeStatus',
    method: 'post',
    data: data
  })
}
// 业务经理列表
export function manager_user_index(data) {
  return request({
    url: '/merchant/ManagerUser/index',
    method: 'get',
    params: data
  })
}
// 假期列表 
export function managerDailyReport_index(data) {
  return request({
    url: '/merchant/managerDailyReport/index',
    method: 'get',
    params: data
  })
}
// 添加假期
export function managerDailyReport_add(data) {
  return request({
    url: '/merchant/managerDailyReport/add',
    method: 'post',
    data: data
  })
}

// 线下服务 
export function managerService_index(data) {
  return request({
    url: '/merchant/managerService/index',
    method: 'get',
    params: data
  })
}
// 添加线下服务
export function managerService_add(data) {
  return request({
    url: '/merchant/managerService/add',
    method: 'post',
    data: data
  })
}
// 编辑线下服务
export function managerService_edit(data) {
  return request({
    url: '/merchant/managerService/edit',
    method: 'post',
    data: data
  })
}