import request from '@/utils/request'

// 公告 列表
export function notice_index(data) {
  return request({
    url: '/merchant/notice/index',
    method: 'get',
    params: data
  })
}

// 创建公告 
export function notice_add(data) {
  return request({
    url: '/merchant/notice/add',
    method: 'post',
    data: data
  })
}

// 删除公告 
export function notice_del(data) {
  return request({
    url: '/merchant/notice/del',
    method: 'post',
    data: data
  })
}

