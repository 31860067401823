<!-- 账号管理 -->
<style scoped>
	.width150{
		width: 150px;
	}
</style>
<template>
  <div >
	<div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
		<a-button type="primary"  @click="dialogClick('add')">
		  添加
		</a-button>
		<MyTable class="mt10" :apiUrl="regionManager_index" :params="ajaxparams" ref="myTable" :columns="columns">
			<a-space slot='button' slot-scope="item,row,index">
				<a-button size="small" type="primary"  @click="dialogClick('edit',row)">
				  编辑
				</a-button>
				<a-popconfirm
				    title="确定是否删除?"
				    ok-text="确定"
				    cancel-text="取消"
				    @confirm="delConfirm(row)" >
					<a-button size="small" type="danger" >
					  删除
					</a-button>
				</a-popconfirm>
			</a-space>
			<a-space slot='province_code' slot-scope="item,row,index">
        <a-tag v-for="(t,i) in (row.province_code?province_code(row.province_code):[])" :key="i">{{t}}</a-tag>
			</a-space>
		</MyTable>
	</div>
	<a-modal :maskClosable="false" v-model="dialogVisible" :title="dialogType=='add'?'添加人员':'编辑人员'" :confirm-loading="submitLoading" @ok="onSubmit"  @cancel="handleCancel" >
		<a-form-model :rules="rules" ref="formModel" size="small" :model="form">
      <a-form-model-item label="用户名" prop="user_name">
        <a-input  placeholder="请输入用户名" v-model="form.user_name" />
      </a-form-model-item>
      <a-form-model-item label="手机号码" prop="mobile">
          <a-input  placeholder="请输入手机号码" v-model="form.mobile" />
      </a-form-model-item>
      <a-form-model-item label="省份" >
          <a-select mode="multiple" allowClear v-model="form.province_code" placeholder="请选择省份" >
					  <a-select-option :value="item.code" v-for="(item,index) in regionData" :key="index">{{item.name}}</a-select-option>
			  	</a-select>
      </a-form-model-item>
    </a-form-model>
	</a-modal>
  </div>
</template>
<script>
import imgUpload from "@/components/myUpload.vue"
import MyTable from "@/components/myTable.vue"

import { regionManager_index,regionManager_add,regionManager_edit,regionManager_del,regionManager_lock } from '@/api/regionManager'
import { config_index } from '@/api/config'

export default {
	components:{MyTable,imgUpload},
  data() {
    return {
      	regionManager_index:regionManager_index,
		IMGHOST:window.IMGHOST,
		ajaxparams:{},//请求参数
		/*
			列表header
		*/
		columns:[
			{
			  title: '操作',
			  key: 'operation',
			  fixed: 'left',
			  width: 150,
			  ellipsis: true,
			  scopedSlots: { customRender: 'button' },
			},
			{ title: '用户名', ellipsis: true, width: 150, dataIndex: 'user_name', key: 'user_name' },
			{ title: '手机号码',ellipsis: true, width: 150, dataIndex: 'mobile', key: 'mobile' },
			{ 
				title: '省份', dataIndex: 'province_code', key: 'province_code', width: 200,
				scopedSlots: { customRender: 'province_code' },
			}
		],
		isDisabledSwitch:false,//是否禁用Switch
		submitLoading:false,
		dialogType:'add',
		dialogVisible:false,
		form:{
			"id":0,
			"user_name":"",
			"moble":"",
			"province_code":[],
		},
		loading:false,
      rules:{
        user_name: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
        moble: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
        province_code: [{ required: true, message: '请选择省份', trigger: 'change' }],
      },
      regionData:[]
    }
  },
  computed:{
    regionTree(){
		  if(this.regionData.length>0){
        let data = [];
        for (let i = 0; i < this.regionData.length; i++) {
          if(this.regionData[i].depth==1){
            data.push(this.regionData[i])
          }
        }
        return data
			  // return this.$common.treeData(this.regionData,'pcode','code',0)
		  }
      return []
	  }
  },
  created() {
    let data = this.$db.get('regionList').data;
    let regionData = []
    for (let i = 0; i < data.length; i++) {
      if(data[i].depth==1){
        regionData.push(data[i])
      }
    }
    this.regionData = regionData

	  this.getpPosition()
  },
  methods: {
    province_code(codes){
      codes = codes.split(',')
      let _this = this;
      let names = []
      for (let i = 0; i < codes.length; i++) {
          let result = _this.regionData.find((item)=>{return codes[i]==item.code})
          if(result){
            names.push(result.name)
          }
      }
      return names
    },
	uploadRemove(){
		this.form.img = ''
	},
	// 上传图片回调
	uploadResult(row){//logo
		if(row.type=='img'){
			this.form.img = row.url
		}
	},
	getpPosition(){
		config_index({option:'regionManager_position'}).then((result)=>{
			this.positionData = result.regionManager_position
		})
	},
	async switchClick(val,index){//禁用/启用
		this.isDisabledSwitch = true
		regionManager_lock({id:val.id}).then((result)=>{
			this.$message.success('操作成功');
			this.$refs.myTable.tableData[index].status= val?1:0
			setTimeout(()=>{
				this.isDisabledSwitch = false
			},1500)
		}).catch(()=>{
			this.isDisabledSwitch = false
		})
	},
	onSelectChange(val){
		console.log(val)
		
	},
	handleCancel(){
		this.submitLoading = false;
		this.dialogVisible=false
	},
	async onSubmit(){
		this.$refs.formModel.validate(async valid => {
			if (valid) {
				this.submitLoading = true
        let model = Object.assign({}, this.form);
        model.province_code = model.province_code.join(',')
				if(this.dialogType=='add'){
					regionManager_add(model).then((result)=>{
						this.$message.success('操作成功');
						setTimeout(()=>{
							this.$refs.myTable.getList()
							this.submitLoading = false;
							this.dialogVisible=false
						},1500)
					}).catch(()=>{
						this.submitLoading = false;
					})

				}else{
					regionManager_edit(model).then((result)=>{
						this.$message.success('操作成功');
						setTimeout(()=>{
							this.$refs.myTable.getList()
							this.submitLoading = false;
							this.dialogVisible=false
						},1500)
					}).catch(()=>{
						this.submitLoading = false;
					})
					
				}
				
			}else{
				return false
			}
		});
	},
	async delConfirm(val){//删除
		regionManager_del({id:val.id}).then((result)=>{
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.$refs.myTable.getList()
			},1500)
		})
	},
    dialogClick(type,row)  {
		this.dialogType=type;
		if(type=='edit'){
			this.form={
				"id":row.id,
				"user_name":row.user_name,
				"mobile":row.moble,
				"province_code":row.province_code.split(',').map(Number),
			}
		}else{
			this.form={
				"user_name":"",
        "mobile":"",
        "province_code":[],
			}
		}
		this.dialogVisible=true
    },
  },
}
</script>
