<!-- 账号管理 -->
<style scoped></style>
<template>
	<div>
		<div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
			<a-button type="primary" @click="dialogClick('add')">添加</a-button>
			<MyTable class="mt10" :apiUrl="pointGoods_index" :params="ajaxparams" ref="myTable" :columns="columns">
				<a-space slot='button' slot-scope="item,row,index">
					<a-button  size="small" type="primary"  @click="dialogClick('edit',row)">
						编辑
					</a-button>
					<a-popconfirm v-if="row.is_shelves == 0" title="确定是否上架?" ok-text="确定" cancel-text="取消" @confirm="changeStatus(row,1)">
						<a-button  size="small" type="primary" >
							上架
						</a-button>
					</a-popconfirm>
					<a-popconfirm v-else-if="row.is_shelves == 1" title="确定是否下架?" ok-text="确定" cancel-text="取消" @confirm="changeStatus(row,0)">
						<a-button size="small" type="warning">
							下架
						</a-button>
					</a-popconfirm>
					<a-popconfirm title="确定是否删除?" ok-text="确定" cancel-text="取消" @confirm="pointGoodsDel(row)">
						<a-button size="small" type="danger">
							删除
						</a-button>
					</a-popconfirm>
				</a-space>
				<a-space slot='sort' slot-scope="item,row,index">
					<editable-cell
						:ref="'edittableCell' + row.id"
						:text="row.sort"
						@change="onCellChange($event, row, index)"
					/>
				</a-space>
				<a-space slot='goods' slot-scope="item,row,index">
					<imgUpload
					v-if="row.cover_img"
					:list="row.cover_img ? [row.cover_img] : row.imgs"
					:disabled="true"
					:uploadType="'img'"
					></imgUpload>
					<div class="ellipsis-2">{{row.goods_name}}</div>
				</a-space>
				<a-space slot='is_shelves' slot-scope="item,row,index">
					<div>{{row.status==1?'上架':'下架'}}</div>
				</a-space>
			</MyTable>
		</div>
		<!-- 排行榜修改 -->
    <a-modal 
      :maskClosable="false" class="mb20" width="1000px" 
      :confirm-loading="submitLoading"
      @ok="onSubmit"
      @cancel="handleCancel"
      v-model="dialogVisible" :title="dialogType=='add'?'添加':'编辑'"  >
      <a-form-model
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        ref="formModel"
        size="small"
        :model="form"
      >
	  	<a-form-model-item label="商品名称" prop="goods_name">
            <a-input placeholder="请输入排序" v-model="form.goods_name" />
		</a-form-model-item>
		<a-form-model-item label="商品描述" prop="brief">
            <a-input placeholder="请输入商品描述" v-model="form.brief" />
		</a-form-model-item>
		<a-form-model-item label="积分" prop="points">
            <a-input placeholder="请输入积分" v-model="form.points" />
		</a-form-model-item>
		<a-form-model-item label="库存" prop="stock">
            <a-input placeholder="请输入库存" v-model="form.stock" />
		</a-form-model-item>
        <a-form-model-item
			label="封面图" prop="cover_img">
			<div class="fs12 color666">建议上传尺寸：750*750</div>
			<imgUpload
				v-if="dialogVisible"
				@remove="removeUpload"
				@result="resultUpload"
				:list="form.cover_img "
				:maxLength="1"
				:type="'cover'"
			></imgUpload>
		</a-form-model-item>
		<a-form-model-item label="轮播图" prop="banner_imgs">
            <div class="fs12 color666">建议上传尺寸：750*750</div>
            <imgUpload
              v-if="dialogVisible"
              @remove="removeUpload"
              @result="resultUpload"
              :list="form.banner_imgs ? form.banner_imgs : []"
              :maxLength="10"
              :type="'banner'"
            ></imgUpload>
		</a-form-model-item>
		<a-form-model-item label="商品详情" prop="detail">
            <wangeditor v-if="dialogVisible" @change="editorChange" :content="form.detail"></wangeditor>
		</a-form-model-item>
      </a-form-model>
    </a-modal>
	</div>
</template>
<script>
import imgUpload from "@/components/myUpload.vue"
import MyTable from "@/components/myTable.vue"
import EditableCell from "@/components/editableCell.vue"
import wangeditor from "../goods/components/wangeditor.vue"
import { pointGoods_index,pointGoods_add,pointGoods_edit,pointGoods_del,pointGoods_changeSort,pointGoods_changeShelves } from '@/api/goods.js'

export default {
	components: { MyTable, imgUpload, EditableCell, wangeditor },
	data() {
		return {
			pointGoods_index: pointGoods_index,
			IMGHOST: window.IMGHOST,
			ajaxparams: {},//请求参数
			/*
				列表header
			*/
			columns: [
				
				{ title: '商品', width:300, ellipsis: true, dataIndex: 'goods', key: 'goods',scopedSlots: { customRender: 'goods' } },
				{ title: '积分', width:100, ellipsis: true, dataIndex: 'points', key: 'points' },
				{ title: '库存', width:100, ellipsis: true, dataIndex: 'stock', key: 'stock' },
				{ title: '状态', width:80,  dataIndex: 'is_shelves', key: 'is_shelves' ,scopedSlots: { customRender: 'is_shelves' }},
				{ title: '排序', width:100,  dataIndex: 'sort', key: 'sort' ,scopedSlots: { customRender: 'sort' }},
				{ title: '创建时间', width:180, dataIndex: 'ctime', key: 'ctime' },
				{ title: '更新时间', width:180, dataIndex: 'utime', key: 'utime' },
				{
					width:200,
					title: '操作',
					key: 'operation',
					ellipsis: true,
					scopedSlots: { customRender: 'button' },
				},
			],
			isDisabledSwitch: false,//是否禁用Switch
			submitLoading: false,
			dialogType: 'add',
			dialogVisible: false,
			form: {
				"id": 0,
				"goods_name": "",
				"brief": "",
				"points": 0,
				"stock":0,
				"detail": '',
				"cover_img": [],
				"banner_imgs": []
			},
			rules: {
				goods_name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
				points: [{ required: true, message: "请输入积分", trigger: "blur" }],
				stock: [{ required: true, message: "请输入库存", trigger: "blur" }],
				brief: [{ required: true, message: "请输入商品描述", trigger: "blur" }],
				cover_img: [{ required: true, message: "请上传封面", trigger: "change" }],
				banner_imgs: [{ required: true, message: "请上传轮播图", trigger: "change" }],
			},
			positionData: [],
			loading: false,
			tableData: [],
		}
	},
	created() {
	},
	methods: {
		editorChange(html) {
			this.form.detail = html
		},
		handleCancel() {
			this.submitLoading = false;
			this.dialogVisible = false
		},
		async onSubmit() {
			this.$refs.formModel.validate(async (valid) => {
				if (valid) {
				let params = JSON.parse(JSON.stringify(this.form))
				params.banner_imgs = this.form.banner_imgs.join(",")
				params.cover_img = this.form.cover_img.join(",")
				this.submitLoading = true
				if (this.dialogType == "add") {
					pointGoods_add(params)
					.then((result) => {
						this.$message.success("操作成功")
						setTimeout(() => {
						this.$refs.myTable.getList()
						this.handleCancel("add")
						}, 1500)
					})
					.catch(() => {
						this.submitLoading = false
					})
				} else {
					pointGoods_edit(params)
					.then((result) => {
						this.$message.success("操作成功")
						setTimeout(() => {
						this.$refs.myTable.getList()
						this.handleCancel()
						}, 1500)
					})
					.catch(() => {
						this.submitLoading = false
					})
				}
				} else {
				return false
				}
			})
			},
		async changeStatus(val,status) {//删除
			pointGoods_changeShelves({ id: val.id,is_shelves:status }).then((result) => {
				this.$message.success('操作成功');
				setTimeout(() => {
					this.$refs.myTable.getList()
				}, 1500)
			})
		},
		async onCellChange(e, row, index) {
			//编辑排序
			if (!e || e == 0) {
				this.$refs["edittableCell" + row.id].resetEdit(row.sort)
				this.$message.warning("排序值必须大于0")
				return
			}
			pointGoods_changeSort({ id: row.id, sort: e })
				.then(() => {
				this.$message.success("操作成功")
				})
				.catch(() => {
				this.$refs["edittableCell" + row.id].resetEdit(row.sort)
				})
		},
		async pointGoodsDel(val) {//删除
			pointGoods_del({ id: val.id }).then((result) => {
				this.$message.success('操作成功');
				setTimeout(() => {
					this.$refs.myTable.getList()
				}, 1500)
			})
		},

		// 上传删除处理
		removeUpload(row) {
			if (row.type == "cover") {
				this.form.cover_img = []
			} else if (row.type == "banner") {
				this.form.banner_imgs.splice(row.index, 1)
			}
		},
		// 上传回调处理
		resultUpload(row) {
			if (row.type == "cover") {
				this.form.cover_img = [row.url]
			} else if (row.type == "banner") {
				this.form.banner_imgs.push(row.url)
			} 
		},

		dialogClick(type, row) {
			this.dialogType = type;
			if (type == 'edit') {
				this.form = {
					"id": row.id,
					"goods_name": row.goods_name,
					"brief": row.brief,
					"points": row.points,
					"detail": row.detail,
					"cover_img":row.cover_img.split(','),
					"banner_imgs":row.banner_imgs.split(','),
				}
			} else {
				this.form = {
					"goods_name": undefined,
					"brief": undefined,
					"points": undefined,
					"detail": '',
					"cover_img": [],
					"banner_imgs": []
				}
			}
			this.dialogVisible = true
		},
	},
}
</script>
