<!-- 搞单列表 -->
<template>
  <div>
    <div style="background-color: #fff; padding: 16px; margin-bottom: 20px">
      <div>
        <!-- <a-button type="primary" @click="doReady()">开单</a-button> -->
        <!-- 搜索条件 -->
        <a-form-model
          size="small"
          layout="inline"
          :model="ajaxparams"
          @keyup.native.enter="$refs.myTable.search()"
        >
          <a-form-model-item label="业务员名称">
            <a-input
              placeholder="业务员名称"
              v-model="ajaxparams['manager_name_no']"
            />
          </a-form-model-item>
          <a-form-model-item label="日报类型">
            <a-select
              style="width: 200px"
              allowClear
              placeholder="请选择日报类型"
              v-model="ajaxparams['1_report_type']"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in typeData"
                :key="index"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="选择日期">
            <a-range-picker v-model="ajaxparams['report_date_between']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" value=null />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              icon="search"
              @click="$refs.myTable.search()"
              type="primary"
              >搜索</a-button
            >
          </a-form-model-item>
          <a-form-model-item >
            <a-button :loading="buttonLoading"  @click="doExport" type="primary"> 导出 </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="mt10">
        <a-button type="primary" @click="check">添加假期</a-button>
      </div>
      <MyTable
        class="mt10"
        :apiUrl="orders_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
        @selectRow="onSelectChange"
        :isSelection="false"
      >
        <a-space slot="manager_id" slot-scope="item, row, index">
          <div>{{ findManager(item) }}</div>
        </a-space>
        <a-space slot="report_type" slot-scope="item, row, index">
          <div v-if="row.report_type == 1">正常提交</div>
          <div v-else-if="row.report_type == 2">国假（休息）</div>
          <div v-else-if="row.report_type == 3">病假</div>
          <div v-else-if="row.report_type == 4">事假</div>
        </a-space>
      </MyTable>

      <!-- 审核 -->
      <a-modal
        :maskClosable="false"
        v-model="dialogVisible"
        title="添加假期"
        :confirm-loading="submitLoading"
        @ok="onSubmit"
        @cancel="handleCancel"
      >
        <a-form-model :rules="rules" ref="formModel" size="small" :model="form">
          <a-form-model-item label="业务经理">
            <a-select
              style="width: 200px"
              allowClear
              v-model="form.manager_id"
              placeholder="请选择业务经理"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in user"
                :key="index"
                >{{ item.manager_name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="日报类型">
            <a-select
              style="width: 200px"
              allowClear
              v-model="form.report_type"
              placeholder="请选择日报类型"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in typeData"
                :key="index"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="日期">
            <a-date-picker
              v-model="form.date"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              value="null"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue";
import imgUpload from "@/components/myUpload.vue";

import {
  managerDailyReport_index,
  managerDailyReport_add,
  manager_user_index,
} from "@/api/manager.js";
import { daily_report }from "@/api/export"
export default {
  components: { MyTable, imgUpload },
  data() {
    return {
      orders_index: managerDailyReport_index,

      ajaxparams: {},

      /*
			列表header
		*/
      columns: [
        {
          title: "业务员",
          key: "manager_id",
          dataIndex: "manager_id",
          width: 130,
          scopedSlots: { customRender: "manager_id" },
        },
        {
          title: "日报类型",
          key: "report_type",
          dataIndex: "report_type",
          width: 130,
          scopedSlots: { customRender: "report_type" },
        },
        {
          title: "续费客户",
          key: "renew_user",
          dataIndex: "renew_user",
          width: 130,
        },
        {
          title: "续费金额",
          key: "renew_amount",
          dataIndex: "renew_amount",
          width: 130,
        },
        {
          title: "未续客户",
          key: "unrenew_user",
          dataIndex: "unrenew_user",
          width: 130,
        },
        {
          title: "未续原因",
          key: "unrenew_reason",
          dataIndex: "unrenew_reason",
          width: 200,
          ellipsis: true,
        },
        {
          title: "新客户",
          key: "new_user",
          dataIndex: "new_user",
          width: 130,
        },
        {
          title: "营销品种",
          key: "promote_goods",
          dataIndex: "promote_goods",
          width: 130,
        },
        {
          title: "业绩金额",
          key: "performance_amount",
          dataIndex: "performance_amount",
          width: 130,
        },
        {
          title: "营销问题",
          key: "feedback",
          dataIndex: "feedback",
          width: 200,
          ellipsis: true,
        },
        {
          title: "重点产品",
          key: "important_goods_promote",
          dataIndex: "important_goods_promote",
          width: 130,
        },
        {
          title: "其他工作",
          key: "other",
          dataIndex: "other",
          width: 130,
        },
        {
          title: "目标客户",
          key: "target_user",
          dataIndex: "target_user",
          width: 130,
        },
        {
          title: "目标业绩",
          key: "target_performance_amount",
          dataIndex: "target_performance_amount",
          width: 130,
        },
        {
          title: "沟通内容",
          key: "communicate_info",
          dataIndex: "communicate_info",
          width: 130,
        },
        {
          title: "日报日期",
          ellipsis: true,
          dataIndex: "report_date",
          key: "report_date",
          width: 220,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "create_at",
          key: "create_at",
          width: 220,
        },
      ],
      dialogVisible: false,
      submitLoading: false,
      buttonLoading: false,
      form: {},

      rules: {
        manager_id: [
          { required: true, message: "请选择业务经理", trigger: "blur" },
        ],
        report_type: [
          { required: true, message: "请选择日报类型", trigger: "blur" },
        ],
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
      },
      typeData: [
        {
          id: "2",
          name: "国假(休息)/生日",
        },
        {
          id: "3",
          name: "病假",
        },
        {
          id: "4",
          name: "事假",
        },
      ],
      user: [],
    };
  },
  created() {
    this.getUser();
  },
  methods: {
      //执行导出
      doExport(){
        this.buttonLoading = true
        daily_report(this.ajaxparams).then(res => {
          this.buttonLoading = false
          this.$message.success("添加导出队列成功")
          this.$db.set('is_export',1)
        },err => {
          this.buttonLoading = false
          this.$message.warning("导出失败")
        })
        
      },
    //查找业务员
    findManager(manager_id) {
      if (!manager_id) return "";
      const targetManager = this.user.filter((obj) => obj.id == manager_id);
      if(targetManager && targetManager.length > 0 ) return targetManager[0].manager_name
      else return '-'
    },
    check(row) {
      this.dialogVisible = true;
    },
    async getUser() {
      let res = await manager_user_index();
      console.log("🚀 ~ getUser ~ res:", res);
      this.user = res;
      this.user.unshift({
        id: 0,
        manager_name: "全部",
      });
    },
    async onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          console.log(this.form, 111);
          this.submitLoading = true;
          managerDailyReport_add(this.form)
            .then((result) => {
              this.$message.success("操作成功");
              setTimeout(() => {
                this.$refs.myTable.getList();
                this.submitLoading = false;
                this.dialogVisible = false;
              }, 1500);
            })
            .catch(() => {
              this.submitLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.submitLoading = false;
      this.dialogVisible = false;
    },
  },
};
</script>
