<style scoped>
</style>
<template>
  <div class="pb20">
    <a-card class="w-100">
      <a-descriptions title="用户数据" bordered style="margin-top:15px;">
        <a-descriptions-item label="总用户数">
          {{indexData.user_total}}
        </a-descriptions-item>
        <a-descriptions-item label="今日新增">
          {{indexData.user_today_new}}
        </a-descriptions-item>
        <a-descriptions-item label="今日付款">
          {{indexData.user_today_pay}}
        </a-descriptions-item>
        <a-descriptions-item label="A1">
          {{indexData.A1}}
        </a-descriptions-item>
        <a-descriptions-item label="A2">
          {{indexData.A2}}
        </a-descriptions-item>
        <a-descriptions-item label="A3">
          {{indexData.A3}}
        </a-descriptions-item>
        <a-descriptions-item label="VIP">
          {{indexData.vip}}
        </a-descriptions-item>
        <a-descriptions-item label="非会员">
          {{indexData.none}}
        </a-descriptions-item>
        <a-descriptions-item label="过期会员">
          {{indexData.expired}}
        </a-descriptions-item>
        <a-descriptions-item label="内部合伙人">
          {{indexData.partner}}
        </a-descriptions-item>
        <a-descriptions-item label="充值总额">
          {{$common.formatMoney(indexData.recharge_amount/100)}}
        </a-descriptions-item>
        <a-descriptions-item label="赠送共富币">
          {{$common.formatMoney(indexData.give_away_coin/100)}}
        </a-descriptions-item>
        
      </a-descriptions>
      <a-descriptions title="订单数据" bordered style="margin-top:15px;">
        <a-descriptions-item label="总订单数">
          {{indexData.order_total}}
        </a-descriptions-item>
        <a-descriptions-item label="有效果订单">
          {{indexData.order_available}}
        </a-descriptions-item>
         <!-- <a-descriptions-item label="取消订单">
          {{indexData.order_cancel}}
        </a-descriptions-item> -->
        <a-descriptions-item label="商品总额">
          {{$common.formatMoney(indexData.goods_amount/100)}}
        </a-descriptions-item>
        <a-descriptions-item label="订单总额">
          {{$common.formatMoney(indexData.order_amount/100)}}
        </a-descriptions-item>
        <a-descriptions-item label="余额支付">
          {{$common.formatMoney(indexData.balance_pay_amount/100)}}
        </a-descriptions-item>
        <a-descriptions-item label="共富币支付">
          {{$common.formatMoney(indexData.coin_pay_amount/100)}}
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
    
    <a-row :gutter="30" >
     <!-- 近期订单数量趋势 -->
    <a-col class="mt20" :xs="12" :sm="12" :lg="12" >
        <a-card class="w-100 ">
          <div  class="w-100" id="bar1" style="height:500px"></div>
        </a-card>
      </a-col>
    <!-- 近期交易金额趋势 -->
      <a-col class="mt20" :xs="12" :sm="12" :lg="12" >
        <a-card class="w-100 ">
        <div class="w-100" id="bar2" style="height:500px"></div>
        </a-card>
      </a-col>
       <!-- 花钱最多的用户 -->
      <a-col class="mt20" :xs="12" :sm="12" :lg="12" >
        <a-card class="w-100 ">
          <div  class="w-100" id="spendingMoney" style="height:500px"></div>
        </a-card>
      </a-col>
       <!-- Top 10 -->
      <a-col class="mt20" :xs="12" :sm="12" :lg="12" >
        <a-card class="w-100 ">
        <div class="w-100" id="china" style="height:500px"></div>
        </a-card>
      </a-col>
      
    </a-row>
  </div>
</template>

<script>
import * as echarts from "echarts"
import {market_data} from '@/api/data'
export default {
  data() {
    return {
      indexData:{
        user_total:0,
        user_today_new:0,
        user_today_pay:0,
        none:0,
        vip:0,
        A1:0,
        A2:0,
        A3:0,
        salesman:0,
        partner:0,
        order_total:0,
        order_available:0,
        order_cancel:0,
        goods_amount:0,
        order_amount:0,
        balance_pay_amount:0,
        coin_pay_amount:0,
        recent_order:[],
        recent_amount:[],
        user_province:[],
        recent_user_order_amount:[],// 近期用户订单金额
      },
    }
  },
  created() {
	  
	  
  },
  mounted(){
    market_data().then((res)=> {
      // 工作台,用户数据,订单数据 赋值
      for (let key in res){
        this.$set(this.indexData,key,res[key])
      }
      this.barInit(res.recent_order,'bar1')
      let recent_amount = {}
      for (const key in res.recent_amount) {
        recent_amount[key] = res.recent_amount[key]/100
      }

      this.barInit(recent_amount,'bar2')
      this.barInit(res.recent_user_order_amount?res.recent_user_order_amount:[],'spendingMoney')
     
      let provinceNames = []
      let provinceSeries = []
      let userLevel = []
      let sortData = [];
      const levels = ['none','vip','A1','A2','A3']
      levels.forEach(v => {
        userLevel.push({
            name: v === 'none' ? '非会员' : v,
            type: 'bar',
            stack: 'total',
            label: {
              show: false
            },
            emphasis: {
              focus: 'series'
            },
            data:[]
        })
      })
      for (let key in res.user_province) {
        provinceNames.push(res.user_province[key]['name'])
        levels.forEach((v,k) => {
          userLevel[k]['data'].push(res.user_province[key][v])
        })
      }
      this.provinceInit(provinceNames,userLevel)
    }).catch((e)=>{
      console.log(e)
    })
  },
  methods: {
    provinceInit(yData,userLevel){
      const provinceChart = echarts.init(document.getElementById('china')); 
      var option = {
        title:{
          "text":"Top 10"
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: yData
        },
        series: userLevel
      }
      provinceChart.setOption(option)
    },
    barInit(data,id,type='bar'){
      let xData = []
      let yData = []
      if(id=='spendingMoney'){
       for (let i = 0; i < data.length; i++) {
        xData.push(data[i].name)  
        yData.push(data[i].order_amount/100)      
       }
      }else{
         for (const item in data) {
          xData.push(item)
          yData.push(data[item])
        }
      }
      let series = {
          name:id=='bar2'||id=='spendingMoney'?'金额':'数量',
          type:type,
          label:{show:true,position: 'top',},
          data:yData,
      }
   
      var mycahrt=echarts.init(document.getElementById(id));   //显示 id main  dark 主题
      var option={
          backgroundColor: '#fff',   //背景
          title: {
              text: id=='spendingMoney'?'花钱最多的用户':id=='bar1'?'近期订单数量趋势':'近期交易金额趋势' //      //标题,可选
            },
            tooltip: {},
            xAxis:{
                splitLine: {show: false},   //是否显示网格线，默认不显示，可选
                data:xData
            },
            yAxis: {splitLine: {show: false},},                                       //默认显示，可选
            series:[series]
      };
      mycahrt.setOption(option);     //生成图表
    },
    
  },
}
</script>
