<!-- 店铺设置 -->
<style scoped>
	
</style>
<template>
  <div >
	<div class="pageCentent">
		<a-form-model
		    ref="ruleForm"
		    :model="form"
		    :rules="rules"
		    :label-col="labelCol"
		    :wrapper-col="wrapperCol"
		  >	
			<!-- <a-form-model-item label="切换操作状态" v-if="!type">
				<a-radio-group v-model="type" button-style="solid">
				  <a-radio-button :value="0">
					查看
				  </a-radio-button>
				  <a-radio-button value="edit">
					编辑
				  </a-radio-button>
				</a-radio-group>
			</a-form-model-item>
			<a-form-model-item v-if="type=='add'" ref="mobile" label="管理员账号" prop="administrator">
			  <a-input v-model="form.administrator" @blur="() => { $refs.administrator.onFieldBlur();}" />
			  <span v-else>{{detail_data.administrator}}</span>
			</a-form-model-item> -->
		    <a-form-model-item ref="mobile" label="手机号" prop="mobile">
				<a-input v-model="form.mobile" @blur="() => { $refs.mobile.onFieldBlur();}" />
			</a-form-model-item>
		    <a-form-model-item label="电子邮箱" ref="email" prop="email">
				<a-input v-model="form.email" @blur=" () => {  $refs.email.onFieldBlur(); } " />
			</a-form-model-item>
			
			<a-form-model-item label="商家名称" ref="name" prop="name">
				<a-input v-model="form.name" @blur=" () => {$refs.name.onFieldBlur(); }"/>
			</a-form-model-item>
			<a-form-model-item label="品牌" ref="brand" prop="brand">
				<a-input v-model="form.brand" @blur="() => { $refs.brand.onFieldBlur();}"/>
			</a-form-model-item>
			<a-form-model-item label="经营范围" ref="business_scope" prop="business_scope">
				<a-input :maxLength="200" v-model="form.business_scope" type="textarea" />
			</a-form-model-item>
			
			<!-- <a-form-model-item label="运营状态" ref="operation_status" prop="operation_status" v-if="type!='add'">
				<a-select v-model="form.operation_status" style="width: 150px" >
					<a-select-option :value="0">默认</a-select-option>
					<a-select-option :value="1">自有品牌</a-select-option>
					<a-select-option :value="2">第三方代运营</a-select-option>
					<a-select-option :value="3">第三方</a-select-option>
				</a-select>
			</a-form-model-item> -->
		    <a-form-model-item label="商家描述" ref="summary" prop="summary">
				<a-input :maxLength="200" v-model="form.summary" type="textarea" />
			</a-form-model-item>
			<!-- <a-form-model-item label="是否品牌街店铺" ref="is_street" prop="is_street" v-if="type!='add'">
				<a-radio-group v-model="form.is_street">
					<a-radio :value="1">是</a-radio>
					<a-radio :value="0">否</a-radio>
				</a-radio-group>
			</a-form-model-item> -->
		    <a-form-model-item label="LOGO" ref="logo" prop="logo">
		      <logoUpload ref="logo" v-if="isHttp"  :list="form.logo?[form.logo]:[]" :maxLength="1" :type="'logo'"  @result="uploadResult"></logoUpload>
		    </a-form-model-item>
			<a-form-model-item label="营业执照图片" ref="license_pic" prop="license_pic">
			  <license_picUpload v-if="isHttp" ref="license_pic" :list="form.license_pic?[form.license_pic]:[]" :type="'license_pic'" :maxLength="1"  @result="uploadResult"></license_picUpload>
			</a-form-model-item>
			<a-form-model-item label="商标注册证件照" ref="trademark_pic" prop="trademark_pic">
			  <trademark_picUpload v-if="isHttp"  ref="trademark_pic" :list="form.trademark_pic?[form.trademark_pic]:[]" :type="'trademark_pic'" :maxLength="1"  @result="uploadResult"></trademark_picUpload>
			</a-form-model-item>
			<a-form-model-item label="其他照片" ref="other_pic" prop="other_pic">
			  <other_picUpload v-if="isHttp" @remove="uploadRemove" ref="other_pic" :list="form.other_pic" :maxLength="5" :type="'other_pic'" @result="uploadResult"></other_picUpload>
			</a-form-model-item>
		    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }" >
		      <a-button class="mr20" type="primary" @click="onSubmit">
		        提交
		      </a-button>
		    </a-form-model-item>
		  </a-form-model>
	</div>
  </div>
</template>
<script>
import logoUpload from "@/components/myUpload.vue"
import license_picUpload from "@/components/myUpload.vue"
import trademark_picUpload from "@/components/myUpload.vue"
import other_picUpload from "@/components/myUpload.vue"
import myUpload from "@/components/myUpload.vue"
export default {
  components:{logoUpload,license_picUpload,trademark_picUpload,other_picUpload},
  data() {
    return {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
		other: '',
		type:'edit',
		detail_data:{},
		form: {
			"id": 0,
			"mobile": "",
			"administrator":'',
			"email": "",
			"name": "",
			"brand":"",
			"logo": "",
			"summary": "",
			"business_scope": "",
			"license_pic": "",
			"trademark_pic": "",
			"other_pic": [],
			"is_street": 1,
			"operation_status": 1,
			"sort": 99,
			"status": 1
		},
		rules: {
			name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
			mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
			email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
			brand: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
			summary: [{ required: true, message: '请输入简介', trigger: 'blur' }],
			business_scope: [{ required: true, message: '请输入经营范围', trigger: 'blur' }],
			sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
			logo: [{ required: true, message: '请上传图片', trigger: 'change' }],
			license_pic: [{ required: true, message: '请上传图片', trigger: 'change' }],
			trademark_pic: [{ required: true, message: '请上传图片', trigger: 'change' }],
			other_pic: [{ required: true, message: '请上传图片', trigger: 'change' }],
		},
		isHttp:false
	}
  },
  mounted() {
	this.type = this.$route.query.type?this.$route.query.type:0;
	console.log(this.type)
	if(this.type=='add'){
		this.isHttp=true
	}else{
		this.merchant_setting_info()
	}
  },
  methods: {
	async merchant_setting_info(){
		let res = await this.$api.merchant_setting_info({id:this.$route.query.id})
		if(res.code==200){
			this.form = res.result
			this.detail_data = JSON.parse(JSON.stringify(res.result))
			setTimeout(()=>{
				this.isHttp=true
			},10)
		}
	},
	uploadResult(row){//logo
		console.log(row)
		if(row.type=='logo'){
			this.form.logo = row.url
		}else if(row.type=='license_pic'){
			this.form.license_pic = row.url
		}else if(row.type=='trademark_pic'){
			this.form.trademark_pic = row.url
		}else if(row.type=='other_pic'){
			this.form.other_pic.push(row.url)
		}
	},
	uploadRemove(row){
		if(row.type=='logo'){
			this.form.logo = ''
		}else if(row.type=='license_pic'){
			this.form.license_pic = ''
		}else if(row.type=='trademark_pic'){
			this.form.trademark_pic = ''
		}else if(row.type=='other_pic'){
			this.form.other_pic.splice(row.index, 1); 
		}
	},
	onSubmit() {
		this.$refs.ruleForm.validate(async valid => {
			if (valid) {
				let request = {
					"id": this.form.id,
					"mobile": this.form.mobile,
					"email":  this.form.email,
					"name":  this.form.name,
					"brand": this.form.brand,
					"logo":  this.form.logo,
					"summary":  this.form.summary,
					"business_scope":  this.form.business_scope,
					"license_pic":  this.form.license_pic,
					"trademark_pic": this.form.trademark_pic,
					"other_pic": this.form.other_pic,
					// "operation_status": this.form.operation_status,
					// "sort": this.form.sort,
					// "status": this.form.status
				}
				let res = await this.$api.merchant_setting_edit(request)
				if(res.code==200){
					this.$message.success('操作成功');
					setTimeout(()=>{
						this.merchant_setting_info()
						this.type=0
					},1000)
				}
			} else {
				console.log('error submit!!');
				return false;
			}
		});
	},
	resetForm() {
		this.$refs.ruleForm.resetFields();
	},
  },
}
</script>
