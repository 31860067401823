<template>
  <a-row :gutter="30" class="pana-group">
  <!-- 商品数量 -->
    <a-col :xs="6" :sm="6" :lg="6" class="card-pana-col">
      <div class="card-pana" @click="handleSetLineChartData('newVisitis')">
        <div class="card-pana-icon-wrapper  icon-shopping">
          <a-icon type="database" theme="filled" class="card-pana-icon" />
        </div>
        <div class="card-pana-description">
          <div class="card-pana-text">
            商品总量
          </div>
          <count-to :start-val="0" :end-val="totalData.goods" :duration="2600" class="card-pana-num" />
        </div>
      </div>
    </a-col>
    <!-- 用户数量 -->
    <a-col :xs="6" :sm="6" :lg="6" class="card-pana-col">
      <div class="card-pana" @click="handleSetLineChartData('messages')">
        <div class="card-pana-icon-wrapper icon-message">
          <a-icon type="idcard" theme="filled" class="card-pana-icon"  />
        </div>
        <div class="card-pana-description">
          <div class="card-pana-text">
            用户总量
          </div>
          <count-to :start-val="0" :end-val="totalData.user" :duration="3000" class="card-pana-num" />
        </div>
      </div>
    </a-col>
    <!-- 订单总量 -->
    <a-col :xs="6" :sm="6" :lg="6" class="card-pana-col">
      <div class="card-pana" @click="handleSetLineChartData('purchases')">
        <div class="card-pana-icon-wrapper icon-money">
          <a-icon type="profile" theme="filled" class="card-pana-icon"  />
        </div>
        <div class="card-pana-description">
          <div class="card-pana-text">
            订单总量
          </div>
          <count-to :start-val="0" :end-val="totalData.order" :duration="3000" class="card-pana-num" />
        </div>
      </div>
    </a-col>
    <!-- 有效订单量 -->
    <a-col :xs="6" :sm="6" :lg="6" class="card-pana-col">
      <div class="card-pana" @click="handleSetLineChartData('purchases')">
        <div class="card-pana-icon-wrapper icon-money">
          <a-icon type="profile" theme="filled" class="card-pana-icon"  />
        </div>
        <div class="card-pana-description">
          <div class="card-pana-text">
            有效订单量
          </div>
          <count-to :start-val="0" :end-val="totalData.available_order" :duration="3000" class="card-pana-num" />
        </div>
      </div>
    </a-col>
    <!-- 商品总额 -->
    <a-col :xs="6" :sm="6" :lg="6" class="card-pana-col">
      <div class="card-pana" @click="handleSetLineChartData('shoppings')">
        <div class="card-pana-icon-wrapper icon-people">
          <a-icon type="account-book" theme="filled" class="card-pana-icon" />
        </div>
        <div class="card-pana-description">
          <div class="card-pana-text">
            商品总额
          </div>
          <count-to :start-val="0" :end-val=" totalData.goods_amount" :duration="3600" class="card-pana-num" />
        </div>
      </div>
    </a-col>
    <!-- 交易总额 -->
    <a-col :xs="6" :sm="6" :lg="6" class="card-pana-col">
      <div class="card-pana" @click="handleSetLineChartData('shoppings')">
        <div class="card-pana-icon-wrapper icon-people">
          <a-icon type="account-book" theme="filled" class="card-pana-icon" />
        </div>
        <div class="card-pana-description">
          <div class="card-pana-text">
            交易总额
          </div>
          <count-to :start-val="0" :end-val=" totalData.total_amount" :duration="3600" class="card-pana-num" />
        </div>
      </div>
    </a-col>
    <!-- 余额支付额 -->
    <a-col :xs="6" :sm="6" :lg="6" class="card-pana-col">
      <div class="card-pana" @click="handleSetLineChartData('shoppings')">
        <div class="card-pana-icon-wrapper icon-people">
          <a-icon type="account-book" theme="filled" class="card-pana-icon" />
        </div>
        <div class="card-pana-description">
          <div class="card-pana-text">
            余额支付额
          </div>
          <count-to :start-val="0" :end-val=" totalData.payed_amount " :duration="3600" class="card-pana-num" />
        </div>
      </div>
    </a-col>
    <!-- 共富币支付额 -->
    <a-col :xs="6" :sm="6" :lg="6" class="card-pana-col">
      <div class="card-pana" @click="handleSetLineChartData('shoppings')">
        <div class="card-pana-icon-wrapper icon-people">
          <a-icon type="account-book" theme="filled" class="card-pana-icon" />
        </div>
        <div class="card-pana-description">
          <div class="card-pana-text">
            共富币支付额
          </div>
          <count-to :start-val="0" :end-val=" totalData.coin" :duration="3600" class="card-pana-num" />
        </div>
      </div>
    </a-col>
  </a-row>
  
</template>

<script>
import CountTo from 'vue-count-to'
export default {
  components: {
    CountTo
  },
  props: {
    totalData: {
      type: Object,
      default: function () {
        return {
          goods:0,
          user:0,
          order:0,
          available_order:0, 
          total_amount:0,
          payed_amount:0,
          goods_amount:0,
          coin:0,
        }
      }
    }
  },
  methods: {
    handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type)
    }
  }
}
</script>

<style scoped>
  .pana-group {
    /* margin-top: 18px; */

  }
  .card-pana-col {
    margin-bottom: 32px;
  }

  .card-pana {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: raative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

  }
  .card-pana:hover .card-pana-icon-wrapper {
      color: #fff;
    }

  .card-pana:hover .icon-people {
      background: #40c9c6;
    }

  .card-pana:hover .icon-message {
      background: #36a3f7;
    }

  .card-pana:hover .icon-money {
      background: #f4516c;
    }

  .card-pana:hover .icon-shopping {
      background: #34bfa3
    }

  .icon-people {
    color: #40c9c6;
  }

  .icon-message {
    color: #36a3f7;
  }

  .icon-money {
    color: #f4516c;
  }

  .icon-shopping {
    color: #34bfa3
  }

  .card-pana-icon-wrapper {
    float: left;
    margin: 14px 0 0 14px;
    padding: 16px;
    transition: all 0.38s ease-out;
    border-radius: 6px;
  }

  .card-pana-icon {
    float: left;
    font-size: 48px;
  }

  .card-pana-description {
    float: right;
    font-weight: bold;
    margin: 26px;
    margin-left: 0px;

  }
  .card-pana-text {
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    margin-bottom: 12px;
  }

  .card-pana-num {
    font-size: 20px;
  }

  @media (max-width:550px) {
    .card-pana-description {
      display: none;
    }

    .card-pana-icon-wrapper {
      float: none !important;
      width: 100%;
      height: 100%;
      margin: 0 !important;

    }
    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
</style>
