<!-- 账号管理 -->
<style scoped>
.width150 {
  width: 150px;
}
</style>
<template>
  <div>
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
      <!-- 搜索条件 -->
      <a-form-model
        size="small"
        layout="inline"
        :model="ajaxparams"
        @keyup.native.enter="$refs.myTable.search()"
      >
        <a-form-model-item label="业务员">
          <a-input  placeholder="请输入业务员名称" v-model="ajaxparams['salesman_no']" />
        </a-form-model-item>
        <a-form-model-item label="订单号">
          <a-input placeholder="请输入订单号" v-model="ajaxparams['1_order_sn_like']" />
        </a-form-model-item>
        <a-form-model-item label="用户昵称">
          <a-input placeholder="请输入用户昵称" v-model="ajaxparams['2_name_like']" />
        </a-form-model-item>
        <a-form-model-item label="是否预售" prop="is_presale">
          <a-select allowClear class="width150" v-model="ajaxparams['is_presale']" placeholder="请选择" >
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="用户手机号">
          <a-input placeholder="请输入用户手机号" v-model="ajaxparams['2_mobile_like']" />
        </a-form-model-item>
        <a-form-model-item label="收货人">
          <a-input placeholder="请输入收货人" v-model="ajaxparams['1_consignee_name_like']" />
        </a-form-model-item>
        <a-form-model-item label="收货人联系方式">
          <a-input placeholder="请输入收货人联系方式" v-model="ajaxparams['1_consignee_mobile_like']" />
        </a-form-model-item>
        <a-form-model-item label="商品名称">
          <a-input placeholder="请输入商品名称" v-model="ajaxparams['3_goods_name_like']" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button icon="search" @click="$refs.myTable.search()" type="primary">搜索</a-button>
        </a-form-model-item>
        <a-form-model-item >
          <a-button  @click="doExport" type="primary"> 导出 </a-button>
        </a-form-model-item>

        <a-form-model-item >
          <a-button  @click="doUnDeliveryGoodsExport" type="primary"> 导出未发货商品 </a-button>
        </a-form-model-item>

      </a-form-model>
      <MyTable
        class="mt10"
        :apiUrl="orders_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
      >
        <a-space slot="button" slot-scope="item,row,index">
          <a-button
            :disabled="row.ready_num <= row.delivery_item" 
            size="small"
            type="primary"
            @click="dialogClick('logi',row)"
          >发货</a-button>
          <a-button
            v-if="row.order_status_gather<3"
            size="small"
            @click="dialogClick('consignee',row)"
          >修改收货地址</a-button>
          <a-button size="small" type="dashed" @click="dialogClick('remark',row)">修改订单备注</a-button>
        </a-space>
        <a-space slot="order_sn" slot-scope="item,row,index">
          <router-link target="_blank" :to="{ path: '/orders/detail', query: { id: row.order_sn }}">
            <span>{{row.order_sn}}</span>
          </router-link>
        </a-space>

        <a-space slot="order_status_gather" slot-scope="item,row,index">
          <span>{{orderStatusName[row.order_status_gather]}}</span>
        </a-space>
        <a-space slot="order_type" slot-scope="item,row,index">
          <span>{{row.order_type==0?'关闭':row.order_type==1?'正常':'完成'}}</span>
        </a-space>
        <a-space slot="pay_status" slot-scope="item,row,index">
          <span>{{row.pay_status==0?'未支付':'已支付'}}</span>
        </a-space>
        <a-space slot='is_presale' slot-scope="item,row,index">
          <span>{{row.is_presale==0?'否':'是'}}</span>
        </a-space>
        <a-space slot="manage_id" slot-scope="item,row,index">
          <span>{{ salesmen[item] ? salesmen[item]['name'] : "-"}}</span>
        </a-space>
        <!-- <a-space slot="is_first" slot-scope="item,row,index">
          <span>{{row.is_first==0?'否':'是'}}</span>
        </a-space> -->

        <a-space slot="delivery_status" slot-scope="item,row,index">
          <span>{{row.delivery_status==0?'未发货':row.delivery_status==1?'部分发货':'已发货'}}</span>
        </a-space>
        <a-space slot="goods_amount" slot-scope="item,row,index">
          <span>{{$common.countFloat((row.goods_amount?row.goods_amount:0)/100,2)}}</span>
        </a-space>
        <a-space slot="payed_amount" slot-scope="item,row,index">
          <span>{{$common.countFloat((row.payed_amount?row.payed_amount:0)/100,2)}}</span>
        </a-space>
        <a-space slot="order_amount" slot-scope="item,row,index">
          <span>{{$common.countFloat((row.order_amount?row.order_amount:0)/100,2)}}</span>
        </a-space>
        <a-space slot="coupon_discount_amount" slot-scope="item,row,index">
          <span>{{$common.countFloat((row.coupon_discount_amount?row.coupon_discount_amount:0)/100,2)}}</span>
        </a-space>
        <a-space slot="address" slot-scope="item,row,index">
          <span>{{row.consignee_area+' ' + row.consignee_address}}</span>
        </a-space>
      </MyTable>
    </div>
    <a-modal
      :maskClosable="false"
      v-model="dialogVisible"
      width="80%"
      :title="dialogType=='logi'?'发货':dialogType=='consignee'?'修改收货地址':'修改备注'"
      :confirm-loading="submitLoading"
      @ok="onSubmit"
      @cancel="handleCancel"
    >
      <a-form-model :rules="rules" ref="formModel" size="small" :model="form">
        <div v-if="dialogType=='consignee'">
          <a-form-model-item label="收货人姓名" prop="consignee_name">
            <a-input placeholder="请输入收货人姓名" v-model="form.consignee_name" />
          </a-form-model-item>
          <a-form-model-item label="收货人手机号" prop="consignee_mobile">
            <a-input placeholder="请输入收货人手机号" v-model="form.consignee_mobile" />
          </a-form-model-item>
          <a-form-model-item label="地区" prop="address">
            <a-cascader
              v-model="form.address"
              :options="regionTree"
              :field-names="{ label: 'name', value: 'code', children: 'children' }"
              :show-search="{ filterProvince }"
              placeholder="请选择地区"
            />
          </a-form-model-item>
          <a-form-model-item label="详细地址" prop="consignee_address">
            <a-textarea placeholder="请输入详细地址" v-model="form.consignee_address" />
          </a-form-model-item>
        </div>
        <div v-else-if="dialogType=='remark'">
          <a-form-model-item label="备注" prop="remark">
            <a-textarea placeholder="请输入备注" v-model="form.remark" />
          </a-form-model-item>
        </div>
        <div v-else-if="dialogType=='logi'">
          <a-form-model-item label="物流/快递" prop="logi_id" >
            <a-select allowClear v-model="form.logi_id" placeholder="请选择物流/快递"  :showSearch="true" optionFilterProp="children">
              <a-select-option
                v-for="(item,index) in logisticsData"
                :key="index"
                :value="item.id"
              >{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="物流/快递单号" prop="logi_no">
            <a-input placeholder="自提订单 请以ZT00开头" v-model="form.logi_no" />
          </a-form-model-item>
          <a-form-model-item label="选择发货物品">
            <a-table
              rowKey="id"
              :row-selection="selectRow"
              :columns="goods_columns"
              :data-source="order_data.ordersItems?order_data.ordersItems:[]"
              bordered
            >
              <a-space slot="price" slot-scope="item,row,index">
                <span>¥{{$common.toDecimal2(row.price/100)}}</span>
              </a-space>
              <!-- <a-space slot="img" slot-scope="item,row,index">
                <a-avatar shape="square" :size="44" :src="IMGHOST+row.img" />
              </a-space> -->
              <a-space slot="is_ready" slot-scope="item,row,index">
                <span>{{row.is_ready==1?'已开单':'未开单'}}</span>
              </a-space>
              <a-space slot="is_delivery" slot-scope="item,row,index">
                <span>{{row.is_delivery == 1 ? '已发货': (row.delivery_num == 0 ? '未发货' : "部分发货")}}</span>
              </a-space>
              
              <a-space slot="current_delivery_num" slot-scope="item,row,index">
                  <editable-cell ref="edittableCell" :text="row.num - row.delivery_num" @change="changeDeliveryNum($event,row,index)" />
              </a-space>
            </a-table>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import EditableCell from "@/components/editableCell.vue"
import MyTable from "@/components/myTable.vue"
import {
  orders_index,
  orders_consignee,
  orders_remark,
  orders_delivery,
  orders_read,
} from "@/api/orders"
import { users_salesman } from "@/api/users"
import { order_index,undelivery_goods }from "@/api/export"
export default {
  components: { MyTable ,EditableCell},
  data() {
    return {
      orders_index: orders_index,
      IMGHOST: window.IMGHOST,

      ajaxparams: {
        "1_delivery_status_in": "0,1",
        pay_status: "1",
        "1_order_status":1,
        title_no:"待发货列表"

      }, //请求参数
      /*
			列表header
		*/
      columns: [
        {
          title: "订单号",
          ellipsis: true,
          width: 220,
          fixed: "left",
          dataIndex: "order_sn",
          key: "order_sn",
          scopedSlots: { customRender: "order_sn" },
        },
        {
          title: "用户名称",
          ellipsis: true,
          dataIndex: "name",
          fixed: "left",
          key: "name",
          width: 100,
          scopedSlots: { customRender: "name" },
        },
        { title: '业务员',dataIndex:  'manage_id',key: 'manage_id', width: 100,scopedSlots: { customRender: 'manage_id' }},
        {
          title: "订单商品金额",
          ellipsis: true,
          dataIndex: "goods_amount",
          key: "goods_amount",
          width: 120,
          scopedSlots: { customRender: "goods_amount" },
        },
        { title: '是否预售', ellipsis: true,dataIndex: 'is_presale', key: 'is_presale', width: 100,scopedSlots: { customRender: 'is_presale' }},
        {
          title: "订单金额",
          ellipsis: true,
          dataIndex: "order_amount",
          key: "order_amount",
          width: 120,
          scopedSlots: { customRender: "order_amount" },
        },
        {
          title: "订单支付金额",
          ellipsis: true,
          dataIndex: "payed_amount",
          key: "payed_amount",
          width: 120,
          scopedSlots: { customRender: "payed_amount" },
        },
        {
          title: "订单备注",
          ellipsis: true,
          dataIndex: "user_remark",
          key: "user_remark",
          width: 200,
        },
        {
          title: "商家备注",
          ellipsis: true,
          dataIndex: "merchant_remark",
          key: "merchant_remark",
          width: 200,
          scopedSlots: { customRender: "merchant_remark" },
        },
        {
          title: "收货人姓名",
          ellipsis: true,
          dataIndex: "consignee_name",
          key: "consignee_name",
          width: 150,
          scopedSlots: { customRender: "consignee_name" },
        },
        {
          title: "收货人联系方式",
          ellipsis: true,
          dataIndex: "consignee_mobile",
          key: "consignee_mobile",
          width: 150,
          scopedSlots: { customRender: "consignee_mobile" },
        },
        {
          title: "收货人地址",
          ellipsis: true,
          dataIndex: "address",
          key: "address",
          width: 250,
          scopedSlots: { customRender: "address" },
        },
        {
          title: "取消备注",
          ellipsis: true,
          dataIndex: "cancel_remark",
          key: "cancel_remark",
          width: 200,
          scopedSlots: { customRender: "cancel_remark" },
        },
        // {
        //   title: "是否首单",
        //   ellipsis: true,
        //   dataIndex: "is_first",
        //   key: "is_first",
        //   width: 100,
        //   scopedSlots: { customRender: "is_first" },
        // },

        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "ctime",
          key: "ctime",
          width: 220,
        },
        {
          title: "更新时间",
          ellipsis: true,
          dataIndex: "utime",
          key: "utime",
          width: 220,
        },
        {
          title: "操作",
          key: "operation",
          fixed: "right",
          width: 300,
          ellipsis: true,
          scopedSlots: { customRender: "button" },
        },
      ],
      orderStatusName: {
        "-1": "关闭",
        1: "待付款",
        2: "待发货",
        3: "部分发货",
        4: "已发货",
        5: "待评价",
        6: "售后中",
        7: "交易成功",
      },
      isDisabledSwitch: false, //是否禁用Switch
      submitLoading: false,
      dialogType: "add",
      dialogVisible: false,
      form: {
        order_sn: "",
        logi_id: "",
        logi_no: "",
        orders_items_id: [],
        consignee_name: "",
        consignee_mobile: "",
        // consignee_province:'',
        // consignee_city:'',
        // consignee_county:'',
        consignee_address: "",
        remark: "",
        address: [],
      },
      rules: {
        consignee_name: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        consignee_mobile: [
          { required: true, message: "请输入收货人手机号", trigger: "blur" },
        ],
        consignee_address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
        logi_no: [
          { required: true, message: "请输入物流/快递单号", trigger: "blur" },
        ],
        address: [{ required: true, message: "请选择地区", trigger: "change" }],
        logi_id: [
          { required: true, message: "请选择物流/快递", trigger: "change" },
        ],
      },
      regionData: [],
      logisticsData: [],
      order_data: [],
      goods_columns: [
        // {
        //   title: "商品",
        //   width: 50,
        //   dataIndex: "img",
        //   scopedSlots: { customRender: "img" },
        // },
        { title: "订单号", width: 100, dataIndex: "order_sn" },
        { title: "商品名称", width: 130, dataIndex: "goods_name" },
        {
          title: "商品单价",
          width: 100,
          dataIndex: "price",
          scopedSlots: { customRender: "price" },
        },
        { title: "规格描述", width: 130, dataIndex: "goods_spec_desc" },
        {
          title: "开单状态",
          width: 50,
          dataIndex: "is_ready",
          scopedSlots: { customRender: "is_ready" },
        },
        {
          title: "发货状态",
          width: 50,
          dataIndex: "is_delivery",
          scopedSlots: { customRender: "is_delivery" },
        },
        { title: "购买数量", width: 90, dataIndex: "num" },
        { title: "已发数量", width: 90, dataIndex: "delivery_num" },
        {
          title: "本次发货数量",
          width: 110,
          dataIndex: "delivery_num",
          scopedSlots: { customRender: "current_delivery_num" }
        },
      ],
      selectGoods: [],
      deliveryInfo:{},
      salesmen:{},
      
    }
  },
  mounted() {
    this.logisticsData = this.$db.get("logisticsList").data
    this.regionData = this.$db.get("regionList").data
    this.salesmen = this.$db.get('salesmen')
    if(!this.salesmen){
      users_salesman().then(res => {
        this.salesmen = res
        console.log('users_salesman',this.salesmen)

      })
    }
  },
  computed: {
    regionTree() {
      if (this.regionData.length > 0) {
        return this.$common.treeData(this.regionData, "pcode", "code", 0)
      }
    },
    selectRow() {
        return{
          onChange: (selectedRowKeys, selectedRows) => {
            this.selectGoods = selectedRows
          },
          getCheckboxProps: row => ({
            props:{
              disabled:row.is_delivery || !row.is_ready  //已经发货,或者未开单 不能被选择
            }
          })
        }
      },
  },
  methods: {
    doExport(){
      //导出
      order_index(this.ajaxparams).then(res => {
        this.$message.success("添加导出队列成功")
        this.$db.set('is_export',1)
      },err => {
        this.$message.warning("导出失败")
      })
    },
    doUnDeliveryGoodsExport(){
      //导出
      undelivery_goods().then(res => {
        this.$message.success("添加导出队列成功")
        this.$db.set('is_export',1)
      },err => {
        this.$message.warning("导出失败")
      })
    },
    // 修改发货数量 
    changeDeliveryNum(val,row){
        this.deliveryInfo[row.id] = val
    },
    getCheckboxProps(row) {
           return ({
                props: {
                //当状态是0的时候执行disable
                disabled:  row.is_delivery == 1
           }
         })
    },
    filterProvince(inputValue, path) {
      return path.some((option) => option.name.indexOf(inputValue) > -1)
    },
    onSelectChange(val) {
      console.log(val)
    },
    handleCancel() {
      this.submitLoading = false
      this.dialogVisible = false
    },
    returnRegionName(code) {
      let rest = this.regionData.find((item) => {
        return code == item.code
      })
      if (rest) {
        return rest.name
      }
      return ""
    },
    async onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.dialogType == "logi") {
            //发货
            if (this.selectGoods.length == 0) {
              this.$message.warning("请选择发货商品")
              this.submitLoading = false
              return
            }
            let params = {
              order_sn: this.form.order_sn,
              logi_id: this.form.logi_id,
              logi_no: this.form.logi_no,
              orders_items: [],
            }
            for (let i = 0; i < this.selectGoods.length; i++) {
              const orderItemId = this.selectGoods[i].id
              let items = {
                id:orderItemId,
                delivery_num:this.deliveryInfo[orderItemId] || this.selectGoods[i].num,
              }
              //本次发货数量 + 已发货数量 小于
              items['is_delivery'] = items['delivery_num'] + this.selectGoods[i].delivery_num < this.selectGoods[i].num ? 0 : 1
              if(items.delivery_num < 1 || items.delivery_num > this.selectGoods[i].num){
                this.$message.warning("发货数量有误")
                this.submitLoading = false
                return
              }
              params.orders_items.push(items)
            }

            orders_delivery(params)
              .then((result) => {
                this.$message.success("操作成功")
                setTimeout(() => {
                  this.$refs.myTable.getList()
                  this.submitLoading = false
                  this.dialogVisible = false
                }, 1500)
              })
              .catch(() => {
                this.submitLoading = false
              })
          } else if (this.dialogType == "consignee") {
            //修改收货地址
            let params = {
              order_sn: this.form.order_sn,
              consignee_name: this.form.consignee_name,
              consignee_mobile: this.form.consignee_mobile,
              consignee_province: this.returnRegionName(this.form.address[0]),
              consignee_city: this.returnRegionName(this.form.address[1]),
              consignee_county: this.returnRegionName(this.form.address[2]),
              consignee_address: this.form.consignee_address,
            }
            orders_consignee(params)
              .then((result) => {
                this.$message.success("操作成功")
                setTimeout(() => {
                  this.$refs.myTable.getList()
                  this.submitLoading = false
                  this.dialogVisible = false
                }, 1500)
              })
              .catch(() => {
                this.submitLoading = false
              })
          } else {
            //修改备注
            let params = {
              order_sn: this.form.order_sn,
              remark: this.form.remark,
            }
            orders_remark(params)
              .then((result) => {
                this.$message.success("操作成功")
                setTimeout(() => {
                  this.$refs.myTable.getList()
                  this.submitLoading = false
                  this.dialogVisible = false
                }, 1500)
              })
              .catch(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    get_orders_read(order_sn) {
      orders_read({ order_sn: order_sn }).then((result) => {
        this.order_data = result
      })
    },
    dialogClick(type, row) {
      this.dialogType = type
      if (type == "logi") {
        this.order_data = {}
        this.selectGoods = []
        this.get_orders_read(row.order_sn)
      }
      this.form = {
        order_sn: row.order_sn,
        logi_id: "",
        logi_no: "",
        orders_items_id: [],
        consignee_name: "",
        consignee_mobile: "",
        // consignee_province:'',
        // consignee_city:'',
        // consignee_county:'',
        consignee_address: "",
        remark: row.merchant_remark,
        address: [],
      }

      this.dialogVisible = true
    },
  },
}
</script>
