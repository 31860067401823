<!-- 搞单列表 -->
<template>
  <div>
    <div style="background-color: #fff; padding: 16px; margin-bottom: 20px">
      <div>
        <!-- <a-button type="primary" @click="doReady()">开单</a-button> -->
        <!-- 搜索条件 -->
        <a-form-model
          size="small"
          layout="inline"
          :model="ajaxparams"
          @keyup.native.enter="$refs.myTable.search()"
        >
          <a-form-model-item label="订单号">
            <a-input
              placeholder="请输入订单号"
              v-model="ajaxparams['1_order_sn_like']"
            />
          </a-form-model-item>
          <a-form-model-item label="用户昵称">
            <a-input
              placeholder="请输入用户昵称"
              v-model="ajaxparams['3_name_like']"
            />
          </a-form-model-item>
          <a-form-model-item label="商品名称">
            <a-input
              placeholder="请输入商品名称"
              v-model="ajaxparams['2_goods_name_like']"
            />
          </a-form-model-item>
          <a-form-model-item label="撤单类型">
            <a-select
              style="width: 200px"
              allowClear
              v-model="ajaxparams['type_no']"
              placeholder="请选择撤单类型"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in typeData"
                :key="index"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              icon="search"
              @click="$refs.myTable.search()"
              type="primary"
              >搜索</a-button
            >
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click="doExport" type="primary"> 导出 </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <MyTable
        class="mt10"
        :apiUrl="orders_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
        @selectRow="onSelectChange"
        :isSelection="false"
      >
        <a-space slot="button" slot-scope="item, row, index">
          <a-popconfirm
            title="是否处理本条记录?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="doReady(row.id, index)"
            v-if="row.cancel_apply_status != 2"
          >
            <a-button size="small" type="primary" :disabled="row.status == 1"
              >处理</a-button
            >
          </a-popconfirm>
          <a-button
            v-if="row.cancel_apply_status == 2"
            size="small"
            type="primary"
            @click="check(row)"
            >审核</a-button
          >
        </a-space>
        <a-space slot="img" slot-scope="item, row, index">
          <img
            v-if="row.img"
            style="width: 30px; heigth: 30px"
            :src="IMGHOST + '/' + row.img"
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="is_ready" slot-scope="item, row, index">
          <span>{{ item ? "已开单" : "未开单" }}</span>
        </a-space>
        <a-space slot="order_sn" slot-scope="item, row, index">
          <router-link
            target="_blank"
            :to="{ path: '/orders/detail', query: { id: row.order_sn } }"
          >
            <span>{{ row.order_sn }}</span>
          </router-link>
        </a-space>
        <a-space slot="thumb" slot-scope="item, row, index">
          <div class="imgUploadContent">
            <imgUpload
              v-if="row.cancel_img"
              :disabled="true"
              ref="logo"
              :list="[row.cancel_img]"
              :maxLength="1"
              :type="'img'"
            ></imgUpload>
          </div>
        </a-space>
      </MyTable>

      <!-- 审核 -->
      <a-modal
        :maskClosable="false"
        v-model="dialogVisible"
        title="审核"
        :confirm-loading="submitLoading"
        @ok="onSubmit"
        @cancel="handleCancel"
      >
        <a-form-model :rules="rules" ref="formModel" size="small" :model="form">
          <a-form-model-item label="备注" prop="cancel_remark">
            <a-textarea
              placeholder="请输入备注"
              v-model="form.cancel_remark"
              :auto-size="{ minRows: 3, maxRows: 8 }"
            >
            </a-textarea>
          </a-form-model-item>
          <a-form-model-item label="签字图" prop="cancel_img">
            <div class="fs12 color666">建议上传尺寸：200*200</div>
            <imgUpload
              :disabled="false"
              v-if="dialogVisible"
              :list="form.cancel_img ? [form.cancel_img] : []"
              :maxLength="1"
              :type="'img'"
              @result="uploadResult"
            ></imgUpload>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue";
import imgUpload from "@/components/myUpload.vue";

import {
  orders_unready_index,
  orders_doReady,
  cancel_confirm,
} from "@/api/orders";
import { order_unready } from "@/api/export";
export default {
  components: { MyTable, imgUpload },
  data() {
    return {
      orders_index: orders_unready_index,
      IMGHOST: window.IMGHOST,

      ajaxparams: {
        type_no: "cancel_list",
        title_no: "撤单列表",
      }, //请求参数
      /*
			列表header
		*/
      columns: [
        {
          title: "操作",
          key: "operation",
          width: 200,
          ellipsis: true,
          fixed: "left",
          scopedSlots: { customRender: "button" },
        },
        {
          title: "订单号",
          key: "order_sn",
          dataIndex: "order_sn",
          width: 130,
          scopedSlots: { customRender: "order_sn" },
        },
        {
          title: "是否开单",
          key: "is_ready",
          dataIndex: "is_ready",
          width: 100,
          scopedSlots: { customRender: "is_ready" },
        },
        {
          title: "商品名称",
          ellipsis: true,
          width: 220,
          dataIndex: "goods_name",
          key: "goods_name",
        },

        {
          title: "用户名称",
          width: 100,
          dataIndex: "name",
          key: "name",
        },
        {
          title: "单价",
          ellipsis: true,
          width: 100,
          dataIndex: "price",
          key: "price",
        },
        {
          title: "购买数量",
          ellipsis: true,
          width: 120,
          dataIndex: "num",
          key: "num",
        },
        {
          title: "规格",
          ellipsis: true,
          width: 300,
          dataIndex: "goods_spec_desc",
          key: "goods_spec_desc",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "cancel_remark",
          key: "cancel_remark",
          width: 220,
        },
        {
          title: "签字图",
          ellipsis: true,
          dataIndex: "cancel_img",
          key: "cancel_img",
          width: 220,
          scopedSlots: { customRender: "thumb" },
        },
        // {
        //   title: "规格图片",
        //   dataIndex: "img",
        //   key: "img",
        //   width: 100,
        //   scopedSlots: { customRender: "img" },
        // },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "ctime",
          key: "ctime",
          width: 220,
        },
      ],
      selectGoods: [],
      dialogVisible: false,
      submitLoading: false,
      form: {},
      rules: {
        cancel_remark: [
          { required: true, message: "请输入备注", trigger: "blur" },
        ],
        cancel_img: [
          { required: true, message: "请上传签字图", trigger: "blur" },
        ],
      },
      typeData: [
        {
          id: "cancel_list",
          name: "普通撤单",
        },
        {
          id: "cancel_apply_list",
          name: "聚划算撤单",
        },
      ],
    };
  },
  methods: {
    check(row) {
      this.dialogVisible = true;
      this.form.order_sn = row.order_sn;
    },
    // 上传图片回调
    uploadResult(row) {
      console.log(row);
      if (row.type == "img") {
        this.form.cancel_img = row.url;
      }
    },
    async onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;
          cancel_confirm(this.form)
            .then((result) => {
              this.$message.success("操作成功");
              setTimeout(() => {
                this.$refs.myTable.getList();
                this.submitLoading = false;
                this.dialogVisible = false;
              }, 1500);
            })
            .catch(() => {
              this.submitLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.submitLoading = false;
      this.dialogVisible = false;
    },
    doExport() {
      //导出
      order_unready(this.ajaxparams).then(
        (res) => {
          this.$message.success("添加导出队列成功");
          this.$db.set("is_export", 1);
        },
        (err) => {
          this.$message.warning("导出失败");
        }
      );
    },
    onSelectChange(val) {
      if (val && val.length > 0) {
        this.selectGoods = val.map((item) => item.id);
      }
    },
    async doReady(id) {
      let ids;
      if (id) {
        ids = [id];
      } else {
        if (this.selectGoods.length > 0) {
          ids = this.selectGoods;
        } else {
          this.$message.warning("请选择至少一个规格");
          return;
        }
      }
      orders_doReady({ ids: ids, status: 1, type: "status" })
        .then((result) => {
          this.$message.success("操作成功");
          setTimeout(() => {
            this.$refs.myTable.getList();
          }, 1500);
        })
        .catch(() => {});
    },
  },
};
</script>
