<!-- 账号管理 -->
<style scoped>
.width150 {
  width: 150px;
}
</style>
<template>
  <div>
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
      <!-- 搜索条件 -->
      <a-form-model
        size="small"
        layout="inline"
        :model="ajaxparams"
        @keyup.native.enter="$refs.myTable.search()"
      >
        <a-form-model-item label="手机号">
          <a-input placeholder="请输入手机号" v-model="ajaxparams['mobile']" />
        </a-form-model-item>
        <!-- <a-form-model-item label="日期">
        <a-date-picker v-model="ajaxparams['create_at']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" value=null />
      </a-form-model-item> -->
   
        <a-form-model-item>
          <a-button icon="search" @click="$refs.myTable.search()" type="primary">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
      <MyTable
        class="mt10"
        :apiUrl="luckydraw_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
      >
       <a-space slot="button" slot-scope="item,row,index">
          <a-button size="small" @click="dialogClick(row)">更改抽奖次数</a-button>
          </a-space>
      </MyTable>
    </div>
    <a-modal
      :maskClosable="false"
      v-model="dialogVisible"
      :title="'更改抽奖次数'"
      :confirm-loading="submitLoading"
      @ok="onSubmit"
      @cancel="handleCancel"
    >
      <a-form-model :rules="rules" ref="formModel" size="small" :model="form">
        <a-form-model-item label="抽奖次数" prop="draw_times">
          <a-input type="number" placeholder="请输入抽奖次数" v-model="form.draw_times" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
  
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {
  luckydraw_index,
  luckydraw_change_draw_times
} from "@/api/luckydraw"

export default {
  components: { MyTable },
  data() {
    return {
      luckydraw_index: luckydraw_index,
      ajaxparams: {}, //请求参数
      /*
			列表header
		*/
      columns: [
        {
          title: "操作",
          key: "operation",
          fixed: "left",
          width: 210,
          ellipsis: true,
          scopedSlots: { customRender: "button" },
        },
        {
          title: "手机号",
          ellipsis: true,
          width: 150,
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "业务经理",
          ellipsis: true,
          width: 150,
          dataIndex: "manager_name",
          key: "manager_name",
        },
        {
          title: "可抽奖次数",
          ellipsis: true,
          width: 100,
          dataIndex: "draw_times",
          key: "draw_times",
        },
        {
          title: "已抽奖次数",
          ellipsis: true,
          width: 100,
          dataIndex: "use_times",
          key: "use_times",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "create_at",
          key: "create_at",
          width: 220,
        },
      ],
      form:{
        id:0,
        draw_times:0
      },
      dialogVisible:false,
      submitLoading:false,
    }
  },

  created() {},
  methods: {
     dialogClick( row) {
      console.log(row)
        this.form.id = row.id
        this.form.draw_times = row.draw_times
        this.dialogVisible = true
        console.log(this.form)
    },
     handleCancel() {
      this.submitLoading = false
      this.dialogVisible = false
    },
    async onSubmit() {
        let _this = this
        _this.submitLoading = true
        luckydraw_change_draw_times(_this.form)
          .then((result) => {
            _this.$message.success("操作成功")
            setTimeout(() => {
              _this.$refs.myTable.getList()
              _this.submitLoading = false
              _this.dialogVisible = false
            }, 1500)
          })
          .catch(() => {
            _this.submitLoading = false
            _this.dialogVisible = false
          })
    },
  },
}
</script>
