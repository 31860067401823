<!-- 广告管理 -->

<style scoped>
	.img{
		/* width:30px; */
		height:30px
	}
	.ellipsis-1{
		max-width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; 
		word-wrap: break-word;
		 word-break: break-all 
	}
	.w150{
		width: 150px;
	}
  .w100{
		width: 100px;
	}
</style>
<template>
  <div class="pageCentent">
  <a-form-model size="small" layout="inline" @keyup.native.enter="search()">
        <a-form-model-item label="奖品名称">
          <a-input  placeholder="请输入奖品名称" v-model="ajaxparams['2_prize_name_like']" allowClear />
        </a-form-model-item>
         <a-form-model-item label="用户名称">
          <a-input  placeholder="请输入用户名称" v-model="ajaxparams['3_name']" allowClear />
        </a-form-model-item>
        <a-form-model-item label="业务经理">
          <a-input  placeholder="请输入业务经理" v-model="ajaxparams['manager_name_no']" allowClear />
        </a-form-model-item>
      
        <a-form-model-item label="是否领取" prop="is_take">
          <a-select class="w100" v-model="ajaxparams['1_is_take']" placeholder="请选择" allowClear>
            <a-select-option :value="0" >未领取</a-select-option>
            <a-select-option :value="1" >已领取</a-select-option>
          </a-select>
        </a-form-model-item>
        <br/>
        <a-form-model-item label="是否发货" prop="is_delivery">
          <a-select class="w100" v-model="ajaxparams['1_is_delivery']" placeholder="请选择" allowClear>
            <a-select-option :value="0" >未发货</a-select-option>
            <a-select-option :value="1" >已发货</a-select-option>
          </a-select>
        </a-form-model-item>
      <a-form-model-item label="获奖时间">
        <a-range-picker v-model="ajaxparams['1_create_at_between']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
      </a-form-model-item>
      <a-form-model-item >
        <a-button icon="search" @click="$refs.myTable.search()" type="primary"> 搜索</a-button>
      </a-form-model-item>
      <a-form-model-item >
				<a-button :loading="buttonLoading"  @click="doExport" type="primary"> 导出 </a-button>
			</a-form-model-item>
    </a-form-model>
    <MyTable class="mt10" :isSelection="false" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
      <a-space slot='is_take' slot-scope="item,row,index">
        <span>{{row.is_take==1?'是':'否'}}</span>
      </a-space>
      <a-space slot='manager_id' slot-scope="item,row,index">
        <span>{{findManagerName(item)}}</span>
      </a-space>
       <a-space slot="button" slot-scope="item,row,index">
          <a-popconfirm
            v-if="row.is_delivery == 0"
            title="确定标记为发货?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="delivery(row)"
          >
            <a-button size="small" type="primary">发货</a-button>
          </a-popconfirm>
          <a-button size="small" @click="showChangeRemarkDialog(row)">
          修改备注
        </a-button>
        </a-space>
    </MyTable>
    <a-modal :title="'修改备注'" :visible="showRemarkDialog" @ok="changeRemark" @cancel="showRemarkDialog = false">
		<a-form-model-item label="备注">
			<a-textarea placeholder="请输入备注信息" v-model="remark"  :auto-size="{ minRows: 3, maxRows: 8 }"> </a-textarea>
        </a-form-model-item>
	  </a-modal>
  </div>
	
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {userPrize_index,userPrize_changeField} from '@/api/manager'
import { user_prize }from "@/api/export"

export default {
	components:{MyTable},
  data() {
    return {
		apiUrl:userPrize_index,
		ajaxparams:{},
    role:'',
    managerInfo:[],
		columns:[
			{ title: '用户名', ellipsis: true,dataIndex: 'name',key: 'name'},
      { title: '业务经理', ellipsis: true,dataIndex: 'manager_id',key: 'manager_id',scopedSlots: { customRender: "manager_id" }},
			{ title: '奖品', ellipsis: true,dataIndex: 'prize_name',key: 'prize_name',scopedSlots: { customRender: "prize_name" }},
			{ title: '是否领取', ellipsis: true,dataIndex: 'is_take',key: 'is_take',scopedSlots: { customRender: "is_take" }},
      { title: '领奖人姓名', ellipsis: true,dataIndex: 'real_name', key: 'real_name'},
			{ title: '领奖人手机号', ellipsis: true,dataIndex: 'mobile', key: 'mobile'},
			{ title: '身份证号', ellipsis: true,dataIndex: 'id_number', key: 'id_number'},
			{ title: '地址', ellipsis: true,dataIndex: 'address', key: 'address'},
      { title: '备注', ellipsis: true,dataIndex: 'remark', key: 'remark'},
      { title: '时间', ellipsis: true,dataIndex: 'create_at', key: 'create_at'},
       {
          title: "操作",
          key: "operation",
          fixed: "right",
          width: 200,
          scopedSlots: { customRender: "button" },
        },
		],
		queryData:{

    },
    buttonLoading:false,
    confirmLoading:false,
    showRemarkDialog:false,
    currentId:0,
	}
  },
  created() {
    this.queryData = this.$route.query
    if(this.queryData.role){
      this.ajaxparams.role = this.queryData.role
    }
    
  },
  mounted(data) {
    this.managerInfo = this.$db.get('manager_index').data
  },
  destroyed() {  
  },
  methods: {
     //执行导出
	doExport(){
    this.buttonLoading = true
		user_prize(this.ajaxparams).then(res => {
      this.buttonLoading = false
			this.$message.success("添加导出队列成功")
			this.$db.set('is_export',1)
		},err => {
      this.buttonLoading = false
			this.$message.warning("导出失败")
		})
		
	},
    search(){
      this.ajaxparams = {role:this.ajaxparams.role}
      this.$refs.myTable.search()
    },
    findManagerName(id){
      console.log("执行")
			let rest = this.managerInfo.find((item)=>{return id==item.id})
			if(rest){
				return rest.manager_name
			}
			return '-'
		},
    //发货
    delivery(row){
      console.log(row)
      const _this = this
      if(_this.confirmLoading) return false
      _this.confirmLoading = true
      userPrize_changeField({id:row.id,type:"delivery"}).then((res) => {
        _this.$message.success("操作成功")
        setTimeout(() => {
          _this.confirmLoading = false
          _this.$refs.myTable.getList()
        }, 1500)
      })
    },
    changeRemark() {
      const _this = this
      if(_this.remark == ''){
        _this.$message.warning("请输入备注")
        return 
      }
      userPrize_changeField({id:this.currentId,remark:this.remark,type:"remark"}).then((res) => {
        _this.$message.success("操作成功")
        setTimeout(() => {
          _this.showRemarkDialog = false
          _this.$refs.myTable.getList()
        }, 1500)
      })
      
    },
     //修改备注
    showChangeRemarkDialog(row){
      this.showRemarkDialog = true
      this.currentId = row.id
    },
  },
}
</script>
	