import request from '@/utils/request'

export function config_menus(data) {
  return request({
    url: '/merchant/config/menus',
    method: 'get',
    params: data
  })
}
// 获取系统配置
export function config_index(data) {
  return request({
    url: '/merchant/config/index',
    method: 'get',
    params: data
  })
}
// 更新系统会员说明
export function setting_updateSysDescMember(data) {
  return request({
    url: '/merchant/setting/updateSysDescMember',
    method: 'post',
    data: data
  })
}

// 操作日志列表
export function operateLog_index(data) {
  return request({
    url: '/merchant/operateLog/index',
    method: 'get',
    params: data
  })
}