<!-- 账号管理 -->
<style scoped>
</style>
<template>
  <div>
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
      <a-button type="primary" @click="dialogClick('add')">添加</a-button>
      <a-table
        class="mt10"
        :row-selection="{onChange:onSelectChange}"
        :scroll="{ x: '100%', y: 0 }"
        :data-source="tableData"
        :columns="columns"
      >
        <a-space slot="button" slot-scope="item,row,index">
          <a-button size="small" type="primary" @click="dialogClick('edit',row)">编辑</a-button>
          <a-popconfirm title="确定是否删除?" ok-text="确定" cancel-text="取消" @confirm="delConfirm(row)">
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </a-space>
        <a-space slot="avatar" slot-scope="item,row,index">
          <img v-if="row.avatar" style="width:30px;heigth:30px" :src="IMGHOST+'/'+row.avatar" />
          <span v-else>-</span>
        </a-space>
        <a-space slot="status" slot-scope="item,row,index">
          <a-switch
            @click="switchClick(row,index)"
            :disabled="isDisabledSwitch"
            checked-children="启用"
            un-checked-children="禁用"
            :defaultChecked="row.status==1?true:false"
          />
        </a-space>
        <a-space slot="role_id" slot-scope="item,row,index">
          <span v-if="row.role_id==1">超级管理员</span>
          <span v-else>{{roleFand(row)}}</span>
        </a-space>
      </a-table>
    </div>
    <a-modal
      :maskClosable="false"
      v-model="dialogVisible"
      :title="dialogType=='add'?'添加账号':'编辑账号'"
      :confirm-loading="submitLoading"
      @ok="onSubmit"
      @cancel="handleCancel"
    >
      <a-form-model :rules="rules" ref="formModel" size="small" :model="form">
        <a-form-model-item label="用户名" v-if="dialogType=='add'" prop="username">
          <a-input placeholder="请输入用户名" v-model="form.username" />
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password">
          <a-input type="password" placeholder="请输入密码" v-model="form.password" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="mobile">
          <a-input placeholder="请输入手机号" v-model="form.mobile" />
        </a-form-model-item>
        <a-form-model-item label="角色" prop="role_id">
          <a-select v-model="form.role_id" placeholder="请选择角色">
            <a-select-option
              :value="item.id"
              v-for="(item,index) in roleData"
              :key="index"
            >{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="编辑会员操作码" prop="edit_auth_code">
          <a-input type="password" placeholder="请输入编辑会员操作码" v-model="form.edit_auth_code" />
        </a-form-model-item>
         <a-form-model-item label="会员等级操作码" prop="level_auth_code">
          <a-input type="password" placeholder="请输入会员等级操作码" v-model="form.level_auth_code" />
        </a-form-model-item>
         <a-form-model-item label="修改余额操作码" prop="balance_auth_code">
          <a-input type="password" placeholder="请输入修改操作码" v-model="form.balance_auth_code" />
        </a-form-model-item>
        <a-form-model-item label="股东操作码" prop="bonus_auth_code">
          <a-input type="password" placeholder="请输入股东操作码" v-model="form.bonus_auth_code" />
        </a-form-model-item>
        <a-form-model-item label="发券操作码" prop="coupon_auth_code">
          <a-input type="password" placeholder="请输入发券操作码" v-model="form.coupon_auth_code" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
// import MyTable from "@/components/myTable.vue"
import {
  admin_index,
  admin_add,
  admin_edit,
  admin_del,
  admin_able,
} from "@/api/admin"
import { role_index } from "@/api/role"
import { removeToken } from "@/utils/auth"

export default {
  // components:{MyTable},
  data() {
    return {
      admin_index: admin_index,
      IMGHOST: window.IMGHOST,
      apiUrl: "merchant_admin_index", //请求地址
      ajaxparams: {}, //请求参数
      rowSelection: [],
      /*
			列表header
		*/
      columns: [
        {
          title: "操作",
          key: "operation",
          fixed: "left",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "button" },
        },
        {
          title: "用户名",
          ellipsis: true,
          width: 150,
          dataIndex: "username",
          key: "username",
        },
        {
          title: "手机号码",
          ellipsis: true,
          width: 150,
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "头像",
          ellipsis: true,
          dataIndex: "avatar",
          key: "avatar",
          width: 100,
          scopedSlots: { customRender: "avatar" },
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          key: "status",
          width: 150,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "角色",
          ellipsis: true,
          dataIndex: "role_id",
          key: "role_id",
          width: 150,
          scopedSlots: { customRender: "role_id" },
        },
        // { title: '最后登录IP', ellipsis: true,dataIndex: 'last_login_ip', key: 'last_login_ip',width: 150},
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "ctime",
          key: "ctime",
          width: 220,
        },
        {
          title: "更新时间",
          ellipsis: true,
          dataIndex: "utime",
          key: "utime",
          width: 220,
        },
      ],
      isDisabledSwitch: false, //是否禁用Switch
      submitLoading: false,
      dialogType: "add",
      dialogVisible: false,
      form: {
        // id:0,
        username: "",
        password: "",
        role_id: "",
        mobile: "",
      },
      roleData: [],
      loading: false,
      tableData: [],
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        role_id: [{ required: true, message: "请选择角色", trigger: "change" }],
      },
    }
  },
  created() {
    this.getList()
    this.merchant_role_index()
  },
  methods: {
    async getList() {
      this.loading = true
      admin_index()
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            result[i].key = i
          }
          this.tableData = result
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    roleFand(row) {
      let res = this.roleData.find((item) => {
        return item.id == row.role_id
      })
      if (res) {
        return res.name
      }
      return "-"
    },

    async merchant_role_index() {
      //获取所有角色
      role_index({ page: 1, limit: 10000 }).then((result) => {
        this.roleData = result
      })
    },
    async switchClick(val, index) {
      //禁用/启用
      this.isDisabledSwitch = true
      admin_able({ status: val.status ? 0 : 1, id: val.id })
        .then((result) => {
          this.$message.success("操作成功")
          this.$refs.myTable.tableData[index].status = val ? 1 : 0
          setTimeout(() => {
            this.isDisabledSwitch = false
          }, 1500)
        })
        .catch(() => {
          this.isDisabledSwitch = false
        })
    },
    onSelectChange(val) {
      console.log(val)
    },
    handleCancel() {
      this.submitLoading = false
      this.dialogVisible = false
    },
    async onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.dialogType == "add") {
            admin_add(this.form)
              .then((result) => {
                this.$message.success("操作成功")
                setTimeout(() => {
                  this.getList()
                  this.submitLoading = false
                  this.dialogVisible = false
                }, 1500)
              })
              .catch(() => {
                this.submitLoading = false
              })
          } else {
            let auth_id = JSON.parse(this.$Cookies.get("userInfo")).id
            admin_edit(this.form)
              .then((result) => {
                this.$message.success("操作成功")
                setTimeout(() => {
                  this.getList()
                  this.submitLoading = false
                  this.dialogVisible = false
                  if (auth_id == this.form.id) {
                    removeToken()
                    this.$Cookies.remove("userInfo")
                    location.reload()
                  }
                }, 1500)
              })
              .catch(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    async delConfirm(val) {
      //删除
      admin_del({ id: val.id }).then((result) => {
        this.$message.success("操作成功")
        setTimeout(() => {
          this.getList()
        }, 1500)
      })
      // let res = await this.$api.merchant_admin_del({id:val.id})
      // if(res.code==200){
      // 	this.$message.success('操作成功');
      // 	setTimeout(()=>{
      // 		this.$refs.myTable.getList()
      // 	},1500)
      // }
    },
    dialogClick(type, row) {
      this.dialogType = type
      if (type == "edit") {
        this.$nextTick(() => {
          this.form = {
            id: row.id,
            password: "",
            role_id: row.role_id,
            mobile: row.mobile,
            edit_auth_code: row.edit_auth_code,
            level_auth_code: row.level_auth_code,
            balance_auth_code: row.balance_auth_code,
            bonus_auth_code: row.bonus_auth_code,
            coupon_auth_code: row.coupon_auth_code
          }
        })
      } else {
        if (this.roleData.length == 0) {
          this.$message.warning("请前往创建角色")
          return
        }
        this.$nextTick(() => {
          this.form = {
            password: "",
            role_id: "",
            mobile: "",
            username: "",
            edit_auth_code: "",
            level_auth_code:"",
            balance_auth_code:"",
            bonus_auth_code:"",
            coupon_auth_code:""
          }
        })
      }
      this.dialogVisible = true
    },
  },
}
</script>
