<!-- 搞单列表 -->
<template>
  <div>
    <div style="background-color: #fff; padding: 16px; margin-bottom: 20px">
      <MyTable
        class="mt10"
        :apiUrl="orders_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
        :isSelection="false"
      >
        <a-space slot="button" slot-scope="item, row, index">
          <a-popconfirm
            title="是否处理本条记录?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="doReady(row.id)"
            v-if="row.cancel_apply_status != 2"
          >
            <a-button
              size="small"
              type="primary"
              :disabled="row.delivery_status == 1"
              >处理</a-button
            >
          </a-popconfirm>
        </a-space>
        <a-space slot="consignee" slot-scope="item, row, index">
          <p>{{ row.consignee_name }}</p>
          <p>{{ row.consignee_mobile }}</p>
          <p>{{ row.consignee_area }}</p>
          <p>{{ row.consignee_address }}</p>
        </a-space>
        <a-space slot="order_sn" slot-scope="item, row, index">
          <router-link
            target="_blank"
            :to="{ path: '/orders/detail', query: { id: row.order_sn } }"
          >
            <span>{{ row.order_sn }}</span>
          </router-link>
        </a-space>
        <a-space slot="thumb" slot-scope="item, row, index">
          <div class="imgUploadContent">
            <imgUpload
              v-if="row.cancel_img"
              :disabled="true"
              ref="logo"
              :list="[row.cancel_img]"
              :maxLength="1"
              :type="'img'"
            ></imgUpload>
          </div>
        </a-space>
      </MyTable>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue";
import imgUpload from "@/components/myUpload.vue";

import { point_Orders_index, point_Orders_delivery } from "@/api/goods";
export default {
  components: { MyTable, imgUpload },
  data() {
    return {
      orders_index: point_Orders_index,
      IMGHOST: window.IMGHOST,

      ajaxparams: {}, //请求参数
      /*
			列表header
		*/
      columns: [
        {
          title: "操作",
          key: "operation",
          width: 100,
          ellipsis: true,
          fixed: "left",
          scopedSlots: { customRender: "button" },
        },
        {
          title: "订单号",
          key: "order_sn",
          dataIndex: "order_sn",
          width: 200,
          scopedSlots: { customRender: "order_sn" },
        },
        {
          title: "商品名称",
          ellipsis: true,
          width: 200,
          dataIndex: "point_goods_name",
          key: "point_goods_name",
        },
        {
          title: "商品数量",
          ellipsis: true,
          width: 130,
          dataIndex: "num",
          key: "num",
        },
        {
          title: "商品积分",
          ellipsis: true,
          width: 130,
          dataIndex: "goods_points",
          key: "goods_points",
        },
        {
          title: "用户名称",
          width: 130,
          dataIndex: "name",
          key: "name",
        },
        {
          title: "收件人信息",
          ellipsis: true,
          width: 400,
          scopedSlots: { customRender: "consignee" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "ctime",
          key: "ctime",
          width: 220,
        },
      ],
    };
  },
  methods: {
    async doReady(id) {
      point_Orders_delivery({ id })
        .then((result) => {
          this.$message.success("操作成功");
          setTimeout(() => {
            this.$refs.myTable.getList();
          }, 1500);
        })
        .catch(() => {});
    },
  },
};
</script>
