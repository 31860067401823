<!-- 账号管理 -->
<style scoped>
</style>
<template>
  <div >
	<div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
		<a-button type="primary"  @click="dialogClick('add')">
		  添加
		</a-button>
		<MyTable class="mt10" :apiUrl="classify_index" :params="ajaxparams" ref="myTable" :columns="columns">
			<a-space slot='button' slot-scope="item,row,index">
				<a-button v-if="row.id!=1&&row.id!=2" size="small" type="primary"  @click="dialogClick('edit',row)">
				  编辑
				</a-button>
				
				<a-popconfirm
					v-if="row.id!=1&&row.id!=2"
				    title="确定是否删除?"
				    ok-text="确定"
				    cancel-text="取消"
				    @confirm="delConfirm(row)" >
					<a-button size="small" type="danger" >
					  删除
					</a-button>
				</a-popconfirm>
			</a-space>
			<a-space slot='sort' slot-scope="item,row,index">
				<editable-cell v-if="row.id!=1&&row.id!=2" :ref="'edittableCell'+row.id" :text="row.sort" @change="onCellChange($event,row,index)" />
			</a-space>
			<a-space slot='thumb' slot-scope="item,row,index">
				<img v-if="row.thumb" style="width:30px;heigth:30px" :src="IMGHOST+'/'+row.thumb" />
				<span v-else>-</span>
			</a-space>
			<a-space slot='status' slot-scope="item,row,index">
				<a-switch  @click="switchClick(row,index)" :disabled="isDisabledSwitch||!(row.id!=1&&row.id!=2)" checked-children="启用" un-checked-children="禁用" :defaultChecked="row.status==1?true:false" />
			</a-space>
		</MyTable>
	</div>
	<a-modal :maskClosable="false" v-model="dialogVisible" :title="dialogType=='add'?'添加分类':'编辑分类'" :confirm-loading="submitLoading" @ok="onSubmit"  @cancel="handleCancel" >
		<a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="formModel" size="small" :model="form">
          <a-form-model-item label="分类名" prop="name">
            <a-input  placeholder="请输入分类名" v-model="form.name" />
          </a-form-model-item>
		  <a-form-model-item label="所属分类" v-if="dialogType=='add'" prop="pid" >
             <a-cascader v-model="form.pid" change-on-select placeholder="请选择所属分类" :field-names="{ label: 'name', value: 'id', children: 'children' }" allowClear :options="classifyList" />
          </a-form-model-item>
		  <a-form-model-item label="排序" prop="sort" >
            <a-input type="sort"  placeholder="请输入排序" v-model="form.sort" />
          </a-form-model-item>
		  <a-form-model-item label="分类图" prop="thumb">
			  <div class="fs12 color666">建议上传尺寸：130*130</div>
            <imgUpload :disabled="false" ref="logo" v-if="dialogVisible"  :list="form.thumb?[form.thumb]:[]" :maxLength="1" :type="'img'"  @result="uploadResult"></imgUpload>
          </a-form-model-item>
        </a-form-model>
	</a-modal>
  </div>
</template>
<script>
import EditableCell from "@/components/editableCell.vue"
import imgUpload from "@/components/myUpload.vue"
import MyTable from "@/components/myTable.vue"
import { classify_index,classify_add,classify_edit,classify_del,classify_field } from '@/api/classify'
export default {
	components:{MyTable,imgUpload,EditableCell},
  data() {
    return {
      	classify_index:classify_index,
		IMGHOST:window.IMGHOST,
		ajaxparams:{},//请求参数
		labelCol: { span: 4 },
      	wrapperCol: { span: 14 },
		/*
			列表header
		*/
		columns:[
			{
			  title: '操作',
			  key: 'operation',
			  fixed: 'left',
			  width: 200,
			  ellipsis: true,
			  scopedSlots: { customRender: 'button' },
			},
			{ title: '分类名称',ellipsis: true, width: 150, dataIndex: 'name', key: 'name' },
			{ 
				title: '图标', ellipsis: true,dataIndex: 'thumb', key: 'thumb', width: 100,
				scopedSlots: { customRender: 'thumb' },
			},
			{ 
				title: '排序', ellipsis: true,dataIndex: 'sort', key: 'sort',width: 150,
				scopedSlots: { customRender: 'sort' },
			},
			{ 
				title: '状态', ellipsis: true,dataIndex: 'status', key: 'status',width: 150,
				scopedSlots: { customRender: 'status' },
			},
			// { title: '目标地址', ellipsis: true,dataIndex: 'target', key: 'target',width: 150},
			{ title: '创建时间', ellipsis: true,dataIndex: 'ctime', key: 'ctime',width: 220},
			{ title: '更新时间', ellipsis: true,dataIndex: 'utime', key: 'utime',width: 220},
		],
		isDisabledSwitch:false,//是否禁用Switch
		submitLoading:false,
		dialogType:'add',
		dialogVisible:false,
		form:{
			name:'',
			sort:'',
			thumb:'',
		},
		rules:{
			name: [{ required: true, message: '请输入分类名', trigger: 'blur' }],
			sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
			thumb: [{ required: true, message: '请选择图片', trigger: 'change' }],
			pid: [{ required: true, message: '请选择所属分类', trigger: 'change' }],
		},
		classifyList:[]
    }
  },
  created() {
	this.getClassifyList()
  },
  methods: {
	// 上传图片回调
	uploadResult(row){//logo
		console.log(row)
		if(row.type=='img'){
			this.form.thumb = row.url
		}
	},
	getClassifyList(){
		classify_index().then((res)=>{
			let data = {
				id:0,
				name:'顶级',
				children:[]
			}
			for (let i = 0; i < res.length; i++) {
				let children = JSON.parse(JSON.stringify(res[i].children?res[i].children:[]))
				let arr = {
					...res[i],
					disabled:res[i].status==1?false:true
				}
				if(children.length!=0){
					arr.children = []
				}
				for (let j = 0; j < children.length; j++) {
					delete children[j].children
					let obj = {
						...children[j],
						disabled:children[j].status==1?false:true
					}
					arr.children.push(obj)
				}
				data.children.push(arr)
			}
			this.classifyList = [data]
		})
	},
	async switchClick(val,index){//禁用/启用
		this.isDisabledSwitch = true
		classify_field({field:'status',id:val.id,status:val.status==1?0:1}).then((result)=>{
			this.$message.success('操作成功');
			this.$refs.myTable.tableData[index].status= val.status==1?0:1
			setTimeout(()=>{
				this.isDisabledSwitch = false
				this.getClassifyList()
			},1500)
		}).catch(()=>{
			this.isDisabledSwitch = false
		})
	},
	async onCellChange(e,row,index){//编辑排序
		if(!e||e==0){
			this.$refs['edittableCell'+row.id].resetEdit(row.sort)
			this.$message.warning('排序值必须大于0');
			return
		}
		classify_field({field:'sort',id:row.id,sort:e}).then((res)=>{
			this.$message.success('操作成功');
			this.getClassifyList()
		}).catch(()=>{
			this.$refs['edittableCell'+row.id].resetEdit(row.sort)
		})
	},
	onSelectChange(val){
		console.log(val)
		
	},
	handleCancel(){
		this.submitLoading = false;
		this.dialogVisible=false
	},
	async onSubmit(){
		this.$refs.formModel.validate(async valid => {
			if (valid) {
				this.form.pid = this.form.pid.length>0?this.form.pid[this.form.pid.length-1]:0;
				this.submitLoading = true
				if(this.dialogType=='add'){
					classify_add(this.form).then((result)=>{
						this.$message.success('操作成功');
						setTimeout(()=>{
							this.$refs.myTable.getList()
							this.submitLoading = false;
							this.dialogVisible=false
							this.getClassifyList()
						},1500)
					}).catch(()=>{
						this.submitLoading = false;
					})

				}else{
					classify_edit(this.form).then((result)=>{
						this.$message.success('操作成功');
						setTimeout(()=>{
							this.$refs.myTable.getList()
							this.submitLoading = false;
							this.dialogVisible=false
							this.getClassifyList()
						},1500)
						
					}).catch(()=>{
						this.submitLoading = false;
					})
					
				}
				
			}else{
				return false
			}
		});
	},
	async delConfirm(val){//删除
		classify_del({id:val.id}).then((result)=>{
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.$refs.myTable.getList()
			},1500)
		})
	},
    dialogClick(type,row)  {
		this.dialogType=type;
		if(type=='edit'){
			this.form={
				id:row.id,
				name:row.name,
				sort:row.sort,
				pid:row.pid,
				level:row.level,
				thumb:row.thumb,
			}
		}else{
			this.form={
				name:'',
				sort:'',
				pid:0,
				thumb:'',
			}
		}
		this.dialogVisible=true
    },
  },
}
</script>
