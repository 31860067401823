<!-- 广告管理 -->

<style scoped>
	.img{
		/* width:30px; */
		height:30px
	}
	.ellipsis-1{
		max-width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; 
		word-wrap: break-word;
		 word-break: break-all 
	}
	.w150{
		width: 150px;
	}
</style>
<template>
  <div class="pageCentent">
    <a-form-model size="small" layout="inline" @keyup.native.enter="search()">
        <a-form-model-item label="姓名">
          <a-input  placeholder="请输入客户姓名" v-model="ajaxparams['name']" />
        </a-form-model-item>
        <a-form-model-item label="业务经理">
          <a-input  placeholder="请输入业务经理" v-model="ajaxparams['manager_name_no']" />
        </a-form-model-item>
      
        <a-form-model-item label="状态" prop="status">
          <a-select class="w150" v-model="ajaxparams['status_no']" placeholder="请选择" allowClear>
            <a-select-option :value="0" >未完成</a-select-option>
            <a-select-option :value="1" >完成</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="天数" prop="week_day">
          <a-select class="w150" v-model="ajaxparams['1_week_day']" placeholder="请选择" allowClear>
            <a-select-option :value="1" >1</a-select-option>
            <a-select-option :value="2" >2</a-select-option>
            <a-select-option :value="3" >3</a-select-option>
            <a-select-option :value="4" >4</a-select-option>
            <a-select-option :value="5" >5</a-select-option>
            <a-select-option :value="6" >6</a-select-option>
            <a-select-option :value="7" >7</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="轮次" prop="week">
          <a-select class="w150" v-model="ajaxparams['1_week']" placeholder="请选择" allowClear>
            <a-select-option :value="1" >1</a-select-option>
            <a-select-option :value="2" >2</a-select-option>
            <a-select-option :value="3" >3</a-select-option>
            <a-select-option :value="4" >4</a-select-option>
          </a-select>
        </a-form-model-item>
        <br/>
      <a-form-model-item label="省份">
        <a-select style="width:200px" allowClear v-model="ajaxparams['province_code']" placeholder="请选择省份" :showSearch="true" optionFilterProp="label">
          <a-select-option :label="item.name" :value="item.code" v-for="(item, index) in regionTree" :key="index">{{ item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="任务时间">
        <a-date-picker @change="changeDate" v-model="ajaxparams.task_date" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
      </a-form-model-item>
      <a-form-model-item >
        <a-button icon="search" @click="$refs.myTable.search()" type="primary"> 搜索</a-button>
      </a-form-model-item>
      <a-form-model-item >
				<a-button :loading="buttonLoading"  @click="doExport" type="primary"> 导出 </a-button>
			</a-form-model-item>
    </a-form-model>
    <MyTable class="mt10" :isSelection="false" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
      <a-space slot='status' slot-scope="item,row,index">
        <span>{{row.status==0?'未完成':'完成'}}</span>
      </a-space>
      <a-space slot='province_code' slot-scope="item,row,index">
        <span>{{findProvinceName(item)}}</span>
      </a-space>
      <a-space slot='manager_id' slot-scope="item,row,index">
        <span>{{findManagerName(item)}}</span>
      </a-space>
    </MyTable>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {userTask_index} from '@/api/manager'
import { user_task }from "@/api/export"

export default {
	components:{MyTable},
  data() {
    return {
		IMGHOST:window.IMGHOST,
		apiUrl:userTask_index,
		ajaxparams:{},
    regionData:[],
    regionTree: [],
    managerInfo:[],
    buttonLoading:false,
		columns:[
			{ title: '用户', ellipsis: true,dataIndex: 'name',key: 'name'},
      { title: '业务经理', ellipsis: true,dataIndex: 'manager_id', key: 'manager_id',scopedSlots: { customRender: "manager_id" }},
			{ title: '任务名', ellipsis: true,dataIndex: 'task_name',key: 'task_name'},
			{ title: '积分', ellipsis: true,dataIndex: 'reward_points',key: 'reward_points'},
			{ title: '任务日期', ellipsis: true,dataIndex: 'task_date', key: 'task_date'},
			{ title: '状态', ellipsis: true,dataIndex: 'status', key: 'status',scopedSlots: { customRender: "status" }},
      { title: '省份', ellipsis: true,dataIndex: 'province_code', key: 'province_code',scopedSlots: { customRender: "province_code" }},
			{ title: '第几天', ellipsis: true,dataIndex: 'week_day', key: 'week_day'},
			{ title: '轮次', ellipsis: true,dataIndex: 'week', key: 'week'},
		],
		queryData:{

    }
	}
  },
  created() {
  },
  mounted() {
    this.regionData = this.$db.get("regionList").data
    this.regionTree = this.$common.treeData(this.regionData, "pcode", "code", 0)
    this.managerInfo = this.$db.get('manager_index').data
  },
  methods: {
    //执行导出
	doExport(){
    this.buttonLoading = true
		user_task(this.ajaxparams).then(res => {
      this.buttonLoading = false
			this.$message.success("添加导出队列成功")
			this.$db.set('is_export',1)
		},err => {
      this.buttonLoading = false
			this.$message.warning("导出失败")
		})
		
	},
    search(){
      this.$refs.myTable.search()
    },
  
    //选择日期
    changeDate(date,dateString){
      this.ajaxparams.task_date = dateString
    },
    findProvinceName(code){
			let rest = this.regionData.find((item)=>{return code==item.code})
			if(rest){
				return rest.name
			}
			return '-'
		},
    findManagerName(id){
			let rest = this.managerInfo.find((item)=>{return id==item.id})
			if(rest){
				return rest.manager_name
			}
			return '-'
		},
  },
}
</script>
	