<!-- 账号管理 -->
<style scoped>
</style>
<template>
  <div >
	<div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
		<a-form-model :rules="rules" ref="formModel" size="small" :model="form">
          <a-form-model-item label="类型" >
            <span>{{levels[form.type]}}</span>
          </a-form-model-item>
          <a-form-model-item label="说明内容" >
            <div class="w-100">
				<wangeditor v-if="isShow" ref="editor" @change="editorChange" :content="form.content"></wangeditor>
			</div>
          </a-form-model-item>
		  <a-form-model-item >
            <a-button type="primary" :loading="submitLoading" @click="onSubmit">
				保存
			</a-button>
          </a-form-model-item>
        </a-form-model>
  	</div>
  </div>
</template>
<script>
import wangeditor from "@/components/wangeditor.vue"
import { setting_updateSysDescMember,config_index } from '@/api/config'
export default {
	components:{wangeditor},
	props:{
		obj:Object
	},
  data() {
    return {
		IMGHOST:window.IMGHOST,
		ajaxparams:{},//请求参数
		/*
			列表header
		*/
		levels:{
			'sys_desc_member_vip': 'vip',
			'sys_desc_member_A1':'A1',
			'sys_desc_member_A2':'A2',
			'sys_desc_member_A3':'A3',
			'sign_rule':'签到规则',
		},
		
		submitLoading:false,
		form:{
			"type":this.obj.cname,
			"content":this.obj.content,
		},
		rules:{
		},
		isShow:false
    }
  },
  created() {
	this.form = {
		"type":this.obj.cname,
		"content":this.obj.cvalue,
	}
	setTimeout(()=>{
		this.isShow = true
	},100)

  },
  mounted(){
	
  },
  methods: {
	editorChange(html){
		this.form.content = html
	},
	
	async onSubmit(){
		this.$refs.formModel.validate(async valid => {
			if (valid) {
				this.submitLoading = true
				setting_updateSysDescMember(this.form).then((result)=>{
					this.$message.success('操作成功');
					this.$emit('submit')
					this.submitLoading = false;
				}).catch(()=>{
					this.submitLoading = false;
				})
			}else{
				return false
			}
		});
	},
	
  },
}
</script>
