<!-- 账号管理 -->
<style scoped>
.width150 {
  width: 150px;
}
</style>
<template>
  <div>
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
      <div>
      <a-form-model
        size="small"
        layout="inline"
        :model="ajaxparams"
        @keyup.native.enter="$refs.myTable.search()"
      >
        <a-form-model-item label="类型" prop="type">
          <a-select class="width150" v-model="ajaxparams['type']" placeholder="请选择" >
            <a-select-option value="today" >当日</a-select-option>
            <a-select-option value="all" >累计</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="选择日期">
        <a-date-picker v-model="ajaxparams['date']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" value=null />
      </a-form-model-item>
        <a-form-model  size="small" layout="inline" @keyup.native.enter="search()">
         
      </a-form-model>
         <a-form-model-item>
          <a-button icon="search" @click="$refs.myTable.search()" type="primary">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
      </div>
      <MyTable
        class="mt10"
        :apiUrl="orders_order_rank"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
        @selectRow="onSelectChange"
        :isSelection="false"
        :isPagination="false"
      >
       <a-space slot='id' slot-scope="item,row,index">
          <span>{{index+1}}</span>
        </a-space>
        <a-space slot='manager_id' slot-scope="item,row,index">
          <span>{{findManager(item)}}</span>
        </a-space>
        <a-space slot='user_level' slot-scope="item,row,index">
          <span>{{row.is_fake ? '无' : levels[item]}}</span>
        </a-space>
        <a-space slot='is_fake' slot-scope="item,row,index">
          <span>{{row.is_fake == 0 ? '否' : '是' }}</span>
        </a-space>

        <a-space slot='amount' slot-scope="item,row,index">
          <span>{{$common.countFloat(row.amount/100,2)}}</span>
        </a-space>
        
      </MyTable>
    </div>
 
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import { orders_order_rank } from "@/api/orders"
import { manager_user_index } from "@/api/manager"
import moment from "moment"
export default {
  components: { MyTable },
  data() {
    return {
      orders_order_rank: orders_order_rank,
      ajaxparams: {type:"today"}, //请求参数
      levels:['vip','A1','A2','A3'],
      manager:[],
      pickerValue:[moment(new Date().getTime()- 86400000).format('YYYY-MM-DD'),moment(new Date().getTime()).format('YYYY-MM-DD')],

      /*
			列表header
		*/
      columns: [
        {
          title: "排名",
          key: "id",
          dataIndex: "id",
          width: 80,
          scopedSlots: { customRender: 'id' }
        },
       
        {
          title: "用户名",
          key: "name",
          dataIndex: "name",
          width: 130,
        },
         {
          title: "用户等级",
          key: "user_level",
          dataIndex: "user_level",
          width: 130,
          scopedSlots: { customRender: 'user_level' }
        },
        {
          title: "手机号",
          width: 220,
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "订单金额",
          width: 220,
          dataIndex: "amount",
          key: "amount",
          scopedSlots: { customRender: 'amount' }
        },
        {
          title: "业务员",
          width: 120,
          dataIndex: "manager_id",
          key: "manager_id",
          scopedSlots: { customRender: 'manager_id' }
        },
        { 
          title: '手工数据',
          dataIndex: 'is_fake',
          key: 'is_fake', 
          width: 100,
          scopedSlots: { customRender: 'is_fake' }
        }
    
      ],
    }
  },
  mounted(){
    const _this = this
    manager_user_index().then(res => {
        _this.manager = res
    })
  },
  onLoad(){
    
  },
  methods: {
    //查找业务员
    findManager(manager_id){
      if(!manager_id) return ''
      const targetManager = this.manager.filter(obj => obj.id == manager_id)
      return targetManager[0].manager_name
    },
     prickerChange(e){
      console.log(e)
    },
  },
}
</script>
