import request from '@/utils/request'

// 开票列表
export function userInvoice_index(data) {
  return request({
    url: '/merchant/userInvoice/index',
    method: 'get',
    params:data
  })
}

// 更新状态
export function userInvoice_status(data) {
  return request({
    url: '/merchant/userInvoice/status',
    method: 'post',
    data:data
  })
}





