<style scoped></style>
<template>
  <div class="pageCentent">
    <!-- 搜索条件 -->
    <a-form-model size="small" layout="inline" :model="ajaxparams" @keyup.native.enter="submitForm()">
      <a-form-model-item label="金额">
        <a-input type="number" placeholder="请输入金额" v-model="formModel['1_payment_amt']" />
      </a-form-model-item>
      <a-form-model-item label="等级">
        <a-select allowClear style="width:200px" v-model="formModel['1_goods_name']" placeholder="请选择等级" >
          <a-select-option :label="item.vip" :value="item.key" v-for="(item, index) in levels" :key="index">{{ item.vip }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="省份">
        <a-select style="width:200px" allowClear v-model="formModel['2_province_code']" placeholder="请选择省份" :showSearch="true" optionFilterProp="label">
          <a-select-option :label="item.name" :value="item.code" v-for="(item, index) in provinceData" :key="index">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="日期">
        <a-range-picker v-model="formModel['1_create_at_between']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" value=null />
      </a-form-model-item>
      <a-form-model-item>
        <a-button icon="search" @click="submitForm('search')" type="primary">搜索</a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="submitForm('export')" type="primary">导出</a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <MyTable :isSelection="false" class="mt10" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
        <a-space slot="payment_amt" slot-scope="item,row,index">
          <span>{{ (item / 100).toFixed(2) }}</span>
        </a-space>
        <a-space slot="payment_method" slot-scope="item,row,index">
          <span>{{ item === "aliPay" ? "支付宝" :(item === 'weChat' ? "微信" : "后台充值")}}</span>
        </a-space>

        <a-space slot="province_code" slot-scope="item,row,index">
          <span>{{ findProvinceName(item) }}</span>
        </a-space>
        <a-space slot="goods_name" slot-scope="item,row,index">
          <a-tag :color="t.color" v-for="(t, i) in levels" :key="i" v-if="row.goods_name == t.key">{{ t.vip }}</a-tag>
        </a-space>
      </MyTable>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {
  user_recharge,
} from "@/api/user_bill"
import { member_recharge }from "@/api/export"
import moment from "moment"

export default {
  components: { MyTable },
  data() {
    return {
      apiUrl: user_recharge,
      ajaxparams: {},
      formModel:{},
      levels: [
        { vip: "vip", key: "vip", color: "#87d068" },
        { vip: "A1", key: "A1", color: "#2db7f5" },
        { vip: "A2", key: "A2", color: "#ff4500" },
        { vip: "A3", key: "A3", color: "#f50" },
      ],
      columns: [
        {
          title: "id",
          ellipsis: true,
          dataIndex: "id",
          key: "id",
        },
        {
          title: "用户名",
          ellipsis: true,
          width:120,
          dataIndex: "name",
          key: "name",
        },
        {
          title: "推荐人",
          ellipsis: true,
          width:120,
          dataIndex: "recman",
          key: "recman",
        },
         {
          title: "省份",
          dataIndex: "province_code",
          key: "province_code",
          scopedSlots: { customRender: "province_code" },
        },
        {
          title: "等级",
          width:80,
          dataIndex: "goods_name",
          key: "goods_name",
          scopedSlots: { customRender: "goods_name" },
        },
        {
          title: "金额",
          ellipsis: true,
          dataIndex: "payment_amt",
          key: "payment_amt",
          scopedSlots: { customRender: "payment_amt" },
        },
        {
          title: "支付方式",
          ellipsis: true,
          dataIndex: "payment_method",
          key: "payment_method",
          scopedSlots: { customRender: "payment_method" },
        },
        {
          title: "交易号",
          ellipsis: true,
          dataIndex: "trade_no",
          key: "trade_no",
        },
        {
          title: "日期",
          dataIndex: "create_at",
          key: "create_at",
        },
      ],
      regionData: [],
	    provinceData:[],
    }
  },
  computed: {
   
  },
  created() {
  },
  mounted() {
    this.regionData = this.$db.get("regionList").data
    this.provinceData = this.regionData.filter(item => {
      return item.depth == 1
    })
  },
  methods: {
    findProvinceName(code){
			let rest = this.regionData.find((item)=>{return code==item.code})
			if(rest){
				return rest.name
			}
			return ''
		},
    submitForm(formType){
      this.ajaxparams = {}
      //search(搜索) 或者  export(导出)
      this.ajaxparams['form_type_no'] = formType 
      for (let k in this.formModel){
          if(k === '1_payment_amt')this.ajaxparams[k] = this.formModel[k] * 100
          else if(k === '1_create_at_between' && this.formModel[k].length){
              this.ajaxparams[k] = this.formModel[k]
          } 
          else this.ajaxparams[k] = this.formModel[k]
      }
      //导出
      if(formType === 'export'){
          if(this.formModel['1_create_at_between'] && this.formModel['1_create_at_between'].length){
              member_recharge(this.ajaxparams).then(res => {
                  this.$message.success("添加导出队列成功")
                  this.$db.set('is_export',1)
              },err => {
                this.$message.warning("导出失败")
              })
          }else{
            this.$message.warning("请选择导出日期")
              return false
          }
          
      }else{ //搜索
        this.$nextTick(() => {
          this.$refs.myTable.search()
        })
      }
    }
  },
}
</script>
	