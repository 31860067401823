<!-- 账号管理 -->
<style scoped>
	.width150{
		width: 150px
	}
</style>
<template>
  <div >
	<div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
		<!-- 搜索条件 -->
		<a-form-model  size="small" layout="inline" :model="ajaxparams"  @keyup.native.enter="$refs.myTable.search()">
			<a-form-model-item label="订单号">
				<a-input  placeholder="请输入订单号" v-model="ajaxparams['2_order_sn_like']" />
			</a-form-model-item>
			<a-form-model-item label="用户">
				<a-input  placeholder="请输入用户" v-model="ajaxparams['3_name_like']" />
			</a-form-model-item>
			<a-form-model-item label="商品名称">
				<a-input  placeholder="请输入商品名称" v-model="ajaxparams['1_goods_name_like']" />
			</a-form-model-item>
			<a-form-model-item label="品牌">
				<a-select style="width:200px" allowClear v-model="ajaxparams['1_brand_id']" placeholder="请选择品牌">
				<a-select-option :value="item.id" v-for="(item, index) in brandData" :key="index">{{ item.brand_name
				}}</a-select-option>
				</a-select>
			</a-form-model-item>
			 <a-form-model-item label="省份">
				<a-select style="width:200px" allowClear v-model="ajaxparams['2_province_code']" placeholder="请选择省份" :showSearch="true" optionFilterProp="label">
				<a-select-option :label="item.name" :value="item.code" v-for="(item, index) in provinceData" :key="index">{{ item.name
				}}</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="日期" >
				<a-range-picker v-model="ajaxparams['2_ctime_between']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" value=null />
			</a-form-model-item>
			<a-form-model-item >
				<a-button icon="search" @click="$refs.myTable.search()" type="primary"> 搜索</a-button>
			</a-form-model-item>
			<a-form-model-item >
				<a-button  @click="doExport" type="primary"> 导出 </a-button>
			</a-form-model-item>
		</a-form-model>
		<MyTable class="mt10" :apiUrl="orders_item" :params="ajaxparams" ref="myTable" :columns="columns" :rowSelection="false" :scroll="{x:1300}">
			<a-space slot='order_sn' slot-scope="item,row,index">
				<router-link target="_blank" :to="{ path: '/orders/detail', query: { id: row.order_sn }}">
					<span>{{row.order_sn}}</span>
				</router-link>
			</a-space>
			
			<a-space slot='ctime' slot-scope="item,row,index">
				<span>{{item.substr(0,10)}}</span>
			</a-space>

			<a-space slot='is_delivery' slot-scope="item,row,index">
				<span>{{item ? '已发货':'未发货'}}</span>
			</a-space>

			<a-space slot='brand_id' slot-scope="item,row,index">
				<span>{{findBrandName(item)}}</span>
			</a-space>
			<a-space slot='province_code' slot-scope="item,row,index">
				<span>{{findProvinceName(item)}}</span>
			</a-space>

			<a-space slot='is_ready' slot-scope="item,row,index">
				<span>{{item ? '已开单':'未开单'}}</span>
			</a-space>
			<a-space slot='goods_amount' slot-scope="item,row,index">
				<span>{{calculatePrice(row,'goods_amount')}}</span>
			</a-space>
			<a-space slot='price' slot-scope="item,row,index">
				<span>{{$common.countFloat((item?item:0)/100,2)}}</span>
			</a-space>
			<a-space  slot='payed_amount' slot-scope="item,row,index">
				<span>{{calculatePrice(row,'payed_amount')}}</span>
			</a-space>
			<a-space slot='order_amount' slot-scope="item,row,index">
				<span>{{calculatePrice(row,'order_amount')}}</span>
			</a-space>
			<a-space slot='payed_coin' slot-scope="item,row,index">
				<span>{{calculatePrice(row,'payed_coin')}}</span>
			</a-space>
		
		</MyTable>
	</div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import { orders_item} from '@/api/orders'
import { brand_index } from "@/api/goods"
import { order_item }from "@/api/export"
import moment from "moment"
export default {
	components:{MyTable},
  data() {
    return {
      	orders_item:orders_item,
		ajaxparams:{},//请求参数
		/*
			列表header
		*/
		columns:[
			{ title: '订单日期',width:120, dataIndex: 'ctime',  key: 'ctime',scopedSlots:{ customRender: 'ctime' }},
			{ title: '订单号', width: 100,  dataIndex: 'order_sn', key: 'order_sn' ,scopedSlots: { customRender: 'order_sn' }},
			{ title: '客户', width:80,dataIndex:  'name',key: 'name'},
			{ title: '品牌', width:120,dataIndex:  'brand_id',key: 'brand_id', scopedSlots: { customRender: 'brand_id' }},
			{ title: '省份', width:150,dataIndex:  'province_code',key: 'province_code', scopedSlots: { customRender: 'province_code' }},
			{ title: '发货状态',width:100, dataIndex: 'is_delivery', key: 'is_delivery', scopedSlots: { customRender: 'is_delivery' }},
			{ title: '商品名称', width:250,dataIndex: 'goods_name', key: 'goods_name'},
			{ title: '规格名称', width:300,dataIndex: 'goods_spec_desc', key: 'goods_spec_desc'},
			{ title: '商品数量',width:100, dataIndex: 'num', key: 'num'},
			{ title: '商品单价',width:100, dataIndex: 'price', key: 'price', scopedSlots: { customRender: 'price' }},
			{ title: '商品金额', width:100,dataIndex: 'goods_amount', key: 'goods_amount', scopedSlots: { customRender: 'goods_amount' }},
			{ title: '订单金额', width:100,dataIndex: 'order_amount', key: 'order_amount', scopedSlots: { customRender: 'order_amount' }},
			{ title: '订单支付金额', width:100, dataIndex: 'payed_amount', key: 'payed_amount', scopedSlots: { customRender: 'payed_amount' }},
			{ title: '共富币支付', width:100, dataIndex: 'payed_coin', key: 'payed_coin', scopedSlots: { customRender: 'payed_coin' }},
		],
		submitLoading:false,
		regionData:[],
		brandData:[],
		provinceData:[],
    }
  },
  mounted() {
	this.regionData = this.$db.get('regionList').data
	brand_index({ page: 1, limit: 100 }).then((res) => {
      this.brandData = res.list
    })
	this.provinceData = this.regionData.filter(item => {
      return item.depth == 1
    })
	
  },
  computed:{
	  
  },
  methods: {

	//执行搜索
	doExport(){
		//导出
		if(!this.ajaxparams['2_ctime_between']){
			this.$message.warning("请选择导出日期")
			return 
		}
		order_item(this.ajaxparams).then(res => {
			this.$message.success("添加导出队列成功")
			this.$db.set('is_export',1)
		},err => {
			this.$message.warning("导出失败")
		})
		
	},
	//计算单品商品价格
	calculatePrice(row,priceType){
		let res = "";
		
		//商品价格  单价 * 数量
		const goodsAmount = row.num * row.price
		switch (priceType) {
			case 'goods_amount':
				res = goodsAmount
				break;
			
			//订单金额  单品价格 / 订单商品总价 * 订单总价
			case 'order_amount':
				res = goodsAmount / row.goods_amount * row.order_amount
				break;
			
			//支付金额  单品价格 / 订单商品总价 * 支付总价
			case 'payed_amount':
				res = goodsAmount / row.goods_amount * row.payed_amount
				break;
			//共富币支付金额 单品总价 / 订单商品总价 * 共富币支付金额
			case 'payed_coin':
				res = goodsAmount / row.goods_amount * row.payed_coin
				break;
			default:
				this.$message.warning('参数错误')
				break;
		}
		if(!goodsAmount) return 0
		return parseInt(res / 100)
	
	},
	returnRegionName(code){
		let rest = this.regionData.find((item)=>{return code==item.code})
		if(rest){
			return rest.name
		}
		return ''
	},
	findProvinceName(code){
			let rest = this.regionData.find((item)=>{return code==item.code})
			if(rest){
				return rest.name
			}
			return ''
		},
	findBrandName(id){
		let rest = this.brandData.find((item)=>{return id==item.id})
		if(rest){
			return rest.brand_name
		}
		return ''
	},
  },
}
</script>
