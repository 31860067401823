<!-- 账号管理 -->
<style scoped>
.width150 {
  width: 150px;
}
</style>
<template>
  <div>
    <div style="background-color: #fff; padding: 16px; margin-bottom: 20px">
      <a-button type="primary" @click="dialogClick()"> 添加 </a-button>
      <MyTable
        class="mt10"
        :isSelection="false"
        :apiUrl="notice_index"
        :params="notice_ajaxparams"
        ref="myTable"
        :columns="columns"
      >
        <a-space slot="goods" slot-scope="item, row, index">
          <span>
            {{ row.goods ? row.goods.goods_name : "-" }}
          </span>
        </a-space>
        <a-space slot="is_publish" slot-scope="item, row, index">
          <span>
            {{ item == 1 ? "已发布" : "未发布" }}
          </span>
        </a-space>
        <a-space slot="type" slot-scope="item, row, index">
          <span>
            {{ row.type == 1 ? "图文" : "视频" }}
          </span>
        </a-space>
        <a-space slot="province_code" slot-scope="item, row, index">
          <a-tag
            v-for="(t, i) in row.province_code
              ? province_code(row.province_code)
              : []"
            :key="i"
            >{{ t }}</a-tag
          >
        </a-space>
        <a-space slot="button" slot-scope="item, row, index">
          <a-popconfirm
            title="确定是否删除?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="delConfirm(row)"
          >
            <a-button size="small" type="danger"> 删除 </a-button>
          </a-popconfirm>
          <a-button size="small" type="primary" @click="toDetail(row)">
            详情
          </a-button>
        </a-space>
      </MyTable>
    </div>
    <!-- 添加 -->
    <a-modal
      :maskClosable="false"
      v-if="dialogVisible"
      v-model="dialogVisible"
      :title="dialogType == 'add' ? '添加公告' : '编辑公告'"
      :confirm-loading="submitLoading"
      @ok="onSubmit"
      @cancel="handleCancel"
    >
      <a-form-model
        :rules="rules"
        ref="formModel"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        :model="form"
      >
        <a-form-model-item label="标题" prop="title">
          <a-input placeholder="请输入标题" v-model="form.title" />
        </a-form-model-item>
        <a-form-model-item label="内容" prop="content">
          <a-textarea
            placeholder="请输入内容"
            v-model="form.content"
            :rows="4"
          />
        </a-form-model-item>
        <a-form-model-item label="类型" prop="type">
          <a-select allowClear v-model="form.type" placeholder="请选择类型">
            <a-select-option :value="1">图文</a-select-option>
            <a-select-option :value="2">视频</a-select-option>
          </a-select>
        </a-form-model-item>
        <template v-if="form.type === 1 && dialogVisible">
          <a-form-model-item label="图片" prop="imgs">
            <!-- <div class="fs12 color666">建议上传尺寸：750*750</div> -->
            <imgUpload
              @remove="removeUpload"
              @result="resultUpload"
              :uploadType="'img'"
              :list="form.imgs ? form.imgs : []"
              :maxLength="9"
              :type="'imgs'"
              key="imgs"
            ></imgUpload>
          </a-form-model-item>
        </template>
        <template v-if="form.type === 2 && dialogVisible">
          <a-form-model-item label="视频" prop="video">
            <imgUpload
              @remove="removeUpload"
              @result="resultUpload"
              :uploadType="'video'"
              :list="form.video ? [form.video] : []"
              :maxLength="1"
              :type="'video'"
              key="video"
            ></imgUpload>
          </a-form-model-item>
          <a-form-model-item label="视频封面" prop="cover_img">
            <imgUpload
              @remove="removeUpload"
              @result="resultUpload"
              :uploadType="'img'"
              :list="form.cover_img ? [form.cover_img] : []"
              :maxLength="1"
              :type="'cover_img'"
              key="cover_img"
            ></imgUpload>
          </a-form-model-item>
        </template>
        <a-form-model-item label="区域" prop="province_code">
          <a-select
            mode="multiple"
            allowClear
            v-model="form.province_code"
            placeholder="请选择区域"
          >
            <a-select-option
              :value="item.name"
              v-for="(item, index) in regionData"
              :key="index"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="发布时间" prop="publish_time">
          <a-date-picker
            show-time
            v-model="form.publish_time"
            format="YYYY-MM-DD HH:mm"
            valueFormat="YYYY-MM-DD HH:mm"
            value="null"
          />
        </a-form-model-item>
        <a-form-model-item label="商品" prop="goods_id">
          <a-button @click="goodsClick">选择</a-button>
          <div>
            <a-tag v-if="selectRowValue">{{ selectRowValue.goods_name }}</a-tag>
          </div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 选择商品 -->
    <a-modal
      bodyStyle="max-height:500px;overflow-y:auto;"
      :maskClosable="false"
      :width="800"
      v-model="goodsVisible"
      v-if="goodsVisible"
      title="选择商品"
      :confirm-loading="goodsSubmitLoading"
      @ok="onGoodsSubmit"
      @cancel="handleGoodsCancel"
    >
      <a-form-model
        size="small"
        layout="inline"
        :model="ajaxparams"
        @keyup.native.enter="$refs.myGoodsTable.search()"
      >
        <a-form-model-item label="商品名称">
          <a-input
            placeholder="请输入商品名称"
            v-model="ajaxparams['goods_name_like']"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            icon="search"
            @click="$refs.myGoodsTable.search()"
            type="primary"
            >搜索</a-button
          >
        </a-form-model-item>
      </a-form-model>
      <MyTable
        class="mt10"
        selectType="radio"
        :apiUrl="goods_index"
        :params="ajaxparams"
        ref="myGoodsTable"
        :columns="goodsColumns"
        @selectRow="selectRow"
      >
        <a-space slot="cover_img" slot-scope="item, row, index">
          <img
            v-if="row.cover_img"
            style="width: 30px; heigth: 30px"
            :src="IMGHOST + '/' + row.cover_img"
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="goods_price" slot-scope="item, row, index">
          <span>{{ row.goods_price / 100 }}</span>
        </a-space>
        <a-space slot="goods_type" slot-scope="item, row, index">
          <span>{{ goodsTypeData[item] }}</span>
        </a-space>
      </MyTable>
    </a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue";
import { notice_index, notice_add, notice_del } from "@/api/notice";
import { goods_index } from "@/api/goods";
import imgUpload from "@/components/myUpload.vue";

export default {
  components: { MyTable, imgUpload },
  data() {
    return {
      IMGHOST: window.IMGHOST,
      notice_index: notice_index,
      goods_index: goods_index,
      notice_ajaxparams: {},
      ajaxparams: {}, //请求参数
      /*
        列表header
      */
      columns: [
        {
          title: "ID",
          // fixed: "left",
          ellipsis: true,
          width: 70,
          dataIndex: "id",
          key: "id",
        },
        { title: "标题", width: 160, dataIndex: "title", key: "title" },
        { title: "内容", width: 300, dataIndex: "content", key: "content" },
        {
          title: "绑定的商品",
          width: 180,
          dataIndex: "goods",
          key: "goods",
          scopedSlots: { customRender: "goods" },
        },
        {
          title: "类型",
          ellipsis: true,
          width: 80,
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "浏览量",
          ellipsis: true,
          width: 80,
          dataIndex: "views",
          key: "views",
          scopedSlots: { customRender: "views" },
        },
        {
          title: "区域",
          width: 120,
          dataIndex: "province_code",
          key: "province_code",
          scopedSlots: { customRender: "province_code" },
        },
        {
          title: "发布时间",
          width: 220,
          dataIndex: "publish_time",
          key: "publish_time",
        },
        {
          title: "是否发布",
          width: 100,
          dataIndex: "is_publish",
          key: "is_publish",
          scopedSlots: { customRender: "is_publish" },
        },
        {
          title: "操作",
          key: "operation",
          width: 100,
          ellipsis: true,
          scopedSlots: { customRender: "button" },
        },
      ],
      submitLoading: false,
      dialogType: "add",
      dialogVisible: false,
      form: {
        id: 0,
        title: "",
        content: "",
        goods_id: 0,
        type: 1,
        province_code: [],
        imgs: [],
        video: "",
        cover_img: "",
        publish_time: "",
      },
      loading: false,
      tableData: [],
      rules: {
        title: [{ required: true, message: "请选择标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        // goods_id: [{ required: true, message: '请输入绑定的商品', trigger: 'change' }],
        type: [{ required: true, message: "请输入类型", trigger: "blur" }],
        province_code: [
          { required: true, message: "请选择区域", trigger: "change" },
        ],
        publish_time: [
          { required: true, message: "请选择发布时间", trigger: "change" },
        ],
      },
      regionData: [],

      goodsVisible: false,
      goodsSubmitLoading: false,

      goodsColumns: [
        {
          title: "ID",
          // fixed: "left",
          ellipsis: true,
          width: 70,
          dataIndex: "id",
          key: "id",
        },
        {
          title: "商品名称",
          ellipsis: true,
          width: 150,
          dataIndex: "goods_name",
          key: "goods_name",
        },
        {
          title: "商品封面",
          dataIndex: "cover_img",
          key: "cover_img",
          width: 100,
          scopedSlots: { customRender: "cover_img" },
        },
        { title: "商品描述", width: 250, dataIndex: "brief", key: "brief" },
        {
          title: "商品价格",
          ellipsis: true,
          width: 150,
          dataIndex: "goods_price",
          key: "goods_price",
          scopedSlots: { customRender: "goods_price" },
        },
        {
          title: "库存",
          ellipsis: true,
          dataIndex: "total_stock",
          key: "total_stock",
          width: 150,
          scopedSlots: { customRender: "total_stock" },
        },
        {
          title: "商品类型",
          ellipsis: true,
          dataIndex: "goods_type",
          key: "goods_type",
          width: 150,
          scopedSlots: { customRender: "goods_type" },
        },

        // {
        //   title: "排序",
        //   ellipsis: true,
        //   dataIndex: "sort",
        //   key: "sort",
        //   width: 150,
        //   scopedSlots: { customRender: "sort" },
        // },
        {
          title: "上架状态",
          ellipsis: true,
          dataIndex: "is_shelves",
          key: "is_shelves",
          width: 150,
          scopedSlots: { customRender: "is_shelves" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "ctime",
          key: "ctime",
          width: 220,
        },
        {
          title: "更新时间",
          ellipsis: true,
          dataIndex: "utime",
          key: "utime",
          width: 220,
        },
      ],
      selectRowValue: null,
    };
  },
  created() {
    let data = this.$db.get("regionList").data;
    let regionData = [
      {
        code: 0,
        depth: 0,
        id: 0,
        name: "全部",
        pcode: 0,
      },
    ];
    for (let i = 0; i < data.length; i++) {
      if (data[i].depth == 1) {
        regionData.push(data[i]);
      }
    }
    this.regionData = regionData;
  },
  methods: {
    async delConfirm(val) {
      //删除
      notice_del({ id: val.id }).then((result) => {
        this.$message.success("操作成功");
        setTimeout(() => {
          this.$refs.myTable.getList();
        }, 1500);
      });
    },
    selectRow(value) {
      this.selectRowValue = value[0];
    },
    province_code(codes) {
      codes = codes.split(",");
      let _this = this;
      let names = [];
      for (let i = 0; i < codes.length; i++) {
        let result = _this.regionData.find((item) => {
          return codes[i] == item.code;
        });
        if (result) {
          names.push(result.name);
        }
      }
      return names;
    },
    uploadRemove() {
      this.form.img = "";
    },
    // 上传图片回调
    uploadResult(row) {
      //logo
      if (row.type == "img") {
        this.form.img = row.url;
      }
    },
    handleCancel() {
      this.$nextTick(() => {
        this.submitLoading = false;
        this.dialogVisible = false;
        this.selectRowValue = null;
      });
    },
    onSubmit() {
      this.$refs.formModel.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          const codes = this.regionData
            .filter(
              (i) =>
                this.form.province_code.findIndex((a) => a === i.name) !== -1
            )
            .map((i) => i.code);
          let params = {
            ...this.form,
            province_code: codes.join(","),
            goods_id: this.form.goods_id || 0,
          };
          if (this.form.type == 1) {
            if (this.form.imgs && this.form.imgs.length) {
              params.imgs = this.form.imgs.join(",");
            }
            params.cover_img = "";
            params.video = "";
          } else {
            params.imgs = "";
          }
          notice_add(params)
            .then(() => {
              this.$message.success("操作成功");
              setTimeout(() => {
                this.$refs.myTable.getList();
                this.submitLoading = false;
                this.dialogVisible = false;
              }, 1500);
            })
            .catch(() => {
              this.submitLoading = false;
            });
        }
      });
    },
    dialogClick() {
      this.form = {
        title: "",
        content: "",
        goods_id: null,
        type: 1,
        province_code: [],
        imgs: [],
        video: "",
        cover_img: "",
      };
      this.dialogVisible = true;
      this.selectRowValue = null;
    },

    goodsClick() {
      this.goodsVisible = true;
    },
    handleGoodsCancel() {
      this.goodsSubmitLoading = false;
      this.goodsVisible = false;
    },

    // 上传删除处理
    removeUpload(row) {
      this.$nextTick(() => {
        if (row.type == "imgs") {
          this.form.imgs.splice(row.index, 1);
        } else if (row.type == "cover_img") {
          this.form.cover_img = "";
        } else if (row.type == "video") {
          this.form.video = "";
        }
      });
    },
    // 上传回调处理
    resultUpload(row) {
      this.$nextTick(() => {
        if (row.type == "imgs") {
          this.form.imgs.push(row.url);
        } else if (row.type == "cover_img") {
          this.form.cover_img = row.url;
        } else if (row.type == "video") {
          this.form.video = row.url;
        }
      });
    },
    async onGoodsSubmit() {
      this.$nextTick(() => {
        this.form.goods_id = this.selectRowValue.id;
        this.form.setFieldsValue({
          goods_id: this.selectRowValue.id,
        });
      });
      this.handleGoodsCancel();
    },
    toDetail(row) {
      const { goods, ...data } = row;
      let text = this.$router.resolve({
        path: "/notice/detail",
        query: {
          ...data,
          goods_name: goods?.goods_name || "-",
        },
      });
      window.open(text.href, "_blank");
    },
  },
};
</script>

