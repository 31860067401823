<!-- 账号管理 -->
<style scoped></style>
<template>
	<div>
		<div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
			<MyTable class="mt10" :apiUrl="userPost_index" :params="ajaxparams" ref="myTable" :columns="columns">
				<a-space slot='button' slot-scope="item,row,index">
					<a-popconfirm title="确定是否通过?" ok-text="确定" cancel-text="取消" @confirm="changeStatus(row,1)">
						<a-button v-if="row.status == 0" size="small" type="primary" @click="dialogClick('edit', row)">
							通过
						</a-button>
					</a-popconfirm>
					<a-popconfirm v-if="row.status == 0" title="确定是否拒绝?" ok-text="确定" cancel-text="取消" @confirm="changeStatus(row,-1)">
						<a-button size="small" type="danger">
							拒绝
						</a-button>
					</a-popconfirm>
				</a-space>
				user
				<a-space slot='user' slot-scope="item,row,index">
					<span>{{row.user.name}}</span>
				</a-space>
				<a-space slot='imgs' slot-scope="item,row,index">
					<imgUpload
					v-if="row.video||(row.imgs&&row.imgs.length>0)"
					:list="row.video ? [row.video] : row.imgs"
					:disabled="true"
					:uploadType="row.video?'video':'img'"
					></imgUpload>
					<span v-else>-</span>
				</a-space>
				<a-space slot='status' slot-scope="item,row,index">
					<div>{{row.status==1?'审核通过':row.status==-1?'审核拒绝':'待审核'}}</div>
				</a-space>
			</MyTable>
		</div>
	</div>
</template>
<script>
import imgUpload from "@/components/myUpload.vue"
import MyTable from "@/components/myTable.vue"

import { userPost_index,userPost_changeStatus } from '@/api/manager'
import { config_index } from '@/api/config'

export default {
	components: { MyTable, imgUpload },
	data() {
		return {
			userPost_index: userPost_index,
			IMGHOST: window.IMGHOST,
			ajaxparams: {},//请求参数
			/*
				列表header
			*/
			columns: [
				
				{ title: '用户', ellipsis: true, dataIndex: 'user', key: 'user',scopedSlots: { customRender: 'user' } },
				{ title: '区域', ellipsis: true, dataIndex: 'area', key: 'area' },
				{ title: '内容',  dataIndex: 'content', key: 'content' },
				{ title: '图片/视频',  dataIndex: 'imgs', key: 'imgs',scopedSlots: { customRender: 'imgs' } },
				{ title: '状态',  dataIndex: 'status', key: 'status' ,scopedSlots: { customRender: 'status' }},
				{ title: '创建时间', dataIndex: 'ctime', key: 'ctime' },
				{
					title: '操作',
					key: 'operation',
					ellipsis: true,
					scopedSlots: { customRender: 'button' },
				},
			],
			isDisabledSwitch: false,//是否禁用Switch
			submitLoading: false,
			dialogType: 'add',
			dialogVisible: false,
			form: {
				"id": 0,
				"val": "",
				"name": "",
			},
			positionData: [],
			loading: false,
			tableData: [],
		}
	},
	created() {
	},
	methods: {

		handleCancel() {
			this.submitLoading = false;
			this.dialogVisible = false
		},
		
		async changeStatus(val,status) {//删除
			userPost_changeStatus({ id: val.id,status:status }).then((result) => {
				this.$message.success('操作成功');
				setTimeout(() => {
					this.$refs.myTable.getList()
				}, 1500)
			})
		},
		dialogClick(type, row) {
			this.dialogType = type;
			if (type == 'edit') {
				this.form = {
					"id": row.id,
					"name": row.name,
					"val": row.val,
					"goods_type": row.goods_type,
				}
			} else {
				this.form = {
					"name": "",
					"val": "",
					"goods_type": 1,
				}
			}
			this.dialogVisible = true
		},
	},
}
</script>
