<!-- 广告管理 -->

<style scoped>
	.img{
		/* width:30px; */
		height:30px
	}
	.ellipsis-1{
		max-width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; 
		word-wrap: break-word;
		 word-break: break-all 
	}
	.w150{
		width: 150px;
	}
</style>
<template>
  <div class="pageCentent">
    <a-form-model size="small" layout="inline" @keyup.native.enter="search()">
      <a-form-model-item label="通知时间">
        <a-range-picker @change="noticeChnage" v-model="noticeTime" format="YYYY-MM-DD hh:mm:ss" valueFormat="YYYY-MM-DD hh:mm:ss" />
      </a-form-model-item>
      <a-form-model-item >
        <a-button icon="search" @click="$refs.myTable.search()" type="primary"> 搜索</a-button>
      </a-form-model-item>
    </a-form-model>
    <a-button type="primary"  @click="showDialog()">
		  添加
		</a-button>
    <div >
      <MyTable class="mt10" :isSelection="false" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
         <a-space slot='arrTime' slot-scope="item,row,index">
          <span>{{row.start_time+' ~ '+row.end_time}}</span>
        </a-space>
      </MyTable>
    </div>
	
	<a-modal title="新增" :visible="dialogVisible" @ok="approved" @cancel="dialogVisible = false">
		<a-form-model-item label="内容">
			<a-input placeholder="请输入内容信息" v-model="form.content" > </a-input>
		</a-form-model-item>
		<a-form-model-item label="日期">
			<a-range-picker v-model="form.time" format="YYYY-MM-DD hh:mm:ss" valueFormat="YYYY-MM-DD hh:mm:ss" />
		</a-form-model-item>
	</a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {managerSlogan_index,managerSlogan_add} from '@/api/manager'
export default {
	components:{MyTable},
  data() {
    return {
		IMGHOST:window.IMGHOST,
		apiUrl:managerSlogan_index,
		ajaxparams:{},
		dialogVisible:false,
    noticeTime:[],
		columns:[
			{ title: '时间', ellipsis: true,dataIndex: 'create_at',key: 'create_at',width: 180},
			{ title: '内容', ellipsis: true,dataIndex: 'content',key: 'content'},
			{ title: '发布人', ellipsis: true,dataIndex: 'publish_user', key: 'publish_user',width: 120},
			{ title: '持续时间', ellipsis: true, width: 360, dataIndex: 'arrTime', key: 'arrTime', scopedSlots: { customRender: 'arrTime' } },
		],
		form:{}

	}
  },
  created() {
  },
  mounted() {
  },
  destroyed() {  
  },
  methods: {

	//选择日期
	noticeChnage(){
		if(this.noticeTime.length>0){
      this.ajaxparams.start_time_min = this.noticeTime[0]
		  this.ajaxparams.end_time = this.noticeTime[1]
    }else{
      delete this.ajaxparams.start_time_min
      delete this.ajaxparams.end_time
    }
	},
	showDialog(type,id){
		// this.currentId = id
		// this.buttonType = type
		this.dialogVisible = true
	},
	mobile(id_card){
		var mobile = id_card.substr(0, 6) + '*******' + id_card.slice(14)
		return mobile
	},
	
	approved(row){
		let postData = {
			"start_time": "",
      "end_time": "",
      "content": ""
		}
		if(this.form.content) postData.content = this.form.content
		if(this.form.time&&this.form.time.length>0) {
      postData.start_time = this.form.time[0]
      postData.end_time = this.form.time[1]
    }
		managerSlogan_add(postData).then((res)=>{
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.dialogVisible = false
				this.$refs.myTable.getList()
			},1500)
		})
	},
  },
}
</script>
	