import request from '@/utils/request'

// 抽奖用户列表
export function luckydraw_index(data) {
  return request({
    url: '/merchant/LuckyDraw/index',
    method: 'get',
    params: data
  })
}

// 抽奖奖品列表
export function luckydraw_prize_index(data) {
  return request({
    url: '/merchant/LuckyDraw/prize',
    method: 'get',
    params: data
  })
}

// 修改抽奖次数
export function luckydraw_change_draw_times(data) {
  return request({
    url: '/merchant/LuckyDraw/changeDrawTimes',
    method: 'POST',
    data: data
  })
}

// 修改奖品字段
export function luckydraw_changePrizeField(data) {
  return request({
    url: '/merchant/LuckyDraw/changePrizeField',
    method: 'POST',
    data: data
  })
}