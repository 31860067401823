import request from '@/utils/request'

// 角色列表
export function role_index(data) {
  return request({
    url: '/merchant/role/index',
    method: 'get',
    params:data
  })
}

// 创建角色
export function role_add(data) {
  return request({
    url: '/merchant/role/add',
    method: 'post',
    data:data
  })
}
// 编辑角色
export function role_edit(data) {
  return request({
    url: '/merchant/role/edit',
    method: 'post',
    data:data
  })
}
// 删除角色
export function role_del(data) {
  return request({
    url: '/merchant/role/del',
    method: 'post',
    data:data
  })
}
// 更新角色状态
export function role_permission(data) {
  return request({
    url: '/merchant/role/permission',
    method: 'post',
    data:data
  })
}




