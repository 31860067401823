import request from '@/utils/request'

// 商品特性列表
export function charact_index(data) {
  return request({
    url: '/merchant/GoodsCharact/index',
    method: 'get',
    params:data
  })
}


// 添加商品特性
export function charact_add(data) {
  return request({
    url: '/merchant/GoodsCharact/add',
    method: 'post',
    data:data
  })
}

// 删除商品特性
export function charact_del(data) {
  return request({
    url: '/merchant/GoodsCharact/del',
    method: 'post',
    data:data
  })
}





