<!-- 广告管理 -->

<style scoped>
	.img{
		/* width:30px; */
		height:30px
	}
	.ellipsis-1{
		max-width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; 
		word-wrap: break-word;
		 word-break: break-all 
	}
	.w150{
		width: 150px;
	}
</style>
<template>
  <div class="pageCentent">
	<!-- 搜索条件 -->
	<a-form-model  size="small" layout="inline" :model="ajaxparams">
		<a-form-model-item label="广告名称">
		  <a-input  placeholder="请输入广告名称" v-model="ajaxparams.ad_name_like" />
		</a-form-model-item>
		<a-form-model-item label="时间间隔">
			<a-range-picker format="YYYY-MM-DD HH:mm:ss" show-time @change="searchPicker"  />
		</a-form-model-item>
	    <a-form-model-item >
	      <a-button icon="search" @click="$refs.myTable.getList()" type="primary"> 搜索</a-button>
	    </a-form-model-item>
	</a-form-model>
	<a-button type="primary" class="mt10" @click="showModel('add')">
	  添加
	</a-button>
	<div >
		<MyTable class="mt10" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
			<a-space slot='button' slot-scope="item,row,index">
				<a-button size="small" type="primary"  @click="showModel('edit',row)">编辑</a-button>
				<a-popconfirm title="确定是否删除广告?" ok-text="确定" cancel-text="取消"  @confirm="delAd(row)" >
					<a-button size="small" type="danger" >删除</a-button>
				</a-popconfirm>
			</a-space>
			<a-space slot='img' slot-scope="item,row,index">
				<img v-if="row.img" class="img" :src="IMGHOST+'/'+row.img" />
				<span v-else>-</span>
			</a-space>
			<a-space slot='is_lock' slot-scope="item,row,index">
				<a-switch @click="switchClick(row,index)" :disabled="isDisabledSwitch" checked-children="正常" un-checked-children="锁定" :defaultChecked="row.is_lock==1?false:true" />
				<!-- <span>{{row.is_lock?'锁定':'正常'}}</span> -->
			</a-space>
			<a-space slot='sort' slot-scope="item,row,index">
				<editable-cell :ref="'edittableCell'+row.id" :text="row.sort" @change="onCellChange($event,row,index)" />
			</a-space>
		</MyTable>
	</div>
	<a-modal :maskClosable="false" :title="modelType=='add'?'添加广告':'编辑广告'" :visible="isModalShow" :confirm-loading="confirmLoading" @ok="handleConfirm"  @cancel="handleCancel" >
		<a-form-model :rules="rules" ref="formModel" size="small" :model="formModel">
			<a-form-model-item label="广告名称" prop="ad_name">
			  <a-input  placeholder="请输入广告名称" v-model="formModel.ad_name" />
			</a-form-model-item>
			<a-form-model-item label="目标地址" prop="target">
			  <a-input  placeholder="请输入目标地址" v-model="formModel.target" />
			</a-form-model-item>
			<a-form-model-item label="广告图" prop="img" ref="img">
				<my-upload v-if="isModalShow" :maxLength="1" @remove="uploadRemove" :list="defaultImg" @result="uploadResult"></my-upload>
			</a-form-model-item>
			<a-form-model-item label="广告位置" prop="position_id">
				<a-select style="width: 120px" v-model="formModel.position_id">
					<a-select-option :value="item.id" v-for="(item,index) in ad_position" :key="index">
						{{item.position_name}}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="排序" prop="sort">
			  <a-input  placeholder="请输入排序" v-model="formModel.sort" />
			</a-form-model-item>
		</a-form-model>
	</a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import EditableCell from "@/components/editableCell.vue"
import myUpload from "@/components/myUpload.vue"
export default {
	components:{MyTable,EditableCell,myUpload},
  data() {
    return {
		IMGHOST:window.IMGHOST,
		apiUrl:'merchant_ad_index',
		ajaxparams:{},
		columns:[
			{ title: '操作', key: 'operation', width: 175, scopedSlots: { customRender: 'button' } },
			{ title: '广告名称', ellipsis: true, width: 220, dataIndex: 'ad_name', key: 'ad_name' ,scopedSlots: { customRender: 'ad_name' } },
			{ title: '广告位名称', ellipsis: true, width: 150, dataIndex: 'position_name', key: 'position_name' ,scopedSlots: { customRender: 'position_name' } },
			{ title: '广告图', ellipsis: true, width: 120, dataIndex: 'img', key: 'img' ,scopedSlots: { customRender: 'img' }},
			{ title: '目标地址', ellipsis: true, width: 220, dataIndex: 'target', key: 'target' ,scopedSlots: { customRender: 'target' }},
			{ title: '是否锁定', ellipsis: true,dataIndex: 'is_lock', key: 'is_lock',width: 100,scopedSlots: { customRender: 'is_lock' }},
			{ title: '排序', ellipsis: true,dataIndex: 'sort', key: 'sort',width: 120,scopedSlots: { customRender: 'sort' }},
			{ title: '最后更新时间', ellipsis: true,dataIndex: 'utime', key: 'utime',width: 200},
			{ title: '创建时间', ellipsis: true,dataIndex: 'ctime', key: 'ctime',width: 200}
		],
		modelType:'add',
		formModel:{
			"id": '',
			"position_id": 0,
			"ad_name": "",
			"img": "",
			"target": "",
			"sort": 100
		},
		rules:{
			ad_name: [{ required: true, message: '请输入广告名称', trigger: 'blur' }],
			position_id: [{ required: true, message: '请选择广告位', trigger: 'change' }],
			img: [{ required: true, message: '请上传广告图片', trigger: 'change' }],
			target: [{ required: true, message: '请输入目标地址', trigger: 'blur' }],
			sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
		},
		isModalShow:false,
		confirmLoading:false,
		ad_position:[],
		defaultImg:[],
		isDisabledSwitch:false,
		
	}
  },
  created() {
	  this.merchant_config_index()
  },
  mounted() {
  	// document.addEventListener('visibilitychange', this.handleVisiable)
  },
  destroyed() {  
    // document.removeEventListener('visibilitychange', this.handleVisiable)  
  },
  methods: {
	//上传
	uploadResult(row){
	  	this.formModel.img = row.url
	},
	uploadRemove(row){
	  	this.formModel.img = ''
	},
	// 获取广告位
	async merchant_config_index(){
		let res = await this.$api.merchant_config_index({option:'ad_position'})
		if(res.code==200){
			this.ad_position = res.result.ad_position
		}
	},
	async onCellChange(e,row,index){//编辑排序
	  	if(!e||e==0){
	  		this.$refs['edittableCell'+row.id].resetEdit(row.sort)
	  		this.$message.warning('排序值必须大于0');
	  		return
	  	}
	  	let res = await this.$api.merchant_ad_operation({field:'sort',id:row.id,sort:e})
	  	if(res.code==200){
	  		this.$message.success('操作成功');
	  	}else{
	  		this.$refs['edittableCell'+row.id].resetEdit(row.sort)
	  	}
	  	
	},
	async delAd(row){
		let res = await this.$api.merchant_ad_operation({field:'is_del',id:row.id,is_del:'1'})
		if(res.code==200){
			this.$message.success('操作成功');
			setTimeout(()=>{
				this.$refs.myTable.getList()
			},1500)
		}
	},
	async switchClick(val,index){//禁用/启用
	  	this.isDisabledSwitch = true
	  	let res = await this.$api.merchant_ad_operation({field:'is_lock',is_lock:val.is_lock?0:1,id:val.id})
	  	if(res.code==200){
	  		this.$message.success('操作成功');
	  		// this.tableData[index].is_lock= val?1:0
	  	}
	  	setTimeout(()=>{
	  		this.isDisabledSwitch = false;
			this.$refs.myTable.getList()
	  	},1500)
	},
	showModel(type,row){
		this.modelType = type;
		if(type=='edit'){
			this.formModel  ={
				ad_name:row.ad_name,
				id: row.id,
				img:row.img,
				position_id: row.position_id,
				position_name:row.position_name,
				sort: row.sort,
				target: row.target,

			}
			this.defaultImg = row.img?[row.img]:[];
		}else if(type=="add"){
			this.formModel  ={
				ad_name:'',
				img:'',
				position_id: this.ad_position.length>0?this.ad_position[0].id:'',
				sort: 100,
				target: '',
			
			}
			this.defaultImg = []
		}
		this.isModalShow = true
	},
	// 弹窗取消
	handleCancel(){
		this.isModalShow = false;
	},
	// 弹窗确认
	handleConfirm(){
		this.$refs.formModel.validate(async valid => {
			if (valid) {
				this.confirmLoading = true;
				let res = null;
				if(this.modelType=='edit'){
					res = await this.$api.merchant_ad_edit(this.formModel)
				}else{
					res = await this.$api.merchant_ad_add(this.formModel)
				}
				if(res.code == 200){
					this.$message.success('操作成功');
					setTimeout(()=>{
						this.$refs.myTable.getList()
						this.confirmLoading = false;
						this.isModalShow = false
					},1500)
				}
			}else {
				return false
			}
		})
	},
	searchPicker(date,dateString){
		if(dateString.length>0&&dateString[0]){
			this.ajaxparams.ctime_between = dateString[0]+' - '+dateString[1]
		}else{
			this.ajaxparams.ctime_between = null
		}
		
	},
	// //返回页面刷新
	// handleVisiable(e) { 
	// 	if (e.target.visibilityState === 'visible') { //显示页面 
	// 		this.$refs.myTable.getList()
	// 		// window.location.href="about:blank";
	// 		// window.close();
	// 	}  
	// },
  },
}
</script>
	