<template>
	<div>
		<a-upload
		  :disabled="disabled"
		  name="file"
		  :accept="uploadType=='img'?'':'video/mp4'"
		  :list-type="clickType=='common'?'picture-card':'text'"
		  action="#"
		  :file-list="fileList"
		  :before-upload="beforeUpload"
		  @change="handleChange"
		  :customRequest="customRequest"
		  :remove="remove"
		  @preview="handlePreview"
		  
		>
		  <div  v-if="clickType=='common'&&fileList.length < maxLength&&!disabled">
			<a-icon :type="loading ? 'loading' : 'plus'" />
			<div class="ant-upload-text">
			  上传
			</div>
		  </div>
		  <a-button type="primary" v-if="clickType=='button'"> <a-icon type="upload" /> 上传 </a-button>
		</a-upload>
		<a-modal :maskClosable="false" :visible="previewVisible" :footer="null" @cancel="handleCancel">
			<img v-if="uploadType=='img'" alt="example" style="width: 100%" :src="previewImage" />
			<video v-else style="width:100%;height:600px" controls id="video"></video>
		</a-modal>
	</div>
</template>
<script>
function getBase64(file) {
	return new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
	});
}
import { uploader } from '@/api/common'
import axios from 'axios'
import { clearInterval } from 'timers';
export default {
  name: 'myUpload',
  props: {
	  uploadType:{
		type: String,
		default: 'img'
	  },
	  type: {
		type: String,
		default: ''
	  },
	  clickType: {
		type: String,
		default: 'common'
	  },
	  maxLength:{
		type: Number,
		default: 99
	  },
	  list:{
		  type: Array,
		  default:function(){
			  return []
		  }
	  },
	  disabled: {
		type: [String,Boolean],
		default: false
	  },
  },
  data() {
	  
      return {
		
        loading: false,
		imageUrl: '',
		fileList:[],
		defaultFileList:[],
		previewVisible:false,
		previewImage: '',
		previewFile:''
      };
  },
  created() {
	this.fileList = []
	let data = []
	for (var i = 0; i < this.list.length; i++) {
		let arr = {
			uid: (i+1).toString(),
			name: (i+1).toString(),
			status: 'done',
			url: this.list[i].indexOf("http") === 0 ? this.list[i] : window.IMGHOST + this.list[i],
			thumbUrl: this.list[i].indexOf("http") === 0 ? this.list[i] : window.IMGHOST + this.list[i],
		}
		if(this.uploadType!='img'){
			arr.thumbUrl = arr.thumbUrl+'?x-oss-process=video/snapshot,t_0'
		}
		data.push(arr)
	}
	this.fileList = data
  },
  methods:{
	  
	handleCancel() {
		if (this.uploadType!='img') {

			var video = document.getElementById('video')
			video.pause()
		}
		this.previewVisible = false;
	},
	async handlePreview(file) {
		if (this.uploadType=='img') {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		}else{
			this.previewVisible = true;
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			this.$nextTick(()=>{
				var video = document.getElementById('video')
				video.src = file.url|| file.preview;
			})
			
		}
		
	},
	uploadFile(file){
		let _this = this;
		let ossRes = _this.$db.get('ossRes')
		var formData = new FormData();
		//注意formData里append添加的键的大小写
		let keyValue = ossRes.dir+new Date().getTime() + (this.uploadType=='img'?'.jpg':'.mp4')
		formData.append('key', keyValue); //存储在oss的文件路径
		formData.append('OSSAccessKeyId', ossRes.accessId); //accessKeyId
		formData.append('policy', ossRes.policy); //policy
		formData.append('signature', ossRes.signature); //签名
		formData.append("file", file.file,file.file.name);
		formData.append('success_action_status', 201); //成功后返回的操作码
		// file.onProgress()
		let progree = {
			percent:1
		}
		var intervalId;
		axios.post(ossRes.host, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		})
		.then(res => {
			if (res.status == 201) {  //上传成功 上传的路径就是要使用的路径
				// let url = ossRes.host + "/" + keyValue;
				_this.$emit('result',{host:ossRes.host,url:"/" + keyValue,type:_this.type})
				_this.previewFile = ossRes.host+"/" + keyValue
				file.onSuccess()
				progree.percent = 99
			}else{
				file.onError() //上传失败
			}
			_this.loading = false;
		})
		.catch(error => {
			console.log('上传失败',error)
			_this.loading = false
			file.onError() //上传失败
		})
		let speed = 100/(file.file.size/65000)
		 intervalId = setInterval(() => {
			if(progree.percent<99&&progree.percent+speed<100){
				progree.percent+=speed;
				file.onProgress(progree)
			}else if(progree.percent=99){
				progree.percent++
			}else if(progree.percent=100){
				clearInterval(intervalId)
			}
		}, 100);
	},
	async customRequest(file) {
		this.loading = true
		let _this = this;
		let getOssToken = _this.$db.get('ossRes')
		if(getOssToken&&(getOssToken.expire*1000>new Date().getTime())){
			this.uploadFile(file)
		}else{
			uploader().then((res)=>{
				_this.$db.set('ossRes',res)
				this.uploadFile(file)
			}).catch((err)=>{
				console.log(err)
				this.loading = true
			})
		}
		
		
		
	},

	remove(file,index){
		if (file) {
			for(let i=0;i<this.fileList.length;i++){
				if(this.fileList[i].uid==file.uid){
					this.$emit('remove',{index:i,name:file.name,type:this.type})
				}
			}
		}
	},
	handleChange(info) {
	  this.fileList = info.fileList
	  const status = info.file.status;
	  if (status === 'done') {
		if(this.uploadType!='img'&&this.fileList.length>0){
			this.fileList[this.fileList.length-1].thumbUrl = this.previewFile+'?x-oss-process=video/snapshot,t_0'
		}
		this.$message.success('上传成功！')
	  } else if (status === 'error') {
		this.$message.error(`${info.file.name} 文件上传失败.`);
	  }
	},
	
	beforeUpload(file) {
		if(this.uploadType=='img'){
			const isJpgOrPng =  file.type === 'image/jpeg' || 
								file.type === "image/png" ||
								file.type === "image/gif" ||
								file.type === "image/webp" ||
								file.type === "image/jpg";
			if (!isJpgOrPng) {
				this.$message.error('上传图片只能是 JPG、JPEG、PNG、GIF 格式!');
			}
			const isLt10M = file.size / 1024 / 1024 < 10;
			if (!isLt10M) {
				this.$message.error('上传图片大小不能超过10MB!');
			}
			return isJpgOrPng && isLt10M;
		}else{
			const isLt10M = file.size / 1024 / 1024 < 100;
			if (!isLt10M) {
				this.$message.error('上传视频大小不能超过100MB!');
			}
			return isLt10M
		}
	 
	},
  }
}
</script>

<style scoped>
	.avatar-uploader > .ant-upload {
	  width: 128px;
	  height: 128px;
	}
	.ant-upload-select-picture-card i {
	  font-size: 32px;
	  color: #999;
	}
	
	.ant-upload-select-picture-card .ant-upload-text {
	  margin-top: 8px;
	  color: #666;
	}
</style>
