import { render, staticRenderFns } from "./userPrize.vue?vue&type=template&id=13bf8d07&scoped=true&"
import script from "./userPrize.vue?vue&type=script&lang=js&"
export * from "./userPrize.vue?vue&type=script&lang=js&"
import style0 from "./userPrize.vue?vue&type=style&index=0&id=13bf8d07&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13bf8d07",
  null
  
)

export default component.exports