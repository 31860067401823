import request from '@/utils/request'

//分类列表
export function classify_index(data) {
  return request({
    url: '/merchant/classify/index',
    method: 'get',
    params:data
  })
}

//获取顶级分类列表
export function classify_top(data) {
  return request({
    url: '/merchant/classify/getTopClassify',
    method: 'get',
    params:data
  })
}

// 添加分类
export function classify_add(data) {
  return request({
    url: '/merchant/classify/add',
    method: 'post',
    data:data
  })
}
// 编辑分类
export function classify_edit(data) {
  return request({
    url: '/merchant/classify/edit',
    method: 'post',
    data:data
  })
}
// 删除分类 
export function classify_del(data) {
  return request({
    url: '/merchant/classify/del',
    method: 'post',
    data:data
  })
}
// 更新分类状态
export function classify_field(data) {
  return request({
    url: '/merchant/classify/field',
    method: 'post',
    data:data
  })
}




