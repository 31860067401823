import request from '@/utils/request'

// 广告列表
export function spec_index(data) {
  return request({
    url: '/merchant/spec/index',
    method: 'get',
    params:data
  })
}

// 创建广告
export function spec_add(data) {
  return request({
    url: '/merchant/spec/add',
    method: 'post',
    data:data
  })
}
// 编辑广告
export function spec_edit(data) {
  return request({
    url: '/merchant/spec/edit',
    method: 'post',
    data:data
  })
}
// 删除广告
export function spec_del(data) {
  return request({
    url: '/merchant/spec/del',
    method: 'post',
    data:data
  })
}




