import request from '@/utils/request'

// 手工数据列表
export function fakeData_index(data) {
  return request({
    url: '/merchant/fakeData/index',
    method: 'get',
    params: data
  })
}

// 创建手工数据
export function fakeData_add(data) {
  return request({
    url: '/merchant/fakeData/add',
    method: 'post',
    data: data
  })
}
// 编辑手工数据
export function fakeData_edit(data) {
  return request({
    url: '/merchant/fakeData/edit',
    method: 'post',
    data: data
  })
}
// 删除手工数据
export function fakeData_del(data) {
  return request({
    url: '/merchant/fakeData/del',
    method: 'post',
    data: data
  })
}